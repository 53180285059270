import moment from 'moment/moment'
import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './SubscriptionsCredits.module.scss'

type Props = {
  item: {
    name?: string,
    expiresAt: Date,
    downloads?: number,
    used?: number,
  }
}
export const CardSubscriptions = (props: Props) => {
  const {item} = props;
  const { t } = useTranslation()
  return (
    <Paper className={styles.contentPaper}>
      <div className={styles.bold}>
        <Typography fontWeight={'bold'} fontSize={18}>{`${t('Plan')}: ${item?.name}`}</Typography>
      </div>
      <br/>
      <Box>
        <span className={styles.bold}>{t('Expiration Date')}:</span>{' '}
        {moment(item?.expiresAt).format('DD-MM-yyyy')}
      </Box>
      <Box sx={{marginTop: 1}}>
        <span className={styles.bold}>{t('Remaining Downloads')}:</span>{' '}
        {Number(Number(item?.downloads || 0) - Number(item?.used || 0))}({item?.downloads || 0})
      </Box>
    </Paper>
  )
}
export default CardSubscriptions