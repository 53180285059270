import Skeleton from '@mui/material/Skeleton'
import { Box, ImageListItem } from '@mui/material'
import { Fragment } from 'react'


export const SkeletonAssetsDetail = (props: any) => {
  const {data} = props;

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', padding: 4,  background: `linear-gradient(to bottom right, ${
        data + '40'
      }, ${data + '10'})`}}>
      <Box sx={{width: '100%'}}>
      <Skeleton variant="rectangular" height={200}>
        <img alt="skeleton" style={{maxWidth: 300, minWidth: '100%'}}/>
      </Skeleton>
      </Box>
      <Box sx={{width: '100%'}}>
        {[1, 2, 3].map((el) => (
          <Box key={`skeleton-${el}`}>
            <Box sx={{maxWidth: 500, minWidth:100, height: 80, marginBottom:2}}>
              <Skeleton variant="rectangular" sx={{marginBottom:2}} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )

}
export default SkeletonAssetsDetail;