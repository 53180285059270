import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ITimezone {
  id: number
  name: string
  abbreviation: string
}

interface IRole {
  id: number
  name: string
  permissions: string[] // Specify the correct type for permissions if available
  slug: string
  users_count: number
  created_at: string
  updated_at: string
  title_en: string
}

interface IAddress {
  address?: string
  phone?: string
  // Specify the shape of the address if available
}

export interface IUserData {
  id?: number |  string
  notificationsDisabled: string[] | null;
  activeSession?: any;
  noImagesLeftToUpload?: any;
  payoutRevolut?: string | null
  payoutPaypal?: string | null
  payoutPayoneer?: string | null
  payoutSkrill?: string | null
  sellerCanRequestPayout?: boolean
  country?: string
  firstName?: string
  lastName?: string,
  username?: string
  email?: string
  inExamination?: boolean,
  timezone: ITimezone
  lang: string
  avatar: string | null
  role: IRole
  phone: string | null
  isStripeAccountActive: boolean
  status: string
  displayStatus: string
  reason: string | null
  newsletter: number
  website: string | null
  facebook: string | null
  linkedin: string | null
  twitter: string | null
  pinterest: string | null
  instagram: string | null
  addresses: IAddress[] // Use the correct type for addresses if available
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  design_photo?: string
  id_file?: string
  sellerEarningsTotal?: string
  sellerEarningsUnpaid?: string
  sellerExamination?: {
    createdAt?: string
    displayStatus?: string
    id?: number
    status?: string
    updatedAt: string
  },
  minPayout: any;
  lastSellerPayout?: any
  lastSellerTaxForm?: any
}

interface IUserStore {
  loading: boolean
  isAuthenticated: boolean
  error: string | null
  usersData: IUserData | null,
  userRole: IRole | null
}

let initialState: IUserStore = {
  loading: false,
  isAuthenticated: false,
  error: null,
  usersData: null,
  userRole: null
}

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    setUserData: (state, action: PayloadAction<IUserData | null>) => {
      state.usersData = action.payload
    },
    setRole: (state, action: PayloadAction<IRole | null>) => {
      state.userRole = action.payload
    },
    updateNotifications: (state, action: PayloadAction<string[] | null>) => {
      if(state.usersData) {
        state.usersData.notificationsDisabled = action.payload
      }
    },
    setIsExamination: (state, action: PayloadAction<boolean>) => {
      if(state.usersData) {
        state.usersData.inExamination = action.payload
      }
    },
    setImagesleftToUpload: (state, action: PayloadAction<number>) => {
      if(state.usersData) {
        state.usersData.noImagesLeftToUpload = action.payload
      }
    },
  },
})

export const { setLoading,setRole, setIsAuthenticated, setError, setUserData, updateNotifications, setIsExamination, setImagesleftToUpload } =
  usersSlice.actions
export default usersSlice.reducer
