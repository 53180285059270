import { useNavigate } from 'react-router-dom'
import { IFilters, initialFiltersConst, NavigationEnum } from '../utils/constants'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import { useDispatch } from 'react-redux'
import { setCategories } from '../redux/reducers/searchReducer'

export const useCustomNavigation = () => {
  const navigate = useNavigate()
  const initialFilters = withDefault(JsonParam, initialFiltersConst)
  const [filters, setFilters] = useQueryParam<IFilters>(
    'filters',
    initialFilters
  )
  const dispatch = useDispatch()
  const customNavigate = (to: NavigationEnum | string, stateObject?: any, params?: any) => {
    //set boolean for animations in redux
    if (!stateObject && !params) {
      navigate(to)
    } else if(params) {
      dispatch(setCategories(params))
      navigate({
        pathname: to,
        search: `filters=${encodeURIComponent(`{"orientation":"all","items":100,"category":"${params?.toUpperCase()}"}`)}`
      }, {
        replace: true
      })
    } else {
      navigate({
        pathname: to
      }, { state: stateObject })
    }
  }

  return customNavigate
}
