import { Accordion, AccordionSummary, Grid, Paper } from '@mui/material'
import styles from '../../main-components/content/account-details/sections/buyer/lightbox/Lightbox.module.scss'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Skeleton from '@mui/material/Skeleton'


export const SkeletonLightBox = () => {

  return (
    <Paper elevation={3}>
      <Accordion
        expanded={false}
        sx={{height: 60}}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Skeleton />
            </Grid>
            <Grid item xs={2}>
              <Skeleton />
            </Grid>
            <Grid item xs={2}>
              <Skeleton />
            </Grid>
            <Grid item xs={2}>
              <Skeleton />
            </Grid>
            <Grid item xs={2}>
              <Skeleton />
            </Grid>
            <Grid item xs={2}>
              <Skeleton />
            </Grid>
         </Grid>
        </AccordionSummary>
      </Accordion>
    </Paper>
  )
}

export default SkeletonLightBox;