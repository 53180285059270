import React, {useEffect, useState } from 'react'
import { Badge, Box, IconButton,  Popover, } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Notifications from '../notifications'
import styles from './NotificationsNavbar.module.scss'
import instance from '../../../providers/axios.config'
import { useDispatch, useSelector } from 'react-redux'
import { setCountNotifications } from '../../../redux/reducers/notificationsReducer'
import { RootState } from '../../../redux/reducers/rootReducer'
import { typeResponse } from '../../../utils/types'

interface INotifications extends typeResponse{
  data: {
    unreads: number
  }
}

export const NotificationsNavbar = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch()

  const notificationCount = useSelector(
    (state: RootState) => state.notifications.count
  )

  const fetchNotificationNumber = async () => {
    try {
      const res = await instance.get(`notifications/status`) as INotifications
      dispatch(setCountNotifications(res.data?.unreads))
    }
    catch (e){
      console.error('Error fetch:', e)
    }
  }

  useEffect(() => {
    fetchNotificationNumber()
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>
        <Badge badgeContent={notificationCount} color="primary">
          <NotificationsIcon sx={{color: '#fff'}} />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: styles.paper
        }}
        transformOrigin={{
          vertical: 'top',

          horizontal: 'center',
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1', backgroundColor: '#343a40',  "*::-webkit-scrollbar-track": {
            background: "red"
          },"*::-webkit-scrollbar-thumb": {
            background: "#1D388F61",
            borderRadius: "2px"
          } }}>
        <Notifications isHeader/>
        </Box>
      </Popover>
    </div>
  )
}

export default NotificationsNavbar;