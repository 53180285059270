import Skeleton from '@mui/material/Skeleton';
import { Fragment } from 'react'
import { ImageList, ImageListItem, useMediaQuery, useTheme } from '@mui/material'

export const SkeletonAssetsImages = (props: any) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const md = useMediaQuery(theme.breakpoints.down('md'))
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const lg = useMediaQuery(theme.breakpoints.down('lg'))

  const getMasonryColumns = () => {
    if (sm) return { col: 1, gap: 0 }
    if (md) return { col: 3, gap: 5 }
    if (xs) return { col: 1, gap: 5 }
    if (lg) return { col: 4, gap: 5 }
    return { col: 4, gap: 5 }
  }

  const masonryOptions = getMasonryColumns()
  return (
    <ImageList
      variant='masonry'
      cols={masonryOptions.col}
      gap={masonryOptions.gap}
    >
      {[...Array(50)].map((el, index) => (
        <Fragment key={index}>
          <ImageListItem
            sx={{
              position: 'relative',
              transition: '0.3s',
              overflow: 'hidden',
              '&:hover': {
                transform: 'scale(1.01)',
                '& .MuiImageListItemBar-root': { opacity: 1 },
                '&::after': {
                  content: '""',
                  display: 'block',
                  maxHeight: '200px',
                  backgroundColor: 'rgba(0,0,0,0.15)',
                  zIndex: 1,
                },
              },
            }}
          >
            <Skeleton variant="rectangular" height={ index % 3 === 0 ? 100 : 150}/>
          </ImageListItem>
        </Fragment>
      ))}
    </ImageList> )
}

export default SkeletonAssetsImages