import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Button,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { Title } from '../../Title'
import { DailyEarnings } from './DailyEarnings'
import styles from './Earnings.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { formatDollar } from '../../../../../../../utils/helpers'
import { styled } from '@mui/material/styles'
import { LoadingTable } from './LoadingTable'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'

type allTime = {
  credits_earnings?: string,
  downloads?: number,
  earnings?: string,
  extended_earnings?: string,
  fast_download_earnings?: string,
  one_day_earnings?: string,
  one_month_earnings?: string,
  one_year_earnings?: string,
  earnings_returned?: string,
}
type monthly = {
  daily?: any,
  downloads?: number,
  earnings?: number,
  extended_earnings?: number,
  earnings_fast_download?: number,
  earnings_returned?: number,
  earnings_subscription_credits?: number,
  earnings_subscription_one_day?: number,
  earnings_subscription_one_month?: number,
  earnings_subscription_one_year?: number,
}
type earningsTypes = {
  all_time: allTime,
  monthly_earnings: any,
}

interface IProps {
  filterValue?: string,
}

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 'bold'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    backgroundColor: theme.palette.action.hover,
    fontWeight: 'bold'
  },
}));


export const Earnings = (props: IProps) => {
  const { filterValue } = props

  const { t } = useTranslation()
  const account = useSelector((state: RootState) => state.user.usersData)
  const [isLoading, setIsLoading] = useState(false)
  const [earning, setEarning] = useState<earningsTypes>({all_time: {}, monthly_earnings: {}});
  const [rowName, setRowName] = useState<any>([])

  const [date, setDate] = useState(null)
  const [dailyEarningsDate, setDailyEarningsDate] = useState<
    string | undefined
  >()
  const [selectedMonth, setSelectedMonth] = useState('1')

  const getEarningData = async () => {
    setIsLoading(true)
    try {
      const urlDate = {
        filters: {
          date: moment(date).format('MM.YYYY')
        }
      }
      const urlMonth = {
        filters: {
          months: selectedMonth
        }
      }
      const res = await instance.get(`seller/earnings`, {params: date ? {...urlDate} : {...urlMonth}})
      setEarning(res?.data || {})
      const keys = Object.keys(res?.data?.all_time)
      setRowName([...keys])
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getEarningData()
  }, [selectedMonth, date])

  const getTitle = () => {
    switch (filterValue) {
      case null:
        return t('Earnings')
      case '0':
        return t('Statistics')
      default:
        return t('Earnings')
    }
  }

  const handleBack = () => {
    setDailyEarningsDate(undefined)
  }

  const handleExportData= async () => {
    setIsLoading(true)
    try {
      const urlDate = {
        filters: {
          date: moment(date).format('MM.YYYY')
        }
      }
      const urlMonth = {
        filters: {
          months: selectedMonth
        }
      }
      const res = await instance.get(`seller/earnings/export`, {params: date ? {...urlDate} : {...urlMonth}, responseType: 'arraybuffer'}) as any
      const url = window.URL.createObjectURL(new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx');
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  if(!isLoading && !earning?.monthly_earnings) {
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <Title title={getTitle()} />
        </div>
        <div className={styles.content}>
          {t('no_earning')}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {dailyEarningsDate && (
          <IconButton
            onClick={() => handleBack()}
            className={styles.backIcon}
            size='large'
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </div>
      <div className={styles.content}>
            {!dailyEarningsDate ? <>
                <div className={styles.filterBar}>
                  <div className={styles.filterBarLeft}>
                    <CustomTextfield
                      className={styles.selectTextField}
                      select
                      label={t('Number of months')}
                      variant='outlined'
                      value={selectedMonth}
                      onChange={(e: any) => {
                        setSelectedMonth(e.target.value)
                        setDate(null)
                      }
                      }
                    >
                      <MenuItem value={'1'}>{t('Month')}</MenuItem>
                      {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                        <MenuItem key={month} value={month}>
                          {t('Months', { month })}
                        </MenuItem>
                      ))}
                    </CustomTextfield>
                    <div className={styles.unpaidEarnings}>
                      {t('Unpaid earnings', { count: Number(account?.sellerEarningsUnpaid) ||  0 })}
                    </div>
                  </div>
                  <div className={styles.filterBarRight}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className={styles.textfield}
                        label={t('Date')}
                        value={date}
                        format='MM-yyyy'
                        views={['month', 'year']}
                        onChange={(newValue: any) => {
                          setDate(newValue)
                          setSelectedMonth('1')
                        }}
                        slots={{
                          textField: CustomTextfield,
                        }}
                        slotProps={{
                          textField: { size: 'small' },
                        }}
                      />
                    </LocalizationProvider>

                    <Button className={`${earning?.all_time?.downloads ? styles.exportExcelButton : styles.exportExcelDisabledButton}`} disabled={!earning?.all_time?.downloads} onClick={handleExportData}>
                      {t('Export Excel')}
                    </Button>
                  </div>
                </div>

                <div className={styles.table}>
                  <TableContainer component={Paper} className={styles.table}>
                    <Table>
                      <TableHead>
                        <TableRow className={styles.tableRow}>
                          <TableCell className={styles.tableCell}>
                            {t('Date')}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {t('Daily downloads')}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {t('Daily earnings')}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div>
                              <div> {t('Fast download')}</div>
                              <div className={styles.bottomCells}>
                                <div>{t('Downloads')}</div>
                                <div>{t('Earnings')}</div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div>
                              <div>{t('One day download')}</div>
                              <div className={styles.bottomCells}>
                                <div>{t('Downloads')}</div>
                                <div>{t('Earnings')}</div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div>
                              <div>{t('One year subscription')}</div>
                              <div className={styles.bottomCells}>
                                <div>{t('Downloads')}</div>
                                <div>{t('Earnings')}</div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div>
                              <div>{t('One month subscription')}</div>
                              <div className={styles.bottomCells}>
                                <div>{t('Downloads')}</div>
                                <div>{t('Earnings')}</div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div>
                              <div>{t('Credit plan')}</div>
                              <div className={styles.bottomCells}>
                                <div>{t('Downloads')}</div>
                                <div>{t('Earnings')}</div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div>
                              <div>{t('Extended')}</div>
                              <div className={styles.bottomCells}>
                                <div>{t('Downloads')}</div>
                                <div>{t('Earnings')}</div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {t('Returned')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        {isLoading ? <LoadingTable /> : <>
                        <TableRow className={styles.tableRow}>
                          <TableCell className={styles.tableCell}>
                            {t('All time')}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.downloads}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.fast_download_earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.one_day_earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.one_year_earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.one_month_earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.credits_earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.extended_earnings}
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            {earning?.all_time?.earnings_returned}
                          </TableCell>
                        </TableRow></>}
                      </TableHead>
                      <TableBody>
                        {
                          (earning?.monthly_earnings ? Object.keys(earning?.monthly_earnings) : []).map((month: any) => {
                            const dataDaily = earning?.monthly_earnings[month]?.daily;

                            return (
                              <Fragment key={month}>
                              <StyledTableRow className={styles.tableRowMonth}>
                                <TableCell
                                  className={styles.tableCellContent}
                                >
                                  <Typography noWrap variant="caption" sx={{display: 'flex'}}> {t('monthly_total')}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption"> {earning?.monthly_earnings[month].downloads}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_fast_download)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_subscription_one_day)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_subscription_one_year)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_subscription_one_month)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_subscription_credits)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_extended)}</Typography>
                                </TableCell>
                                <TableCell className={styles.tableCellContent}>
                                  <Typography variant="caption">{formatDollar(earning?.monthly_earnings[month].earnings_returned)}</Typography>
                                </TableCell>
                              </StyledTableRow>
                                {(dataDaily ? Object.keys(dataDaily) : []).map((day: any) => (
                                  <TableRow className={styles.tableRow} key={day}>
                                    <TableCell
                                      className={styles.tableCellContentDate}
                                      onClick={() =>
                                        setDailyEarningsDate(moment(day).format('YYYY-MM-DD'))
                                      }
                                    >
                                      {moment(day).format('MM-DD-YYYY')}
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      {dataDaily[day].downloads}
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      {formatDollar(dataDaily[day].earnings)}
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      <div className={styles.bottomCellsContent}>
                                        <div>{dataDaily[day].downloads_fast_download}</div>
                                        {formatDollar(dataDaily[day].earnings_fast_download)}
                                      </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      <div className={styles.bottomCellsContent}>
                                        <div>{dataDaily[day].downloads_subscription_one_day}</div>
                                        {formatDollar(dataDaily[day].earnings_subscription_one_day)}
                                      </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      <div className={styles.bottomCellsContent}>
                                        <div>{dataDaily[day].downloads_subscription_one_year}</div>
                                        {formatDollar(dataDaily[day].earnings_subscription_one_year)}
                                      </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      <div className={styles.bottomCellsContent}>
                                        <div>{dataDaily[day].downloads_subscription_one_month}</div>
                                        {formatDollar(dataDaily[day].earnings_subscription_one_month)}
                                      </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      <div className={styles.bottomCellsContent}>
                                        <div>{dataDaily[day].downloads_subscription_credits}</div>
                                        {formatDollar(dataDaily[day].earnings_subscription_credits)}
                                      </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      <div className={styles.bottomCellsContent}>
                                        <div>{dataDaily[day].downloads_extended}</div>
                                        {formatDollar(dataDaily[day].earnings_extended)}
                                      </div>
                                    </TableCell>
                                    <TableCell className={styles.tableCellContent}>
                                      {formatDollar(dataDaily[day].earnings_returned)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </Fragment>
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </> : <DailyEarnings
                selectedDate={dailyEarningsDate}
                handleBack={handleBack}
              />}
        {/*{filterValue === '0' && <Statistics />}*/}
      </div>
    </div>
  )
}
