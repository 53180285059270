import { Box, Button, Divider, Link, List, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import NotificationItemList from '../notificationItemList'
import { useTranslation } from 'react-i18next'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { INotifications } from '../../../utils/types'
import instance from '../../../providers/axios.config'
import Notiflix from 'notiflix'
import { ROUTES } from '../../../utils/constants'
import LoadingNotification from './LoadingNotification'
import { updateNotifications } from '../../../redux/reducers/usersReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

type UnreadType = {
  isHeader?: boolean
}
export const Unread  = (props: UnreadType) => {
  const {isHeader} = props;
  const { t } = useTranslation()
  const customNavigate = useCustomNavigation()
  const dispatch = useDispatch()
  const profile = useSelector(
    (state: RootState) => state.user.usersData
  )
  const [value, setValue] = useState('1');
  const [isLoading, setIsLoading] = useState(false)
  const [notificationsUnread, setNotificationsUnread] = useState<INotifications[]>([]);
  const [notificationsUnreadOld, setNotificationsUnreadOld] = useState<INotifications[]>([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 50,
    totalPages: 1
  })
  const fetchNotificationsUnread = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`notifications/unread`) as any;
      setNotificationsUnread(res.data.notifications.new)
      setNotificationsUnreadOld(res.data.notifications.old)
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    fetchNotificationsUnread()
  }, [value])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleShowMore = () => {
    setPagination({...pagination, currentPage: pagination.currentPage + 1})
  }

  const handleReadNotification = async (id: number) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const resp = await instance.put(`notifications/${id}`) as any;
      if ((resp as any).status === 'success') {
        const updateNotification = notificationsUnreadOld.map((not) => {
          if(not.id === id) {
            return {
              ...not,
              isRead: 1
            }
          }
          return  {
            ...not
          }
        })
        const updateNotificationNew = notificationsUnread.map((not) => {
          if(not.id === id) {
            return {
              ...not,
              isRead: 1
            }
          }
          return  {
            ...not
          }
        })
        setNotificationsUnreadOld([...updateNotification])
        setNotificationsUnread([...updateNotificationNew])
        Notiflix.Loading.remove()
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (e){
      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }
  const handleDeleteNotification = async (id: number) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const resp = await instance.delete(`notifications/${id}`) as any;
      if ((resp as any).status === 'success') {
        const updateNotification = notificationsUnreadOld.filter((notification) => notification.id !== id)
        const updateNotificationNew = notificationsUnread.filter((notification) => notification.id !== id)
        setNotificationsUnreadOld([...updateNotificationNew])
        setNotificationsUnread([...updateNotification])
        Notiflix.Loading.remove()
        Notiflix.Notify.success(`${resp?.message}`)
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (e){
      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }
  const handleRedirect = () => {
    customNavigate(ROUTES.NOTIFICATIONS)
  }
  if(isLoading){
    return <>
      {[1,2,3,4].map((number) => (
        <Fragment key={number}>
          <LoadingNotification />
        </Fragment>
      ))}
    </>
  }
  if(!notificationsUnreadOld?.length && !notificationsUnread?.length && !isLoading) {
    return <Typography sx={{color: isHeader ? '#fff' : '#000'}}>{t('no_notification_unread')}</Typography>
  }
  const handleDisabledNotifications = async (type: any) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    const findDataNotifications = (profile?.notificationsDisabled || []).find((not: string) => not === type);
    try {
      const resp = await instance.put(`notifications/${type}/toggle`, JSON.stringify({action: findDataNotifications ? 'enable' : 'disable'})) as any;
      if ((resp as any).status === 'success') {
        if(findDataNotifications) {
          dispatch(updateNotifications((profile?.notificationsDisabled || []).filter((d: string) => d !== type)))
        } else {
          dispatch(updateNotifications(profile?.notificationsDisabled?.length ? [...profile?.notificationsDisabled, type] : [type]))
        }
        Notiflix.Loading.remove()
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (e){
      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }

  return (
    <>
      <List sx={{ width: isHeader ? 360 : '100%', backgroundColor: isHeader ? '#343a40' : 'transparent', padding: 0 }}>
        {notificationsUnread?.length ? <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography fontWeight={600} fontSize={18} sx={{color: isHeader ? '#E4E6EB' : 'initial'}}>{t('new')}</Typography>
          {isHeader && <Button onClick={handleRedirect} sx={{color: isHeader ? '#CBC7C2' : 'primary'}}>{t('see_all')}</Button>}
        </Box> : null}
        {notificationsUnread.map((not: any) => (
          <Fragment key={not.id}>
            <NotificationItemList notification={not} isHeader={isHeader} onReadNotification={handleReadNotification} onDisableNotification={handleDisabledNotifications} onDeleteNotification={handleDeleteNotification}/>
            <Divider variant="inset" component="li" />
          </Fragment>
        ))}
        {notificationsUnreadOld?.length ? <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography fontWeight={600} fontSize={18} sx={{color: isHeader ? '#E4E6EB' : 'initial'}}>{t('older')}</Typography>
          {!notificationsUnreadOld?.length && <Button>Vezi tot</Button>}
        </Box> : null}
        {notificationsUnreadOld.map((notification) => (
          <Fragment key={notification?.id}>
            <NotificationItemList notification={notification} isHeader={isHeader} onReadNotification={handleReadNotification} onDisableNotification={handleDisabledNotifications} onDeleteNotification={handleDeleteNotification}/>
            <Divider variant="inset" component="li" />
          </Fragment>
        ))}
        {pagination.totalPages > 1 && <Link
          component="button"
          variant="body2"
          sx={{color:  isHeader ? '#CBC7C2' : 'initial'}}
          onClick={handleShowMore}
        >
          {t('show_more')}
        </Link>}
      </List>
    </>
  )

}

export default Unread