import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPasswordField } from '../../textfield/CustomPasswordField'
import styles from './Dialogs.module.scss'
import { CustomTextfield } from '../../textfield/CustomTextField'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key: string, key1: string, key2: string) => void
  dialogTitle: string
}

export const ChangeNameDialog = (props: IProps) => {
  const { open, handleOpen, handleConfirm, dialogTitle } = props

  const [name, setName] = useState<any>({})
  const [error, setError] = useState(false);

  const { t } = useTranslation()

  const handleSave = () => {
    if(!name.firstName || !name.lastName || !name.reason) {
      setError(true)
    }
    else {
      handleConfirm(name.firstName, name.lastName, name.reason)
    }
  }

  return (
    <Dialog open={open} onClose={handleOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='login'
            variant='outlined'
            label={t('First Name')}
            value={name?.firstName}
            onChange={(event: any) => {
              setName({...name, firstName: event.currentTarget.value})
            }}
          />
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='lastName'
            variant='outlined'
            label={t('Last Name')}
            value={name?.lastName}
            onChange={(event: any) => {
              setName({...name, lastName: event.currentTarget.value})
            }}
          />
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='reason'
            multiline
            rows={3}
            variant='outlined'
            label={t('reason')}
            value={name?.reason}
            onChange={(event: any) => {
              setName({...name, reason: event.currentTarget.value})
            }}
          />
          {error && <Typography color="primary">{t('All fields are required')}</Typography>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} className={styles.cancelButton}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSave} className={styles.confirmButton}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
