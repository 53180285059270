import {
  Box,
  Button,
  Divider,
 Link, List,Tab,
  Typography
} from '@mui/material'

import React, { Fragment, useEffect, useState } from 'react'

import { TabContext, TabList, TabPanel } from '@mui/lab'

import NotificationItemList from '../notificationItemList'
import instance from '../../../providers/axios.config'
import Notiflix from 'notiflix'
import { INotifications } from '../../../utils/types'
import { useTranslation } from 'react-i18next'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { ROUTES } from '../../../utils/constants'
import All from './all'
import Unread from './Unread'


export const Notifications = (props: any) => {
  const {isHeader} = props;
  const { t } = useTranslation()

  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label={t('all')} value="1" sx={{color:  isHeader ? '#CBC7C2' : 'initial'}}/>
                  <Tab label={t('unread')} value="2" sx={{color: isHeader ? '#CBC7C2' : 'initial'}} />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{backgroundColor: isHeader ? '#343a40' : 'transparent'}}>
                <List sx={{ width: isHeader ? 360 : '100%', backgroundColor: isHeader ? '#343a40' : 'transparent', padding: 0 }}>
                 <All isHeader={isHeader}/>
                </List>
              </TabPanel>
              <TabPanel value="2" sx={{backgroundColor: isHeader ? '#343a40' : 'transparent'}}>
                <Unread isHeader={isHeader} />
               </TabPanel>
            </TabContext>
      </div>
      </>
  )
}

export default Notifications;