import styles from './FeatureButton.module.scss'

interface IProps {
  icon: any
  title: string
  description: string
}

export const FeatureButton = (props: IProps) => {
  const { icon, title, description } = props
  return (
    <div className={styles.container}>
      <div className={styles.left}>{icon}</div>

      <div className={styles.right}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  )
}
