import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar, Typography,
  Link, Alert, useMediaQuery
} from '@mui/material'
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { RootState } from '../../../redux/reducers/rootReducer'
import { NavigationEnum, constants, navItems, ROUTES, DECLINED, SELLER, BUYER, VALID } from '../../../utils/constants'
import { NavButton } from '../../shared-components/button/NavButton'
import { MobileDrawer } from '../mobile/MobileDrawer'
import styles from './Header.module.scss'
import Language from '../../shared-components/language'
import useAuthentication from '../../../hooks/useAuthentication'
import { red } from '@mui/material/colors'
import NotificationsNavbar from '../../shared-components/NotificationsNavbar'

export const Header = () => {
  const { t } = useTranslation()
  const matchesMobile = useMediaQuery('(max-width:600px)')
  const { isAuthenticated } = useAuthentication()
  const customNavigate = useCustomNavigation()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [selectedButton, setSelectedButton] = useState(1)
  const [hoveredButton, setHoveredButton] = useState()
  const location = useLocation();
  
  useEffect(() => {
    let currentBtnIndex = 1;
    if (location.pathname.startsWith('/assets')) {
      currentBtnIndex = 2;
    }
    if (location.pathname.startsWith('/plans-prices')) {
      currentBtnIndex = 5;
    }
    if (location.pathname.startsWith('/about')) {
      currentBtnIndex = 4;
    }
    if (location.pathname.startsWith('/account')) {
      currentBtnIndex = 6;
    }
    if (location.pathname.startsWith('/login')) {
      currentBtnIndex = 6;
    }
    setSelectedButton(currentBtnIndex);
  }, [location.pathname]);
  const profile = useSelector(
    (state: RootState) => state.user.usersData
  )
  const isLoadingState = useSelector((state: RootState) => state.user.loading)
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const handleRedirectAccount = () => {
    customNavigate(`${ROUTES.ACCOUNT}/${ROUTES.SETTINGS}`)
  }
  const setShowMessageImageToLeft = () => {
    if(profile?.role?.slug === SELLER && profile.status === VALID) {
      return profile?.noImagesLeftToUpload;
    }
  }
  const handleRedirectSession = () => {
    customNavigate(`${ROUTES.ACCOUNT}/${ROUTES.UPLOAD}`)
  }
  return (
    <>
      {profile?.activeSession?.id 
        ? (
          <Alert 
            severity="warning"
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: 3,
              }}
            >
              <Typography textAlign="center">
                <Link onClick={() => {}} component="button" color="error" sx={{textDecoration: 'none'}}>
                  {t('session_upload_active', { number: setShowMessageImageToLeft() })}
                </Link>
              </Typography>
              <Button
                variant='contained'
                className={styles.textfieldButton}
                onClick={() => handleRedirectSession()}
                size='small'
              >
                {t('continue')}
              </Button>
            </Box>
          </Alert>
        ) : null}
    {profile?.email && profile?.sellerExamination?.status === DECLINED ? <Alert severity="error" sx={{display: 'flex', justifyContent: 'center'}}>
      <Typography variant="caption" sx={{color: red[800]}}>{t('examination_failed')}</Typography>
    </Alert>: null}
    {profile?.role?.slug === SELLER && (profile?.email && profile?.addresses?.length === 0) ? <AppBar className={styles.containerAccount} component='nav' position='relative'>
        <Typography variant="caption" sx={{color: '#fff'}}  textAlign="center">{t('header_profile_incomplete')}<Link onClick={handleRedirectAccount} component="button" color="primary" className={styles.link} >
          {t('header_link_profile')}
        </Link></Typography>
      </AppBar> : null}
      {profile?.role?.slug === BUYER && (profile?.email && profile?.addresses?.length === 0) ? <AppBar className={styles.containerAccount} component='nav' position='relative'>
        <Typography variant="caption" sx={{color: '#fff'}}  textAlign="center">{t('header_profile_incomplete')}<Link onClick={handleRedirectAccount} component="button" color="primary" className={styles.link} >
          {t('header_link_profile')}
        </Link></Typography>
      </AppBar> : null}
      <AppBar className={styles.container} component='nav' position='relative'>
        <img
          src={constants.logo_layerstock}
          alt='logo'
          className={styles.logo}
          onClick={() => {
            customNavigate(NavigationEnum.HOME);
            setSelectedButton(1);
          }}
        />

        <Toolbar className={styles.buttonsContainer}>
          <IconButton
            color='inherit'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            {navItems.map((item: any) => (
              <Fragment key={`${item.title}-${item.value}`}>
                <NavButton
                  setSelectedButton={setSelectedButton}
                  title={item.title}
                  value={item.value}
                  listOfItems={item.listOfItems}
                  selected={item.value === selectedButton}
                  hovered={item.value === hoveredButton}
                  buttonOnMouseEnter={setHoveredButton}
                  to={item.to as NavigationEnum}
                  params={item?.params?.toUpperCase() || ''}
                />
              </Fragment>
            ))}
          </Box>
        </Toolbar>

        <Toolbar>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <NavButton
              setSelectedButton={setSelectedButton}
              title={t('Plans & Prices').toUpperCase()}
              value={5}
              selected={selectedButton === 5}
              hovered={hoveredButton === 5}
              buttonOnMouseEnter={setHoveredButton}
              to={NavigationEnum.PLANS_PRICES}
            />
            <Language />
            {isAuthenticated && profile && <NotificationsNavbar />}
            {isLoadingState ? (
              <CircularProgress />
            ) : (
              <Button
                className={selectedButton !== 6 ? styles.loginButton : styles.loginButtonSelected}
                onClick={() => {
                  if (isAuthenticated && profile) {
                    customNavigate(NavigationEnum.ACCOUNT)
                  } else {
                    customNavigate(NavigationEnum.LOGIN)
                  }
                  setSelectedButton(6);
                }}
              >
                <PersonIcon fontSize='small' />
                {t(isAuthenticated && profile ? 'Account' : 'Login')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {matchesMobile && (
        <MobileDrawer
          items={navItems}
          open={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
    </>
  )
}
