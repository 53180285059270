import { FilterChipItem } from './FilterChipItem'
import { useEffect, useRef, useState } from 'react'

interface IProps {
  titles: string[] | []
  icon?: 'CHECK' | 'SEARCH' | 'CLEAR'
  handleSearch?: (key?: string) => void
  handleDelete?: (key?: string) => void
  isVisibleChips?: boolean
  isNoSearch?: boolean
}

export const FilterChipsList = (props: IProps) => {
  const {isVisibleChips, isNoSearch} = props;
  const [visibleChips, setVisibleChips] = useState(0);
  const containerRef = useRef<any>(null);
  useEffect(() => {
    if(!isVisibleChips) {
      const containerWidth = containerRef?.current?.offsetWidth;
      const chipWidth = 90; // Replace this with the actual width of your chip
      const numberOfChips = Math.floor(containerWidth / chipWidth);
      setVisibleChips(numberOfChips);
    }

  }, [isVisibleChips]);
  return (
    <div ref={containerRef} style={{ display: 'flex', flexWrap: isVisibleChips ? 'wrap' : 'nowrap' }}>
      {(isVisibleChips ? props.titles : props.titles.slice(0, visibleChips)).map((title, index) => (
        <FilterChipItem
          key={`${title}-${index}`}
          title={title}
          icon={props.icon}
          isNoSearch={isNoSearch}
          handleSearch={() => {
            if (props.handleSearch) {
              props.handleSearch(title)
            }
          }}
          handleDelete={() => {
            if (props.handleDelete) {
              props.handleDelete(title)
            }
          }}
        />
      ))}
    </div>
  )
}
