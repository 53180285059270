import { Box, Button, Container, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PublicationImageDeclinedCard } from '../../../../../../shared-components/card/PublicationImageDeclinedCard'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import styles from './DeclinedImagesList.module.scss'
import Notiflix from 'notiflix'
import instance from '../../../../../../../providers/axios.config'
import SkeletonImageWithPagination from '../../../../../../shared-components/skeleton/imageWithPagination'
import { typeResponse } from '../../../../../../../utils/types'

type imageProps = {
  thumbURL: string,
  id: string | number,
  imageId: number | string
  reason: string
}

export const DeclinedImagesList = () => {
  const [declinedImages, setDeclinedImages] = useState<any>([])
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 5,
    totalPages: 1
  })

  const handleChange = (event: any, value: any) => {
    setPagination({...pagination, currentPage: value})
  }
  const getDeclinedImages = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/images/declined?limit=5&page=${pagination.currentPage}`)
      setDeclinedImages(res?.data?.images || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }

  useEffect(() => {
    getDeclinedImages()
  }, [pagination?.currentPage])
  const handleDeleteAll = async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const resp = await instance.delete(`seller/images/delete`) as any;
      if ((resp as any).status === 'success') {
        setDeclinedImages([])
        setPagination({currentPage: 1,
          perPage: 5,
          totalPages: 1 })
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }  finally {
      Notiflix.Loading.remove()
    }
    handleOpen()
  }
  const handleDeleteById = async (imageId: any) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const resp = await instance.delete(`seller/images/delete?images[0]=${imageId}`) as typeResponse;
      if ((resp as any).status === 'success') {
        const removeImage = declinedImages.filter((declined: any) => declined?.id !== imageId)
        setDeclinedImages([...removeImage])
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }  finally {
      Notiflix.Loading.remove()
    }
  }

  const handleOpen = () => {
    setOpenDeleteAllDialog(!openDeleteAllDialog)
  }

  return (
    <>
      <ConfirmDialog
        open={openDeleteAllDialog}
        handleOpen={handleOpen}
        handleConfirm={handleDeleteAll}
        dialogMessage={t('Are you sure to delete all of the images')}
        dialogTitle={t('Delete All Images')}
      />
      <Container maxWidth={false} className={styles.container}>
        {!isLoading && !declinedImages?.length ? <><Typography>{t('no_declined_images')}</Typography></> : null}
        {isLoading ? <SkeletonImageWithPagination /> : <>
        {declinedImages.map((item: imageProps) => (
         <Box key={item.id} >
          <PublicationImageDeclinedCard item={{thumbURL: item?.thumbURL, reason:item?.reason, id: item?.id}} onDeleteById={handleDeleteById}/>
         </Box>
        ))}

        {declinedImages?.length ? <Button
          className={styles.deleteAllButton}
          onClick={() => setOpenDeleteAllDialog(true)}
        >
          {t('Delete All')}
        </Button> : null}

        {pagination?.totalPages > 1 && <CustomPagination
          shape='rounded'
          count={pagination?.totalPages}
          page={pagination?.currentPage}
          onChange={handleChange}
          size='large'
          className={styles.pagination}
        />}
        </>}
      </Container>
    </>
  )
}
