import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPasswordField } from '../../textfield/CustomPasswordField'
import { CustomTextfield } from '../../textfield/CustomTextField'
import styles from './Dialogs.module.scss'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key: string, email: string) => void
  dialogTitle: string
  email: string
}

export const ChangeEmailDialog = (props: IProps) => {
  const { open, handleOpen, handleConfirm, dialogTitle, email } = props

  const [reason, setReason] = useState<string>('')
  const [newEmail, setNewEmail] = useState<string>('')

  const { t } = useTranslation()


  return (
    <Dialog open={open} onClose={handleOpen}>
      <Alert severity='info'>
        {t('message_disclaimer_email')}<a href='mailto:submit@layerstock.com'>submit@layerstock.com</a> {t('message_disclaimer_email2')}
      </Alert>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='login'
            variant='outlined'
            required
            helperText={email === newEmail ? t('email_different'): ''}
            label={t('New Email')}
            value={newEmail}
            onChange={(event: any) => {
              setNewEmail(event.target.value)
            }}
          />
          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='login'
            variant='outlined'
            multiline
            rows={3}
            required={true}
            label={t('reason')}
            value={reason}
            onChange={(event: any) => {
              setReason(event.target.value)
            }}
          />

        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} variant="outlined">
          {t('Cancel')}
        </Button>
        <Button variant="contained" disabled={!newEmail || !reason || newEmail === email} onClick={() => handleConfirm(reason, newEmail)}>
          {t('Send')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
