import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import Cookies from 'js-cookie'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import {
  IFilters,
  initialFiltersConst,
  NavigationEnum,
} from '../../../utils/constants'
import { FilterCard } from '../card/FilterCard'
import styles from './SearchBar.module.scss'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCategories, setOrientation, setSearch } from '../../../redux/reducers/searchReducer'

export const SearchBar = () => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigation()
  const initialFilters = withDefault(JsonParam, initialFiltersConst)
  const [filters, setFilters] = useQueryParam<IFilters>(
    'filters',
    initialFilters
  )
  const [searchLocal, setSearchLocal] = useState<string>(filters?.searchFilter || '')
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLocal(e.target.value)

  }
  const dispatch = useDispatch()
  const handleSearchPressed = () => {
    const currentSearchTermsCookies = Cookies.get('searchTermsCookie')
    const parsedSearchTermsCookies = currentSearchTermsCookies
      ? JSON.parse(currentSearchTermsCookies)
      : []
    const newSearchTermsCookies = currentSearchTermsCookies
      ? searchLocal && !parsedSearchTermsCookies.includes(searchLocal) ?  [...parsedSearchTermsCookies, searchLocal] : [...parsedSearchTermsCookies]
      : [searchLocal]
    if (searchLocal?.trim()) {
      Cookies.set('searchTermsCookie', JSON.stringify(newSearchTermsCookies))
    }
    customNavigate(
      `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
        JSON.stringify({
          ...filters,
          searchFilter: searchLocal,
        })
      )}`
    )
    dispatch(setSearch(searchLocal))
  }
  const handleSelectCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    customNavigate(
      `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
        JSON.stringify({
          ...filters,
          category: e.target.value as 'JPG' | 'PNG' | 'EPS',
        })
      )}`
    )
    setFilters({
      ...filters,
      category: e.target.value as 'JPG' | 'PNG' | 'EPS',
    })
    dispatch(setCategories(e.target.value))
  }
  useEffect(() => {
    setSearchLocal(filters?.searchFilter || '')
  }, [filters?.searchFilter])
  return (
    <div className={styles.container}>
      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            backgroundColor: '#f5f5f5',
            paddingRight: 0,
          },
          '& .MuiOutlinedInput-input': {
            color: 'black',
          },
        }}
        autoComplete='off'
        className={styles.searchBar}
        size='small'
        placeholder={t('Search_Placeholder')}
        value={searchLocal || ''}
        onChange={handleSearchChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleSearchPressed()
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button
                className={styles.endIconButton}
                style={{
                  backgroundColor: '#c11b1e',
                }}
                onClick={handleSearchPressed}
              >
                <SearchIcon style={{ color: 'white' }} />
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <div className={styles.categoryContainer}>
        <FilterCard summary={t('Categories')}>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='File Types'
              name='fileTypes'
              value={filters.category}
              onChange={handleSelectCategory}
            >
              <FormControlLabel
                value='EPS'
                control={<Radio color='primary' />}
                label={t('EPS')}
                className={styles.radio}
              />
              <FormControlLabel
                value='JPG'
                control={<Radio color='primary' />}
                label={t('JPG')}
                className={styles.radio}
              />
              <FormControlLabel
                value='PNG'
                control={<Radio color='primary' />}
                label={t('PNG')}
                className={styles.radio}
              />
            </RadioGroup>
          </FormControl>
        </FilterCard>
      </div>
    </div>
  )
}
