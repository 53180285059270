import styles from './AccountSettings.module.scss'
import { Title } from './sections/Title'
import React from 'react'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import { Alert, Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Notiflix from 'notiflix'
import instance from '../../../../providers/axios.config'
import { typeResponse } from '../../../../utils/types'


export const Support = () => {
  const { t } = useTranslation()
  const [message, setMessage] = React.useState('')

  const handleSendMessage = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post(`support`, { message }) as typeResponse;
      if ((resp as any).status === 'success') {
        Notiflix.Notify.success(t('Success'))
        setMessage('')
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      console.error('Error change password:', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }

  return(
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Title title={t('support')} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}><Alert severity="info">{t('support_text')}</Alert></div>

        <CustomTextfield
          className={styles.textfield}
          size='small'
          name='message'
          rows={4}
          multiline
          variant='outlined'
          label={t('message')}
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
        />
        <Box>
          <Button
            variant='contained'
            disabled={!message}
            onClick={() => handleSendMessage()}
          >
            {t('send')}
          </Button>
        </Box>
      </div>
    </div>

  )
}

export default Support