import { Dispatch, SetStateAction, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from './Recaptcha.module.scss'

interface IProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const Recaptcha = (props: IProps) => {
  const { isOpen, setIsOpen } = props
  const [captchaValue, setCaptchaValue] = useState(null)

  const onCaptchaChange = (value: any) => {
    setCaptchaValue(value)
    setIsOpen(value)
  }

  return (
    <div className={styles.container}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
        onChange={onCaptchaChange}
      />
    </div>
  )
}
