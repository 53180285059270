import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  FormControlLabel,
  RadioGroup
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomRadio } from '../checkbox/CustomRadio'
import styles from './methodPayment.module.scss'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key?: string) => void
  dialogTitle: string
  onClose?: () => void
}

export const MethodPayment = (props: IProps) => {
  const { open, handleOpen, handleConfirm, dialogTitle, onClose } = props
  const { t } = useTranslation()


  const [payment, setPayment] = useState('stripe')

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <FormControl component='fieldset' color='error'>
            <RadioGroup
              defaultValue='stripe'
              name='stripe'
              value={payment}
              onChange={(e) => {
                setPayment(e.target.value)
              }}
            >
              <FormControlLabel
                value='stripe'
                control={<CustomRadio />}
                label={t('stripe')}
              />
              <FormControlLabel
                value='paypal'
                control={<CustomRadio />}
                label={t('paypal')}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined'>
          {t('Cancel')}
        </Button>
        <Button onClick={() => handleConfirm(payment)}
                variant='contained' color='primary'>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
