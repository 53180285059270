import styles from './HomeSeller.module.scss'
import { WidgetHomeSeller } from '../../../../../../shared-components/WidgethomeSeller'
import { Fragment, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CheckIcon from '@mui/icons-material/Check';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DownloadIcon from '@mui/icons-material/Download';
import EuroIcon from '@mui/icons-material/Euro';
import { ROUTES } from '../../../../../../../utils/constants'
import ImageIcon from '@mui/icons-material/Image';
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import i18next from 'i18next'
import { typeResponse } from '../../../../../../../utils/types'

const dataHome = [
  {
    id: 'images_pending',
    title: i18next.t('home_pending_images'),
    count: '2',
    icon: <PendingActionsIcon />,
    color: '#fb5c18',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.PUBLICATIONS}/${ROUTES.UNFINISHED}`
  },
  {
    id: 'images_licensing',
    title: i18next.t('home_licensing_images'),
    count: '2',
    icon: <ImageIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.PUBLICATIONS}/${ROUTES.LICENSING}`
  },
  {
    id: 'images_declined',
    title: i18next.t('home_declined_images'),
    count: '2',
    icon: <CloseIcon sx={{fontSize: 30}}/>,
    color: 'red',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.PUBLICATIONS}/${ROUTES.DECLINED}`
  },
  {
    id: 'images_approved',
    title: i18next.t('home_approved_images'),
    count: '2',
    icon: <CheckIcon sx={{fontSize: 30}}/>,
    color: '#08c908',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.PUBLICATIONS}`
  },
  {
    id: 'earnings_last_month',
    title: i18next.t('home_earning_last_month'),
    count: '2',
    icon: <EuroIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.EARNINGS}`
  },
  {
    id: 'daily_downloads',
    title: i18next.t('daily_downloads'),
    count: '2',
    icon: <DownloadIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.EARNINGS}`
  },
  {
    id: 'balance',
    title: i18next.t('balance'),
    count: '2',
    icon: <AccountBalanceIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
  },
  {
    id: 'withdrawals_last_month',
    title: i18next.t('withdraw_last_month'),
    count: '2',
    icon: <AccountBalanceIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.EARNINGS}`
  },
  {
    id: 'total_earning',
    title: i18next.t('total_earning'),
    count: '2',
    icon: <AccountBalanceIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
  },
  {
    id: 'pending',
    title: 'Pending',
    count: '2',
    icon: <AccountBalanceIcon sx={{fontSize: 30}}/>,
    color: '#1A73E8',
    path: `/${ROUTES.ACCOUNT}/${ROUTES.EARNINGS}`
  },
]
export type cardSeller = {
  id: string,
  title?: string,
  count: number | string | null,
  icon?: any,
  color?: string,
  path?: string
}

interface IResponse extends typeResponse {
  data: {
    balance: string,
    daily_downloads: number,
    earnings_last_month: string,
    images_approved: number,
    images_declined: number,
    images_licensing: number,
    images_pending: number,
    withdrawals_last_month: string
  }
}

export const HomeSeller = () => {

  const [sellerCards, setSellerCards] = useState<cardSeller[]>([]);
  const [isLoading, setIsLoading] = useState(false)


  const getMySellerCards = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/cards`) as any;
      if(res.data && res.data.balance) {
        const newSellerCards = dataHome.map((card: any) => {
          return {
            ...card,
            count: res.data[`${card.id}`]
          }
        });
        setSellerCards([...newSellerCards] || [])
      }
      setIsLoading(false)
    } catch (error) {

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    getMySellerCards()
  }, [])
  return (
    <div className={styles.content}>
      <div className={styles.contentCards}>
        {isLoading ? [1, 2, 3, 4, 5, 6, 7].map((item) => (
          <Fragment key={item}>
            <WidgetHomeSeller loading={isLoading}/>
          </Fragment>
        )) : sellerCards.map((data) => (
          <Fragment key={`${data?.id}`}>
            <WidgetHomeSeller data={data} loading={isLoading}/>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default HomeSeller;