import { Container } from '@mui/material'
import Notifications from '../../../shared-components/notifications'

export const NotificationsMain = () => {

  return (
    <Container>
      <Notifications />
    </Container>
  )
}
export default NotificationsMain;