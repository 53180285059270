import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip, Typography
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import { CustomCheckbox } from '../../../../../../shared-components/checkbox/CustomCheckbox'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import styles from './MyPublications.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { monthsBetweenDates } from '../../../../../../../utils/helpers'
import Skeleton from '@mui/material/Skeleton';
import { IImage, typeResponse } from '../../../../../../../utils/types'
import { useTranslation } from 'react-i18next'

interface IResponse extends typeResponse{
  data:{
    images: IImage[],
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}

export const MyPublications = () => {
  const { t } = useTranslation()
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<
    number | string
  >()
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })
  const [myPublications, setMyPublications] = useState<any[]>([])


  const getMyPublications = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/images/approved?limit=${pagination.perPage}&page=${pagination.currentPage}`) as IResponse
      setMyPublications(res?.data?.images || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }

  useEffect(() => {
    getMyPublications()
  }, [pagination?.currentPage])

  const handleChange = (event: any, value: number) => {
    setPagination({...pagination, currentPage: value})
  }

  const handleConfirm = async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const resp = await instance.delete(`seller/images/delete?images[0]=${selectedItemForDelete}`) as any;
      if ((resp as any).status === 'success') {
        const removeImage = myPublications.filter((publication) => publication?.id !== selectedItemForDelete)
        setMyPublications([...removeImage])
        setSelectedItemForDelete('')
        Notiflix.Notify.success(t('Success'))
      } else {
        setSelectedItemForDelete('')
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      setSelectedItemForDelete('')
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }  finally {
      Notiflix.Loading.remove()
    }

    setOpenDeletePopup(false)
  }

  const handleOpen = () => {
    setOpenDeletePopup(!openDeletePopup)
  }

  return (
    <>
      {openDeletePopup && (
        <ConfirmDialog
          open={openDeletePopup}
          handleOpen={handleOpen}
          handleConfirm={() => {
            handleConfirm()
          }}
          dialogTitle={t('Delete Image')}
          dialogMessage={t('Are you sure to delete imageId', {
            imageId: selectedItemForDelete,
          })}
        />
      )}
      <div className={styles.table}>
        <TableContainer component={Paper} className={styles.table}>
          <Table>
            {(!isLoading && !myPublications?.length) ? <><Typography>{t('no_publication')}</Typography></> : null}
            {myPublications?.length ? <TableHead>
              <TableRow className={styles.tableRow}>
                <TableCell />
                <TableCell align='center'>
                  {t('Description and Keywords')}
                </TableCell>
                <TableCell align='center'>{t('Upload Date')}</TableCell>
                <TableCell align='center'>{t('Option')}</TableCell>
                <TableCell align='center'>
                  {t('Use in AI Generation')}
                </TableCell>
                <TableCell align='center'>{t('Delete')}</TableCell>
              </TableRow>
            </TableHead> : null}
            <TableBody>
              {(isLoading ? [1, 2, 3, 4] : myPublications).map((publication, index) => (
                <TableRow key={`${publication?.id}-${index}`} className={styles.tableRow}>
                  <TableCell className={styles.imageContainer}>
                    <PublicationImageCard
                      image={publication?.thumbURL}
                      imageId={publication?.id}
                      canBeDeleted={false}
                      isLoading={isLoading}
                    />
                  </TableCell>
                  <TableCell align='center' className={styles.description}>
                    {isLoading ? <Skeleton /> : <div className={styles.descriptionContainer}>
                      <div className={styles.titleDescriptionAndKeywords}>
                        {t('Description')}
                      </div>
                      <div>{publication?.title}</div>
                      <div className={styles.titleDescriptionAndKeywords}>
                        {t('Keyowrds')}
                      </div>
                      <div>{publication?.keywords?.toString()}</div>
                    </div>}
                  </TableCell>
                  <TableCell align='center'>
                    {isLoading ? <Skeleton /> : <>{moment(publication?.createdAt).format('MM-DD-YYYY')}</>}
                  </TableCell>
                  <TableCell align='center'>
                    {isLoading ? <Skeleton /> :
                    <FormControl component='fieldset' disabled={true}>
                      <FormControlLabel
                        control={<CustomCheckbox checked={publication?.isAdult === 1} />}
                        label={t('Adult content')}
                      />
                      <FormControlLabel
                        control={<CustomCheckbox checked={publication?.isEditorial === 1}/>}
                        label={t('Editorial')}
                      />
                      <FormControlLabel
                        control={<CustomCheckbox checked={publication?.isAi === 1}/>}
                        label={t('Generated by AI')}
                      />
                    </FormControl>}
                  </TableCell>
                  <TableCell align='center'>
                    {isLoading ? <Skeleton /> : <FormControl component='fieldset' disabled={true}>
                      <FormControlLabel
                        disabled
                        control={<CustomCheckbox checked={publication?.isAi === 1} />}
                        label={t('Use in AI Generation')}
                      />
                    </FormControl>}
                  </TableCell>
                  <TableCell align='center'>
                    {isLoading ? <Skeleton /> : <Tooltip title={t('Deletion_avaiable')}><Button
                      className={`${styles.deleteButton} ${monthsBetweenDates(moment(publication?.createdAt), moment()) <= 6 ? styles.disabledDeleteButton : ''}`}
                      onClick={() => {
                        if(monthsBetweenDates(moment(publication?.createdAt), moment()) >= 6) {
                          setSelectedItemForDelete(publication?.id)
                          handleOpen()
                      }}}
                    >
                      {t('Delete')}
                    </Button></Tooltip>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {pagination?.totalPages > 1 && <CustomPagination
          shape='rounded'
          count={pagination?.totalPages}
          page={pagination?.currentPage}
          onChange={handleChange}
          size='large'
          className={styles.pagination}
        />}
      </div>
    </>
  )
}
