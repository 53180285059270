import { createSlice } from '@reduxjs/toolkit'

const initialState: { count: number; errorFeedback: string } = {
  count: 0,
  errorFeedback: '',
}

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setCountNotifications: (state, action) => {
      state.count = action.payload
    },
    setErrorFeedback: (state, action) => {
      state.errorFeedback = action.payload
    },
  },
})

export const { setCountNotifications, setErrorFeedback } = notificationsSlice.actions

export default notificationsSlice.reducer
