import 'react-dropzone-uploader/dist/styles.css'
import { useDropzone } from 'react-dropzone'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { useCallback, useState } from 'react'
import Notiflix from 'notiflix'
import { useTranslation } from 'react-i18next'

const StyledDropzone = styled(Box)<any>(({ theme, disabled }) => ({
  border: disabled ? '2px dashed grey' : '2px dashed #c11b1e',
  borderRadius: '5px',
  height: '100%',
  cursor: 'pointer',
  paddingLeft: '8px',
  opacity: disabled ? 0.6 : 1,
}))
interface DragAndDropCustomProps {
  onUpload: (key: any, type: string) =>  void;
  acceptFile: any;
  type: string;
  description: string;
  title: string;
  maxLength?: number;
  isLoading: boolean;
  setIsLoading: (key:boolean) => void;
}
export const DragAndDropCustom = (props: DragAndDropCustomProps) => {
  const { t } = useTranslation()
  const {onUpload, acceptFile, type, description, maxLength, isLoading, setIsLoading} = props;


  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: any) => {
    if(fileRejections?.length) {
      for (let i = 0; i < fileRejections.length; i += 1) {
        if (fileRejections[i].errors !== undefined) {
          if (fileRejections[i].errors[0].code === 'file-invalid-type') {
            return Notiflix.Report.failure(
              'Error',
              `${t('images_invalid_type', { extension: type.toUpperCase() })}`,
              'OK'
            );
          }
        }
      }
      Notiflix.Report.failure(
        'Error',
        `${t('images_exceeded_maximum')}`,
        'OK'
      )
    }
    setIsLoading(true)
    await onUpload(acceptedFiles, type)
    setIsLoading(false)

  }, [])
  const { getRootProps, getInputProps, fileRejections } = useDropzone({onDrop, maxFiles: maxLength, disabled: isLoading,  accept: {
      ...acceptFile
    },});
  return (
    <StyledDropzone {...getRootProps()} disabled={isLoading || maxLength === 0}>
      <input {...getInputProps()}/>
      {isLoading ? <p>{t('please_wait')}</p> : <p>{description}</p>}
    </StyledDropzone>
  )
}
