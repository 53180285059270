import { Button, Container, Typography } from '@mui/material'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Notiflix from 'notiflix';

// Axios
import instance from '../../../../../../../providers/axios.config';

// Utils
import { EAxiosResponseStatuses, ISellerPayout } from '../../../../../../../utils/types';

interface IConfirmRequestPayout {
  data: {
    payout: ISellerPayout;
  };
  status: keyof typeof EAxiosResponseStatuses;
  message: string | null;
}

enum EStatus {
  'success',
  'error'
}

export const Thankyou = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();

  const [status, setStatus] = useState<keyof typeof EStatus | null>(null);

  const confirmPayout = async () => {
    Notiflix.Loading.standard();
    try {
      const resp: IConfirmRequestPayout = await instance.post('seller/tax-center/request-payment/confirm', { token });
      if (resp) {
        setStatus(resp.status);
      }
    } catch (e) {
      setStatus('error');
    } finally {
      Notiflix.Loading.remove();
    }
  };

  useEffect(() => {
    confirmPayout();
  }, []);
  return(
    <Container sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4}}>
      <VerifiedUserOutlinedIcon sx={{color: '#09beeb', fontSize: 120}}/>
      <Typography variant="h2">{status === 'success' ? t('thank_you') : t('error')}</Typography>
      <Typography marginTop={2} fontSize={24} color="text.secondary">{status === 'error' ? t('error_api') : t('success_payout_confirmation')} </Typography>
      {status === 'success' && <Typography marginTop={2} fontSize={24} color="text.secondary">{t('an_admin_will_analyze_your_payout')}</Typography>}
      <Typography marginTop={2} fontSize={24} color="text.secondary">{t('contact_layerstock_statff')}</Typography>
      <Button onClick={() => navigate('/')} variant="contained" size="large" sx={{backgroundColor: '#ff8800', marginTop: 8}}>{t('go_to_home')}</Button>
    </Container>
  )
}

export default Thankyou;