import { ImageList, useMediaQuery, useTheme } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MainAssetsCard } from '../../../../../../shared-components/card/MainAssetsCard'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { Title } from '../../Title'
import styles from './BuyerHome.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import SkeletonAssetsImages from '../../../../../../shared-components/skeleton/assetsImages'
import { IImage, typeResponse } from '../../../../../../../utils/types'

interface IResponseImages  extends typeResponse {
  data:{
    list: IImage[],
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}

export const BuyerHome = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [visitedImages, setVisitedImages] = useState<IImage[]>([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 1
  })
  const handleChange = (event: any, value: number) => {
    setPagination({...pagination, currentPage: value})
  }

  const getMyImages = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`history?limit=${pagination.perPage}&page=${pagination.currentPage}`) as IResponseImages
      setVisitedImages(res?.data?.list || [])
      if(res?.data?.pagination && res?.data?.pagination.perPage) {
        setPagination({ ...res?.data?.pagination })
      }
      setIsLoading(false)
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }


  useEffect(() => {
    getMyImages()
  }, [])

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const md = useMediaQuery(theme.breakpoints.down('md'))
  const xs = useMediaQuery(theme.breakpoints.down('xs'))
  const lg = useMediaQuery(theme.breakpoints.down('lg'))

  const getMasonryColumns = () => {
    if (sm) return { col: 1, gap: 0 }
    if (md) return { col: 3, gap: 5 }
    if (xs) return { col: 1, gap: 5 }
    if (lg) return { col: 4, gap: 5 }
    return { col: 4, gap: 5 }
  }

  const masonryOptions = getMasonryColumns()

  const handleDeleteImage = async (imageId: number | string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.delete(`history/${imageId}`)
      if ((resp as any).status === 'success') {
        const deleteImage = visitedImages.filter((image) => image.id !== imageId) as any;
        setVisitedImages([...deleteImage])
        Notiflix.Notify.success(t('Success'))
      }
    } catch (error) {
      console.error('Error delete files:', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }

  if(!isLoading && !visitedImages?.length) {
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <Title title={t('Home')} />
        </div>
        <div className={styles.content}>
          {t('no_images_visited')}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div>
        <Title title={t('Home')} />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{t('Visited Images')}</div>

        {isLoading ?  <SkeletonAssetsImages limit={20}/> :
        <ImageList
          variant='masonry'
          cols={masonryOptions.col}
          gap={masonryOptions.gap}
        >
          {visitedImages.map((image) => (
            <Fragment key={image.id}>
              <MainAssetsCard
                isDeletable={true}
                isFavorite={image?.isFavorite}
                description={image?.title}
                image={image?.mediumURL}
                imageId={image.id}
                onDeleteImage={handleDeleteImage}
              />
            </Fragment>
          ))}
        </ImageList> }

        {pagination?.totalPages > 1 && <CustomPagination
          shape='rounded'
          count={pagination?.totalPages}
          page={pagination?.currentPage}
          onChange={handleChange}
          size='large'
          className={styles.pagination}
        />}
      </div>
    </div>
  )
}
