import { Logout } from '@mui/icons-material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import HelpIcon from '@mui/icons-material/Help'
import HomeIcon from '@mui/icons-material/Home'
import ImageIcon from '@mui/icons-material/Image'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PaymentIcon from '@mui/icons-material/Payment'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import {
  Box, Divider,
  FormControl,
  FormControlLabel, Grid, Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Popover,
  RadioGroup, Typography, useMediaQuery
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import i18next from 'i18next'
import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import instance from '../../../../providers/axios.config'
import { RootState } from '../../../../redux/reducers/rootReducer'
import { CustomRadio } from '../../../shared-components/checkbox/CustomRadio'
import styles from './AccountDetails.module.scss'
import { Banner } from './Banner'
import { Outlet, useLocation } from 'react-router-dom'
import { useCustomNavigation } from '../../../../hooks/useCustomNavigate'
import { NOT_CHECKED, ROUTES, VALID } from '../../../../utils/constants'
import { setLoading, setRole, setUserData } from '../../../../redux/reducers/usersReducer'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../../../shared-components/dialogs/ConfirmDialog'

export type TabValue =
  | 'HOME'
  | 'UPLOAD'
  | 'PUBLICATION'
  | 'RELEASES'
  | 'EARNINGS'
  | 'PAYMENT'
  | 'SUPPORT'

const tabs = [
  { key: i18next.t('Home'), icon: <HomeIcon />, path: '', value: 'HOME', filters: [] },
  {
    key: i18next.t('Upload Files'),
    icon: <FileUploadIcon />,
    value: 'UPLOAD',
    path: ROUTES.UPLOAD,
    filters: []
  },
  {
    key: i18next.t('Publication'),
    icon: <ImageIcon />,
    value: 'PUBLICATION',
    path: ROUTES.PUBLICATIONS,
    filters: [
      {
        name: i18next.t('Unfinished'),
        value: '0',
        path: ROUTES.UNFINISHED
      },
      {
        name: i18next.t('Licensing'),
        value: '1',
        path: ROUTES.LICENSING
      },
      {
        name: i18next.t('Declined'),
        value: '2',
        path: ROUTES.DECLINED
      },
      {
        name: i18next.t('Examination'),
        value: '3',
        path: ROUTES.EXAMINATION
      }
    ]
  },
  {
    key: i18next.t('Releases'),
    icon: <TextSnippetIcon />,
    value: 'RELEASES',
    path: ROUTES.RELEASES,
    filters: []
  },
  {
    key: i18next.t('Earnings'),
    icon: <MonetizationOnIcon />,
    value: 'EARNINGS',
    path: ROUTES.EARNINGS,
    filters: [{
      name: i18next.t('Statistics'),
      value: '0',
      path: ROUTES.STATISTICS
    }]
  },
  {
    key: i18next.t('Payment'),
    icon: <PaymentIcon />,
    value: 'PAYMENT',
    path: ROUTES.MYPAYMENT,
    filters: [{
      name: 'Payment History',
      value: '0',
      path: ROUTES.HISTORY
    }, {
      name: 'Tax Center',
      value: '1',
      path: ROUTES.TAXCENTER
    }]
  },
  {
    key: i18next.t('Support'),
    icon: <HelpIcon />,
    value: 'SUPPORT',
    filters: []
  }
]
const logoutTab = {
  key: i18next.t('Log out'),
icon: <Logout />,
  value: 'LOGOUT',
  filters: []
}
export const AccountDetailsSeller = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const matchesMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch()
  const splitPathname = location.pathname.split('/')
  const customNavigate = useCustomNavigation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activePath, setActivePath] = useState('')
  const [selectedSection, setSelectedSection] = useState<string>(splitPathname[splitPathname.length - 1] === ROUTES.ACCOUNT ? '' : splitPathname[splitPathname.length - 1])
  const [isOpenedConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const account = useSelector((state: RootState) => state.user.usersData)

  const handleSelectSectionFilter = (
    path: string, event: React.ChangeEvent<HTMLInputElement>
  ) => {
    customNavigate(`${path}/${event.target.value || ''}`)
    setSelectedSection(event.target.value)
  }
  const handleSelectSectionFilterMobile = (
    path: string, childPath: string
  ) => {
    customNavigate(`${path}/${childPath || ''}`)
    setSelectedSection(childPath)
    handleClose()
  }

  const handleChangeSection = (newValue: string) => {
      if(newValue === ROUTES.UPLOAD && account?.status === NOT_CHECKED) {
        setActivePath(ROUTES.PUBLICATIONS)
        setSelectedSection(ROUTES.EXAMINATION)
        handleClose()
        customNavigate(`${ROUTES.PUBLICATIONS}/${ROUTES.EXAMINATION}`)
      } else {
        setActivePath(newValue)
        setSelectedSection(newValue)
        handleClose()
        customNavigate(newValue || '')
      }
  }

  const handleOpenSettings = () => {
    customNavigate(`${ROUTES.SETTINGS}`)
  }
  const handleLogout = async () => {
    dispatch(setLoading(true))
    const resp: { status: string } = await instance.post('/logout')
    if (resp.status === 'success') {
      customNavigate('/')
      dispatch(setLoading(false))
      dispatch(setUserData(null))
      dispatch(setRole(null))
      localStorage.removeItem('authToken')
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const renderListMobile = () => {
    return (
      <>
        <Link
          aria-describedby={id}
          component="button"
          underline="none"
          variant="body2"
          sx={{ fontWeight: 600,
            fontSize: 14,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 2,
            paddingRight: 1,
            paddingTop: 2,
            paddingBottom: 2,
            color: '#6c757d'}}
          onClick={handleClick}
        >
          <Typography fontSize={16} fontWeight={600}>{ selectedSection ? selectedSection?.charAt(0).toUpperCase()
            + selectedSection?.slice(1) : t('Home')}</Typography>
          <ArrowDropDownIcon />
        </Link>
        <Popover
          sx={{width: '100%'}}
          id={id}
          classes={{paper: styles.rootPaper}}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {tabs.slice(0, -1).map((item) => {
            const filtersItem = account?.status === VALID ? item.filters.filter((it) => it.path !== ROUTES.EXAMINATION) : [...item.filters]
            const filtersItemExamination = account?.status === NOT_CHECKED ? filtersItem.filter((it) => it.path !== ROUTES.UNFINISHED) : filtersItem.filter((it) => it.path !== ROUTES.EXAMINATION)
            return (
              <Box key={item.key} sx={{ color: selectedSection === item.value ? '#c11b1e' : '', width: '100%'}}>
                  <ListItem disablePadding key={item.key} sx={{ color: selectedSection === item.value ? '#c11b1e' : ''}}>
                    <ListItemButton
                      onClick={() => {
                        handleChangeSection(item.path || '')
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          marginRight: 2,
                          borderRadius: 1,
                          backgroundColor: 'transparent',
                          minWidth: 0,
                          color: splitPathname[splitPathname.length - 1] === item.path ? '#c11b1e' : ''
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.key}
                        primaryTypographyProps={{
                          fontWeight: 600,
                          fontSize: 14,
                          color: '#6c757d'
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                {(filtersItemExamination || []).map((child) => (
                    <ListItem disablePadding  key={child.name} sx={{ color: selectedSection === child.path ? '#c11b1e' : '', padding: 0, minHeight: '32px', marginLeft: 8}}>
                      <ListItemButton
                        onClick={() => handleSelectSectionFilterMobile(item?.path || '' , child?.path || '')}
                      >
                        <ListItemText
                          primary={child.name}
                          primaryTypographyProps={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: '#6c757d'
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                ))}
              </Box>
            )
          })}
          <Divider/>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpenConfirmDialog(true)}>
              <ListItemIcon
                sx={{
                  marginRight: 2,
                  borderRadius: 1,
                  backgroundColor: '#f5f5f5',
                  minWidth: 0
                }}
              >
                {logoutTab.icon}
              </ListItemIcon>
              <ListItemText
                primary={logoutTab.key}
                primaryTypographyProps={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: '#6c757d'
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleChangeSection(tabs[tabs.length - 1].value as 'SUPPORT')
              }}
            >
              <ListItemIcon
                sx={{
                  marginRight: 2,
                  borderRadius: 1,
                  backgroundColor: '#f5f5f5',
                  minWidth: 0,
                  color:
                    splitPathname[splitPathname.length - 1] === tabs[tabs.length - 1].value
                      ? '#c11b1e'
                      : ''
                }}
              >
                {tabs[tabs.length - 1].icon}
              </ListItemIcon>
              <ListItemText
                primary={tabs[tabs.length - 1].key}
                primaryTypographyProps={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: '#6c757d'
                }}
              />
            </ListItemButton>
          </ListItem>
        </Popover>
      </>
    )
  }
  const renderDesktop = () => {
    return (
      <>
        <List className={styles.list}>
          {tabs.slice(0, -1).map((item) => {
            const filtersItem = account?.status === VALID ? item.filters.filter((it) => it.path !== ROUTES.EXAMINATION) : [...item.filters]
            const filtersItemExamination = account?.status === NOT_CHECKED ? filtersItem.filter((it) => it.path !== ROUTES.UNFINISHED) : filtersItem.filter((it) => it.path !== ROUTES.EXAMINATION)
            return (
              <Fragment key={item.key}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleChangeSection(item.path || '')
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        marginRight: 2,
                        borderRadius: 1,
                        backgroundColor: '#f5f5f5',
                        minWidth: 0,
                        color: splitPathname[splitPathname.length - 1] === item.path ? '#c11b1e' : ''
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.key}
                      primaryTypographyProps={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: '#6c757d'
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                {item.filters?.length && item.path === activePath ? (
                  <FormControl
                    component='fieldset'
                    color='error'
                    className={styles.radioBoxForm}
                  >
                    <RadioGroup
                      defaultValue={null}
                      name='orientation'
                      onChange={(event: any) => handleSelectSectionFilter(item?.path || '' ,event)}
                      value={splitPathname[splitPathname.length - 1]}
                    >
                      {filtersItemExamination.map((filter) => {
                        return (
                          <FormControlLabel
                            key={`${filter?.value}-${filter?.name}`}
                            value={filter.path}
                            control={<CustomRadio />}
                            label={filter?.name}
                            className={styles.radiobox}
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          })}
        </List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setOpenConfirmDialog(true)}>
            <ListItemIcon
              sx={{
                marginRight: 2,
                borderRadius: 1,
                backgroundColor: '#f5f5f5',
                minWidth: 0
              }}
            >
              {logoutTab.icon}
            </ListItemIcon>
            <ListItemText
              primary={logoutTab.key}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: '#6c757d'
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleChangeSection(tabs[tabs.length - 1].value as 'SUPPORT')
            }}
          >
            <ListItemIcon
              sx={{
                marginRight: 2,
                borderRadius: 1,
                backgroundColor: '#f5f5f5',
                minWidth: 0,
                color:
                  splitPathname[splitPathname.length - 1] === tabs[tabs.length - 1].value
                    ? '#c11b1e'
                    : ''
              }}
            >
              {tabs[tabs.length - 1].icon}
            </ListItemIcon>
            <ListItemText
              primary={tabs[tabs.length - 1].key}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: '#6c757d'
              }}
            />
          </ListItemButton>
        </ListItem>
      </>
    )
  }
  return (
    <div className={styles.container}>
      <Grid container spacing={1}>
        <Grid item md={2} xs={12} sx={{display:'flex', justifyContent: 'left', position: 'sticky'}} >
          <Box className={`${styles.listContainer} ${styles.leftContainer}`}>
            {matchesMobile ? renderListMobile() : renderDesktop()}
          </Box>
        </Grid>
        <Grid item md={10} xs={12}>
          <Banner setOpenSettings={handleOpenSettings} />
          <Outlet />
        </Grid>
      </Grid>
      {isOpenedConfirmDialog && (
        <ConfirmDialog
          open={isOpenedConfirmDialog}
          handleOpen={() => setOpenConfirmDialog(false)}
          handleConfirm={() => handleLogout()}
          dialogTitle={t('Logout')}
          dialogMessage={t('Are you sure to logout from your account?')}
        />
      )}
    </div>
  )
}
