import Skeleton from '@mui/material/Skeleton'
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../dialogs/ConfirmDialog'

export const ImageCardStep1 = ({item, index, type, handleDeleteImage}: any) => {
  const { t } = useTranslation()
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const handleConfirm = () => {
    if(handleDeleteImage) {
      handleDeleteImage(item.imageId, type)
    }
    setOpenDeletePopup(false)
  }

  const handleOpen = () => {
    setOpenDeletePopup(!openDeletePopup)
  }
  return (
    <ImageListItem sx={{width: 120, height: 100}}>
      {item?.thumbnail ? <img
        src={item?.thumbnail}
        alt={item?.originalFilename}
        loading="lazy"
      /> :   <Skeleton  height={200} />}
      <ImageListItemBar position="below" title={item?.originalFilename || item?.originalFileName} />
      {item?.thumbnail ? <ImageListItemBar
        sx={{
          background:
            'linear-gradient(to left, rgba(0,0,0,0.2) 0%, ' +
            'rgba(0,0,0,0.1) 20%, rgba(0,0,0,0) 100%)',
        }}
        title={item.title}
        position="top"
        actionIcon={
          <IconButton
            onClick={handleOpen}
            sx={{ backgroundColor: '#c11b1e', color: '#fff' }}
            aria-label={`star ${item.title}`}
          >
            <CloseIcon sx={{ width: 15, height: 15 }} />
          </IconButton>
        }
        actionPosition="right"
      /> : null}
      {openDeletePopup && (
        <ConfirmDialog
          open={openDeletePopup}
          handleOpen={() => {
            handleOpen()
          }}
          handleConfirm={() => {
            handleConfirm()
          }}
          dialogTitle={t('Delete Image')}
          dialogMessage={t('Are you sure to delete this image')}
        />
      )}
    </ImageListItem>
  )
}