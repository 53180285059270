import {
  Paper,
  Button,
  Dialog,
  Link,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './dialog.module.scss'
import { Controller, useForm } from 'react-hook-form'
import { CustomTextfield } from '../textfield/CustomTextField'
import AutocompleteCountries from '../textfield/AutocompleteCountries'
import React, { useState } from 'react'
import { CustomRadio } from '../checkbox/CustomRadio'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'


type billingInformation = {
  lastName?: string,
  firstName?: string,
  address?: string,
  city?: string,
  zip?: string,
  state?: string,
  company?: string,
  vat_number?: string,
  country?: string,
  vatNumber?: string,
  phone_number?: string,
  payment?: string,
  email?: string,
  edit?: boolean,
};

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key: billingInformation) => void
}

type Address = {
  address?: string,
  city?: string,
  zip?: string,
  state?: string,
  company?: string,
  vat_number?: string,
  phone_number?: string,
  country?: string,
  vatNumber?: string,
  phone?: string,
};
export const DialogFastDownload = (props: IProps) => {
  const { open, handleOpen, handleConfirm } = props
  const { t } = useTranslation()
  const [editAddress, setEditAddress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const account = useSelector((state: RootState) => state.user.usersData)
  const address = account?.addresses?.length ? { ...account?.addresses[0] } : {} as Address
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...address,
      firstName: account?.firstName,
      lastName: account?.lastName,
      email: account?.email,
      payment: 'stripe'
    }
  })
  const [selectedCountry, setSelectedCountry] = useState<{ name?: string, value?: string }>(
    { name: '', value: '' }

  )
  const handleSubmitForm = async (data: billingInformation) => {
    if(editAddress || !account?.addresses?.length) {
      handleConfirm({ ...data, country: selectedCountry?.value, edit: true})
    }
  }

  const handleEditForm= () => {
    setEditAddress(!editAddress)
  }
  const handleChangePayment = (event: any) => {
    setPaymentMethod(event.target.value)
  }
  const setDisabledButton = () => {
    if(editAddress && errors) {
      return true
    } if(!account?.addresses?.length) {
      return !Object.keys(errors)
    }
  }

  const handleSaveNoEdit = () => {
    handleConfirm({ payment: paymentMethod })
  }

  const renderCardDetail = () => {
    return (
      <Box>
      <Paper sx={{padding: 2, marginBottom: 2}}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            First Name: {account?.firstName}
          </Grid>
          <Grid item xs={12} sm={6}>
            Last Name: {account?.lastName}
          </Grid>
          <Grid item xs={12} sm={12}>
            Address: {address?.address}
          </Grid>
          <Grid item xs={12} sm={6}>
            City: {address?.city}
          </Grid>
          <Grid item xs={12} sm={6}>
            Zip/PostalCode: {address?.zip}
          </Grid>
          <Grid item xs={12} sm={6}>
            Country: {address?.country}
          </Grid>
          <Grid item xs={12} sm={6}>
            State: {address?.state}
          </Grid>
          <Grid item xs={12} sm={6}>
            Company: {address?.company}
          </Grid>
          <Grid item xs={12} sm={6}>
            VAT Number: {address?.vatNumber}
          </Grid>
          <Grid item xs={12} sm={6}>
            Phone: {account?.phone}
          </Grid>
          <Grid item xs={12} sm={6}>
            Email: {account?.email}
          </Grid>
        </Grid>
        <Link
          component="button"
          variant="body2"
          onClick={handleEditForm}
        >
          Edit address
        </Link>
      </Paper>
        {!editAddress && <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Payment</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="stripe"
            name="radio-buttons-group"
            onChange={handleChangePayment}
            value={paymentMethod}
          >
            <FormControlLabel value="stripe" control={<CustomRadio />} label="Stripe" />
            <FormControlLabel value="paypal" control={<CustomRadio />} label="Paypal" />
          </RadioGroup>
        </FormControl>}
      </Box>
    )
  }
  const renderForm = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               variant='outlined'
                               inputRef={ref}
                               error={errors?.firstName}
                               helperText={errors?.firstName ? 'This field is required' : ''}
                               label={t('First Name')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='lastName'
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            control={control}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               inputRef={ref}
                               error={errors?.lastName}
                               helperText={errors?.lastName ? 'This field is required' : ''}
                               variant='outlined'
                               label={t('Last Name')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name='address'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               inputRef={ref}
                               variant='outlined'
                               error={errors?.address}
                               helperText={errors?.address ? t('error_address') : ''}
                               label={t('Address')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='city'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               inputRef={ref}
                               variant='outlined'
                               error={errors?.city}
                               helperText={errors?.city ? t('error_city') : ''}
                               label={t('City')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='zip'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               inputRef={ref}
                               variant='outlined'
                               error={errors?.zip}
                               helperText={errors?.zip ? t('error_zip') : ''}
                               label={t('Zip/Postal Code')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteCountries selectedCountry={selectedCountry}
                                 size='small'
                                 className={styles.textfield}
                                 setSelectedCountry={setSelectedCountry} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='state'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               error={errors?.state}
                               helperText={errors?.state ? t('error_state') : ''}
                               variant='outlined'
                               inputRef={ref}
                               label={t('State')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='company'
            control={control}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               variant='outlined'
                               inputRef={ref}
                               label={t('Company')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='vat_number'
            control={control}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               variant='outlined'
                               inputRef={ref}
                               label={t('VAT Number')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='phone_number'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               variant='outlined'
                               error={errors?.phone_number}
                               helperText={errors?.phone_number ? t('error_phone') : ''}
                               inputRef={ref}
                               label={t('Phone Number')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name='email'
            control={control}
            rules={{
              required: editAddress || !account?.addresses?.length
            }}
            render={({ field: { ref, ...newField } }) =>
              <CustomTextfield className={styles.textfield}
                               size='small'
                               variant='outlined'
                               error={errors?.email}
                               helperText={errors?.email ? t('error_phone') : ''}
                               inputRef={ref}
                               label={t('Email')}
                               {...newField} />}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Choose payment Type</FormLabel>
            <Controller
              rules={{ required: editAddress || !account?.addresses?.length }}
              control={control}
              name='payment'
              defaultValue='stripe'
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value='stripe'
                    control={<CustomRadio />}
                    label={t('stripe')}
                  />
                  <FormControlLabel
                    value='paypal'
                    control={<CustomRadio />}
                    label={t('paypal')}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    )
  }
  return (
    <Dialog open={open} onClose={handleOpen}>
        <DialogTitle>{t('billing_details')}</DialogTitle>
        <DialogContent>
          {account?.addresses?.length ? renderCardDetail() : null}
          {editAddress || !account?.addresses?.length ? <form id="submit-form" onSubmit={handleSubmit(handleSubmitForm)}>
            {renderForm()}
          </form> : null}
        </DialogContent>
      <DialogActions>
          <Button onClick={handleOpen} className={styles.cancelButton}>
            {t('Cancel')}
          </Button>
        {editAddress || !account?.addresses?.length ? <Button type="submit"
                  form="submit-form"
                  variant='contained'>
            {t('Confirm')}
          </Button> :
        <Button onClick={handleSaveNoEdit}
          disabled={setDisabledButton()}
                variant='contained'>
          {t('Confirm')}
        </Button>}
        </DialogActions>
    </Dialog>
  )
}
