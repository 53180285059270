import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import { FileDropArea } from '../../../../../../shared-components/area/FileDropArea'
import styles from './UploaderContainer.module.scss'
interface IProps {
  title: string
  description: string
  handleAddedFile: (file: File | null) => void
  value?: File
}

export const UploaderContainerStep4 = (props: IProps) => {
  const { title, description, handleAddedFile, value } = props

  const [files, setFiles] = useState<File[]>([])
  const [errorFeedback, setErrorFeedback] = useState<string>('')
  const handleAddFiles = (newFiles: File[]) => {
    if (
      newFiles?.length === 1 &&
      ['image/jpeg'].includes(newFiles[0].type)
    ) {
      setErrorFeedback('')
      setFiles((prevFiles) => [...prevFiles, ...newFiles])
      handleAddedFile(newFiles[0])
    } else {
      setErrorFeedback(
        t(`You can only upload one file of type extensions`, { extensions: ['JPG'] })
      )
    }
  }

  const removeFile = () => {
    handleAddedFile(null)
    setFiles([])
  }
  const file = value ? value : files ? files[0] : null
  const objectURL = value
    ? URL.createObjectURL(value)
    : files.length
    ? URL.createObjectURL(files[0])
    : null
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <div className={styles.contentContainer}>
        {!file || !objectURL ? (
          <div>
            <FileDropArea
              handleAddFiles={handleAddFiles}
              dropFormatText={description}
            />
          </div>
        ) : (
          <div className={styles.filesContainer}>
            <Box position='relative' display='inline-flex'>
              {file.type !== 'application/pdf' ? (
                <div>
                  <img
                    src={objectURL}
                    alt={file.name}
                    className={styles.image}
                  />
                  <Typography fontSize={10}>{file.name}</Typography>
                </div>
              ) : (
                <Box display={'flex'} width='150px' flexDirection='column'>
                  <Typography paddingRight={4} paddingTop={2}>
                    {file.name}
                  </Typography>
                </Box>
              )}

              <IconButton
                className={styles.closeButton}
                onClick={() => removeFile()}
                size='small'
                sx={{
                  position: 'absolute',
                  right: 5,
                  top: 5,
                  backgroundColor: '#c11b1e',
                  opacity: 0.8,
                  color: 'white',
                }}
              >
                <CloseIcon sx={{ width: 15, height: 15 }} />
              </IconButton>
            </Box>
          </div>
        )}
        <Typography sx={{ margin: 'auto' }} color='red'>
          {errorFeedback}
        </Typography>
      </div>
    </div>
  )
}
