import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ImagesKey } from '../../utils/types'
interface IPropertyRelease {
  created_date?: string
  file?: Blob
  owner?: IOwner
  author?: IAuthor
  witness?: IWitness
}

interface IModelRelease {
  file?: Blob
  model?: IModel
  photographer?: IPhotographer
  witness?: IWitness
}

interface IMinorRelease {
  file: undefined
  minor?: IMinor
  parent?: IParent
  photographer?: IPhotographer
  witness?: IWitness
}
interface IMinor {
  minor_name?: string
  minor_dob?: string // date
  minor_path?: Blob
}
interface IOwner {
  owner_name?: string
  owner_address?: string
  owner_phone?: string
  owner_email?: string
  owner_date_signed?: string // Format: "YYYY-MM-DD"
  owner_signature?: Blob
}

interface IAuthor {
  author_name?: string
  author_address?: string
  author_phone?: string
  author_email?: string
  author_signature?: Blob
}

interface IModel {
  model_name?: string
  model_dob?: string // date
  model_address?: string
  model_phone?: string
  model_email?: string
  model_date_signed?: string // Format: "YYYY-MM-DD"
  model_signature?: Blob
}

interface IParent {
  parent_name?: string
  parent_address?: string
  parent_phone?: string
  parent_email?: string
  parent_date_signed?: string // Format: "YYYY-MM-DD"
  parent_signature?: Blob
}

interface IPhotographer {
  photographer_name?: string
  photographer_address?: string
  photographer_phone?: string
  photographer_email?: string
  photographer_date_signed?: string
  photographer_signature?: Blob
}

interface IWitness {
  witness_name?: string
  witness_address?: string
  witness_date_signed?: string // Format: "YYYY-MM-DD"
  witness_signature?: Blob
}

export interface IRelease {
  property?: IPropertyRelease[]
  model?: IModelRelease[]
  minor?: IMinorRelease[]
}

interface IUploadSession {
  id: number
  title: string
  step: number
  enabled: number
  savedImages: any,
  images: {
    defaultKeywords?: string[]
    originalFilename?: string
    imageId: number
    thumbnail: string
    medium: string
    isEditorial: number
    isAdult: number
    isAi: number
    networkAi: null | any // You can replace `any` with an appropriate type if you have specific data structure for `networkAi`
    keywords: string[]
    description: string
    title: string
    hasEPS: number
    hasJPG: number
    hasPNG: number

    property: {
      author: {
        author_name: string
        author_address: string
        author_phone: string
        author_email: string
      }
      owner: {
        owner_name: string
        owner_address: string
        owner_phone: string
        owner_email: string
        owner_date_signed: string // Format: "YYYY-MM-DD"
      }
    }[]
    model: {
      author: {
        author_name: string
        author_address: string
        author_phone: string
        author_email: string
      }
      owner: {
        owner_name: string
        owner_address: string
        owner_phone: string
        owner_email: string
        owner_date_signed: string // Format: "YYYY-MM-DD"
      }
    }[]
    minor: {
      author: {
        author_name: string
        author_address: string
        author_phone: string
        author_email: string
      }
      owner: {
        owner_name: string
        owner_address: string
        owner_phone: string
        owner_email: string
        owner_date_signed: string // Format: "YYYY-MM-DD"
      }
    }[]
  }[]
  createdAt: string // Format: "YYYY-MM-DD"
  updatedAt: string // Format: "YYYY-MM-DD"
}

const initialState: {
  uploadSession: IUploadSession | null,
  imagesFirstStep: {
    images: ImagesKey,
    unsavedImages: any[]
  }
  errorFeedback: any
} = {
  imagesFirstStep: {
    images: {jpg: [], eps: [], png: []},
    unsavedImages: []
  },
  uploadSession: null, // Initial uploadSession is set to null
  errorFeedback: null, // Initial errorFeedback is null, indicating no error initially
}
interface ISetImagePropertyPayload {
  imageId: number
  propertyName:
    | 'isAdult'
    | 'isEditorial'
    | 'isAi'
    | 'networkAi'
    | 'description'
    | 'keywords'
  | 'defaultKeywords'
  value: boolean | string | string[]
}
const uploadImagesSlice = createSlice({
  name: 'uploadImages',
  initialState,
  reducers: {
    setUploadSessionStep1: (state, action: PayloadAction<any | null>) => {
      state.imagesFirstStep = action.payload
    },
    setUploadSession: (state, action: PayloadAction<IUploadSession | null>) => {
      state.uploadSession = action.payload
    },
    setErrorFeedback: (state, action: PayloadAction<any>) => {
      state.errorFeedback = action.payload
    },
    setImageProperty: (
      state,
      action: PayloadAction<ISetImagePropertyPayload>
    ) => {
      const { imageId, propertyName, value } = action.payload
      const image = state.uploadSession?.images.find(
        (img) => img.imageId === imageId
      )
      if (image) {
        // Update the specified property of the image with the given value
        ;(image as any)[propertyName] = value // You can replace `any` with an appropriate type for the property value
      }
    },
  },
})

export const { setUploadSession, setErrorFeedback, setImageProperty, setUploadSessionStep1 } =
  uploadImagesSlice.actions

export default uploadImagesSlice.reducer
