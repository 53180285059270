import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import SpaIcon from '@mui/icons-material/Spa'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { useTranslation } from 'react-i18next'
import { AnimationContainer } from '../../../shared-components/AnimationContainer'
import { FeatureButton } from '../../../shared-components/button/FeatureButton'
import styles from './ServicesSection.module.scss'

export const ServicesSection = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <AnimationContainer animationStyle={styles.animate}>
        <h1 className={styles.title}>{t('Features_Benefits')}</h1>
      </AnimationContainer>
      <AnimationContainer animationStyle={styles.animate}>
        <div className={styles.description}>
          {t('Features_Benefits_Description')}
        </div>
      </AnimationContainer>

      <AnimationContainer animationStyle={styles.animate}>
        <div className={styles.featuresContainer}>
          <FeatureButton
            icon={<AccessTimeIcon />}
            title={t('Saves Time')}
            description={t('Saves Time Description')}
          />
          <FeatureButton
            icon={<LocalAtmIcon />}
            title={t('Saves Money')}
            description={t('Saves Money Description')}
          />
          <FeatureButton
            icon={<EditNoteRoundedIcon />}
            title={t('Easy Editing')}
            description={t('Easy Editing Description')}
          />
          <FeatureButton
            icon={<SpaIcon />}
            title={t('Multiple Uses')}
            description={t('Multiple Uses Description')}
          />
          <FeatureButton
            icon={<ViewInArIcon />}
            title={t('Added Value')}
            description={t('Added Value Description')}
          />
          <FeatureButton
            icon={<CheckBoxIcon />}
            title={t('Licensing')}
            description={t('Licensing Description')}
          />
        </div>
      </AnimationContainer>
    </div>
  )
}
