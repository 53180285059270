import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './UploadPhotoButton.module.scss'

interface IProps {
  selectedImage: any
  setSelectedImage: any
  id?: string
  onDeleteImage?:(key?: string) => void
  acceptFile?: string;
}
const acceptFileLocal = 'application/pdf';

export const UploadPhotoButton = (props: IProps) => {
  const { selectedImage, setSelectedImage, id, onDeleteImage, acceptFile } = props

  const { t } = useTranslation()

  const handleDeleteImage = () => {
    if(onDeleteImage && id) {
      onDeleteImage(id)
    }
  }

  return (
    <Box key={id}>
      {selectedImage && (
        <Box position='relative' display='inline-flex'>
          <img
            src={typeof selectedImage === 'string' ? selectedImage : URL?.createObjectURL(selectedImage)}
            alt={id}
            className={styles.image}
          />

          <IconButton
            onClick={() => handleDeleteImage()}
            size='small'
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
              backgroundColor: '#c11b1e',
              opacity: 0.8,
              color: 'white',
            }}
          >
            <CloseIcon sx={{ width: 15, height: 15 }} />
          </IconButton>
        </Box>
      )}

      {!selectedImage && (
        <>
          <input
            id={`contained-button-file-${id}`}
            type='file'
            accept={acceptFile || acceptFileLocal}
            style={{ display: 'none' }}
            onChange={(event: any) => {
              setSelectedImage(event.target.files[0])
            }}
          />
          <label htmlFor={`contained-button-file-${id}`}>
            <Button
              variant='outlined'
              className={styles.button}
              component='span'
            >
              {t('Upload')}
            </Button>
          </label>
        </>
      )}
    </Box>
  )
}
