import { Box } from '@mui/material'

export function CustomTabPanel(props: any) {
  const { children, value, indexData, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== indexData}
      id={`simple-tabpanel-${indexData}`}
      aria-labelledby={`simple-tab-${indexData}`}
      {...other}
    >
      {value === indexData && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}