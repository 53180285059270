import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { constants, textAndImage } from '../../../../utils/constants'

import { ChangeEvent, useState } from 'react'
import { SearchPlaceholder } from '../../../shared-components/search/SearchPlaceholder'
import styles from './HeroSection.module.scss'

export const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value)
  }

  const handleSlideChange = (newSlide: number) => {
    setCurrentSlide(newSlide)
  }

  return (
    <div className={styles.imageContainer}>
      <Splide
        onMove={(splide) => handleSlideChange(splide.index)}
        options={{
          rewind: true,
          width: '100%',
          maxHeight: '730px',
          autoplay: true,
          interval: 5000,
        }}
        className={styles.carousel}
      >
        <SplideSlide>
          <img
            src={constants.img_background1}
            alt=''
            className={styles.image}
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src={constants.img_background2}
            alt=''
            className={styles.image}
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src={constants.img_background3}
            alt=''
            className={styles.image}
          />
        </SplideSlide>
      </Splide>

      <SearchPlaceholder
        searchValue={searchValue}
        handleSearchChange={handleSearchChange}
        title={textAndImage[currentSlide].title}
        description={textAndImage[currentSlide].description}
      />
    </div>
  )
}
