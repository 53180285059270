import styles from './Step4.module.scss'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import { Step4ImageElement } from './Step4ImageElement'
import { useFieldArray } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'


export const ImageFieldArray = (props: any) => {
const {imageField, control, index, getValues, addedToSelectionElements, releases, setReleases, errors, setValue, watch} = props;
  const {
    fields: propertyFields,
    append: propertyAppend,
    remove: propertyRemove
  } = useFieldArray({ control, name: `images[${index}].property` });
  const {
    fields: modelFields,
    append: modelAppend,
    remove: modelRemove
  } = useFieldArray({ control, name: `images[${index}].model` });
  const {
    fields: minorFields,
    append: minorAppend,
    remove: minorRemove
  } = useFieldArray({ control, name: `images[${index}].minor` });
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const imageData = uploadSession?.images.find((item) => item?.imageId === imageField?.imageId)
  return(
    <div>
      <div className={styles.imageAndUpload}>
        <PublicationImageCard
          image={imageData?.medium || ''}
          imageId={imageData?.imageId || ''}
          name={imageData?.originalFilename || ''}
          canBeDeleted={false}
        />
      </div>
      <div className={styles.releasesContainer}>

        <Step4ImageElement
          index={index}
          watch={watch}
          setValue={setValue}
          errors={errors.images?.[index]}
          fieldArrayName={`images[${index}]`}
          valuesForm={getValues()}
          tabs={[...propertyFields, ...modelFields, ...minorFields]}
          propertyAppend={propertyAppend}
          modelRemove={modelRemove}
          control={control}
          minorFields={minorFields}
          modelAppend={modelAppend}
          propertyFields={propertyFields}
          modelFields={modelFields}
          setReleases={setReleases}
          minorAppend={minorAppend}
          releases={releases}
          minorRemove={minorRemove}
          addedToSelectionElements={addedToSelectionElements.map(
            (item: any) => item.toString()
          )}
          image={imageData?.thumbnail || ''}
          name={imageData?.originalFilename || ''}
          id={imageData?.imageId || 0}
          title={imageData?.thumbnail?.split('/').at(-1)}
          description={'this is a description'}
        />
      </div>
    </div>
  )
}

export default ImageFieldArray;
