import PersonIcon from '@mui/icons-material/Person'
import PhotoIcon from '@mui/icons-material/Photo'
import { useTranslation } from 'react-i18next'
import { AnimationContainer } from '../../shared-components/AnimationContainer'
import styles from './ContactFooter.module.scss'

export const ContactFooter = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.infoContainer}>
      <AnimationContainer animationStyle={styles.animate}>
        <div className={`${styles.textSection}`}>
          <h2 className={styles.title}>{t('Media_Stock')}</h2>
          <div>{t('Professional Software')}</div>
          <div>
            <span>{t('Phone')}:</span>
            <span className={styles.textBold}> {t('Phone_Number')}</span>
          </div>
        </div>
      </AnimationContainer>

      <AnimationContainer animationStyle={styles.animate}>
        <div className={`${styles.textSection}`}>
          <h2 className={styles.title}>{t('Customers')}</h2>
          <div>{t('Users')}</div>
          <div>{t('Buy Subscription')}</div>
        </div>
      </AnimationContainer>

      <AnimationContainer animationStyle={styles.animate}>
        <div className={`${styles.textSection}`}>
          <h2 className={styles.title}>{t('Site info')}</h2>
          <div>{t('Support')}</div>
          <div>{t('Privacy Policy')}</div>
          <div>{t('FAQ')}</div>
          <div>{t('News')}</div>
        </div>
      </AnimationContainer>
    </div>
  )
}
