import styles from './Payment.module.scss'
import { TabValue } from '../../../AccountDetailsSeller'
import React, { useState } from 'react'
import {
  Paper,
  Typography,
  Link,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Button,
  CircularProgress
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CustomRadio } from '../../../../../../shared-components/checkbox/CustomRadio'
import { Title } from '../../Title'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { useCustomNavigation } from '../../../../../../../hooks/useCustomNavigate'
import { ROUTES, WAITING } from '../../../../../../../utils/constants'
import i18next from 'i18next'
import { daysBetweenDates } from '../../../../../../../utils/helpers'
import moment from 'moment'
import { typePayment } from '../../../AccountSettings'
interface IProps {
  filterValue?: string,
  onChangeFilterValue?: (key: any) => void;
  onChangeSections?: (key: TabValue) => void
}
const getTitleAndDescription = (filterValue: string | null, t:any) => {
  switch (filterValue) {
    case '0':
      return {
        title: t('payment_history'),
        description: '',
      }
    case null:
      return {
        title: t('payment_method'),
        description: '',
      }
    default: {
      return { title: t('payment_method'), description: '' }
    }

  }
}

const messagePayment = (lastSellerPayout: any, lastSellerTaxForm: any) => {

  if(lastSellerTaxForm?.status === WAITING) {
    return  i18next.t('tax_form_waiting_approve');
  }
  if(lastSellerPayout?.status === WAITING) {
    return  i18next.t('payment_waiting', {
      days: 15 - daysBetweenDates(moment(), moment(lastSellerPayout?.created_at)),
    });
  }
  if(!lastSellerPayout && !lastSellerTaxForm) {
    return  i18next.t('add_tax_form_message');
  }
}

export const Payment = (props: IProps) => {
  const { t } = useTranslation()
  const { filterValue, onChangeSections,  } = props;
  const [payment, setPayment] = useState('bankAccount')
  const [errorPayment, setErrorPayment] = useState('')
  const [requestPayment, setRequestPayment] = useState('')
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [paypalEmail, setPaypalEmail] = useState('')
  const [bankAccount, setBankAccount] = useState('')
  const customNavigate = useCustomNavigation()
  const userData = useSelector((state: RootState) => state.user.usersData)

  const canNotRequestPayment = () => {
    if (!userData?.lastSellerTaxForm) {
      return true;
    }
    if (userData.lastSellerTaxForm.status === WAITING) {
      return true;
    }
    if (userData?.minPayout > Number(userData?.sellerEarningsUnpaid)) {
      return true;
    }
    if (userData.lastSellerPayout && daysBetweenDates(moment(), moment(userData.lastSellerPayout.created_at)) < 15) {
      return true;
    }
    return false;
  };

  const handleChangePayment = (ev: any) => {
    setPayment(ev.target.value)
    if(ev.target.value === 'Revolut') {
      setEmail(userData?.payoutRevolut || '')
    } else if(ev.target.value === 'Skrill') {
      setEmail(userData?.payoutSkrill || '')
    } else if(ev.target.value === 'Paypal') {
      setEmail(userData?.payoutPaypal || '')
    } else if(ev.target.value === 'Payoneer') {
      setEmail(userData?.payoutPaypal || '')
    }
  }
  const handleChangeRequestPayment = (event: any) => {
    setRequestPayment(event.target.value)
    if(Number(event.target.value) > Number(userData?.sellerEarningsUnpaid)) {
      setErrorPayment(`Payment must be less than ${userData?.sellerEarningsUnpaid}`)
    } else if(Number(event.target.value) < Number(userData?.minPayout)) {
      setErrorPayment(`Payment must be more than ${userData?.minPayout}`)
    } else {
      setErrorPayment('')
    }
  }
  const handleSaveRequestPayment= async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    if(!email || !/\S+@\S+\.\S+/.test(email) || (!confirmEmail || !/\S+@\S+\.\S+/.test(confirmEmail))) {
      setErrorEmail(t('Invalid email address'))
    }
    if(email !== confirmEmail) {
      setErrorEmail("Email doesn't match")
    }
    const formData = new FormData()
    const dataPayload = {
      email: email,
      amount: Number(requestPayment),
      method_info: payment === 'bankAccount' ? bankAccount : paypalEmail,
      method: payment === 'bankAccount' ? 'bank' : 'paypal',
    }
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>', // Set the Content-Type header for FormData
      },
    }
    formData.append(`email`,email)
    formData.append(`amount`,requestPayment)
    formData.append(`method_info`, 'email')
    formData.append(`method`,payment?.toLowerCase())
    try {
      const res = await instance.post(`seller/tax-center/request-payment`,formData, config)
      Notiflix.Notify.success(t('Success'))
      Notiflix.Loading.remove()
      setPaypalEmail('')
      setBankAccount('')
      setConfirmEmail('')
      setEmail('')
      setRequestPayment('')
    } catch (error) {

      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }

  const handleRedirectAccountPage= () => {
    customNavigate(`/${ROUTES.ACCOUNT}/${ROUTES.SETTINGS}`)
  }

  return (
    <div>
      <Title
        title={filterValue ? getTitleAndDescription(filterValue, t).title : ''}
        description={filterValue ? getTitleAndDescription(filterValue, t).description : ''}
      />
    <div className={styles.content}>
      {messagePayment(userData?.lastSellerPayout, userData?.lastSellerTaxForm) && <Paper className={styles.paperPayment}>
          <Typography fontSize={20} fontWeight="bold">{messagePayment(userData?.lastSellerPayout, userData?.lastSellerTaxForm)}</Typography>
        </Paper>}
        <div className={styles.containerPayment}>
          <Typography>{t('minimum_payout_balance')}: ${userData?.minPayout} </Typography>
          <Typography>{t('current_earning_balance')}: ${userData?.sellerEarningsUnpaid} </Typography>
          <br/>
          <Typography sx={{display: 'flex'}}><Link component="button" onClick={handleRedirectAccountPage}>{t('review_and_confirm')} </Link>&nbsp;{t('all_account_info')}</Typography>
          <div className={styles.requestAmount}>
            <Typography>{t('request_payment')}</Typography>
            <CustomTextfield
                                                                   size='small'
                                                                   type="number"
                                                                   disabled={canNotRequestPayment()}
                                                                   variant='outlined'
                                                                   className={styles.textAmount}
                                                                   error={!!errorPayment}
                                                                   value={requestPayment}
                                                                   onChange={handleChangeRequestPayment}
                                                                   helperText={errorPayment ? errorPayment : ''}
                                                                   label={'Amount'} />
          </div>

          <FormControl className={styles.labelStyleTax}>
            <Typography className={styles.labelStyleTax}>Set Type payment</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="stripe"
              name="radio-buttons-group"
              onChange={handleChangePayment}
              value={payment}
            >
              {typePayment.map((type: any) => (
                <FormControlLabel  disabled={canNotRequestPayment()} value={type.id} control={<CustomRadio />} label={type.name} />
              ))}
            </RadioGroup>
          </FormControl>
          <Typography marginTop={4}>Payout email</Typography>
          <div className={styles.contentEmails}>
            <CustomTextfield     className={styles.textfield}
                                 size='small'
                                 disabled={canNotRequestPayment()}
                                 variant='outlined'
                                 value={email}
                                 onChange={(e: any) => setEmail(e.target.value)}
                                 label={t('Email')} />
            <CustomTextfield     className={styles.textfield}
                                 size='small'
                                 disabled={canNotRequestPayment()}
                                 variant='outlined'
                                 value={confirmEmail}
                                 onChange={(e: any) => setConfirmEmail(e.target.value)}
                                 label={t('confirm_email_address')} />
          </div>
          <Typography>{errorEmail}</Typography>
          <br/>
          <Button
            variant="contained"
            onClick={!isLoading ? handleSaveRequestPayment : undefined}
            className={styles.button}
            disabled={isLoading || !!errorPayment || canNotRequestPayment()}
          >
            {isLoading ? <CircularProgress /> : t('request_payment')}
          </Button>
        </div>
    </div>
    </div>
  )
}

export default Payment;