import SendIcon from '@mui/icons-material/Send'
import { Button, Divider, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import styles from './LightboxImage.module.scss'
import Notiflix from 'notiflix'
import instance from '../../../../../../../providers/axios.config'
import { typeImageForDownload } from '../../../../asset-details/AssetDetails'
import { IImageLightbox } from '../../../../../../../utils/types'

interface IProps {

  onDeleteFavoriteImage: (key: string, key1: string, key2?: any, key3?: string) => void
  lightboxId: string
  item?: IImageLightbox;
}
const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
}
export const LightboxImage = (props: IProps) => {
  const { item, onDeleteFavoriteImage, lightboxId } = props
  const { t } = useTranslation()

  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [openModalShare, setOpenModalShare] = useState(false)
  const [errorEmail, setErrorEmail] = useState(false);
  const [shareEmail, setShareEmail] = useState('')

  const handleConfirm = () => {
    if(item?.image_id) {
      onDeleteFavoriteImage(item.image_id.toString(), lightboxId, item?.size?.id, item?.category?.name)
      setOpenDeletePopup(false)
    }
  }

  const handleOpen = () => {
    setOpenDeletePopup(!openDeletePopup)
  }
  const handleOpenShare = () => {
    setOpenModalShare(!openModalShare)
  }
  const handleModalShare = () => {
   if(isValidEmail(shareEmail)) {
     setErrorEmail(!isValidEmail(shareEmail))
     setOpenModalShare(!openModalShare);
   } else {
     setErrorEmail(!isValidEmail(shareEmail))
   }
  }
  const handleConfirmShare= async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post(`share-image/${item?.image_id}`, {email: shareEmail})
      if ((resp as any).status === 'success') {
        setOpenModalShare(false)
        Notiflix.Notify.success(t('Success'))
      }
    } catch (error) {
      console.error('Error share image', error)
      setOpenModalShare(false)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  const downloadImageFree = async (typeDownload: string) => {
    const response = await instance.get(`images/${item?.image_id}/download?image_size_id=${item?.size?.id?.toString()}&category=${item?.category.name}&method=${typeDownload}`, { responseType: 'arraybuffer' }) as any
    let blob = new Blob([response], { type: `image/${typeImageForDownload(item?.category.name)}` }),
      url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${item?.title}.${item?.category.name}`)
    document.body.appendChild(link)
    link.click()
  }
  const downloadByCredits = async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    if (item?.size?.price === 0) {
      await downloadImageFree('credits')
      Notiflix.Loading.remove()
    } else {
      try {
        const dataPayload = {
          image_id: Number(item?.image_id),
          image_size_id: Number(item?.size?.id),
          method: 'credits',
          category: item?.category?.name
        }
        const response = await instance.post(`orders`, { ...dataPayload }) as any
        if (response?.data?.link) {
          const res = await instance.get(`${response?.data?.link}`, { responseType: 'arraybuffer' }) as any
          let blob = new Blob([res], { type: `image/${typeImageForDownload(item?.category?.name)}` }),
            url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${item?.title}.${item?.category.name}`)
          document.body.appendChild(link)
          link.click()
          Notiflix.Loading.remove()
        } else {
          Notiflix.Loading.remove()
          Notiflix.Report.failure(
            'Error',
            `${response?.message}`,
            'OK'
          )
        }
      } catch (e) {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          'Failed to fetch data. Please try again later.',
          'OK'
        )
      }
    }
  }
  const downloadBySubscription = async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const dataPayload = {
        image_id: Number(item?.image_id),
        image_size_id: Number(item?.size.id),
        method: 'subscription',
        category: item?.category.name
      }
      if (item?.size.price === 0) {
        await downloadImageFree('subscription')
        Notiflix.Loading.remove()
      } else {
        const response = await instance.post(`orders`, { ...dataPayload }) as any
        if (response?.data?.link) {
          Notiflix.Loading.remove()
          const res = await instance.get(`${response?.data?.link}`, { responseType: 'arraybuffer' }) as any
          let blob = new Blob([res], { type: `image/${typeImageForDownload(item?.category.name)}` }),
            url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${item?.title}.${item?.category.name}`)
          document.body.appendChild(link)
          link.click()
          Notiflix.Loading.remove()
        } else {
          Notiflix.Loading.remove()
          Notiflix.Report.failure(
            'Error',
            `${response?.message}`,
            'OK'
          )
        }
      }
    } catch (e) {
      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  return (
    <>
      <ConfirmDialog
        open={openDeletePopup}
        handleOpen={handleOpen}
        handleConfirm={handleConfirm}
        dialogTitle={t('Delete Image')}
        dialogMessage={t('Are you sure to delete imageId', {
          imageId:item?.image_id,
        })}
      />
      <ConfirmDialog
        open={openModalShare}
        handleOpen={handleOpenShare}
        handleConfirm={handleConfirmShare}
        dialogTitle={t('share_image_title')}
        dialogMessage={t('confirm_email_share', {
          imageId: item?.image_id,
          email: shareEmail
        })}
      />
      <Grid container spacing={2} sx={{paddingLeft: 2, paddingBottom: 2, paddingTop: 2}}>
        <Grid  item md={1.5}></Grid>
        <Grid item md={1}>
          <Typography fontSize={12} fontWeight="bold">{t('Image ID')}</Typography></Grid>
        <Grid item md={1}><Typography fontSize={12} fontWeight="bold">{t('Category')}</Typography> </Grid>
        <Grid item md={1}> <Typography fontSize={12} fontWeight="bold">{t('Image Size')}</Typography> </Grid>
        <Grid item md={2.5}><Typography fontSize={12} fontWeight="bold">{t('Share')}</Typography></Grid>
        <Grid item md={2}><Typography fontSize={12} fontWeight="bold">{t('Download by Credits')}</Typography> </Grid>
        <Grid item md={2}>
          <Typography fontSize={12} fontWeight="bold">{t('Download by Subscription')}</Typography> </Grid>
        <Grid item md={1}> </Grid>
        <Grid item md={1.5}>
          <img src={item?.thumbURL} className={styles.image} alt={item?.title} />
        </Grid>
        <Grid item md={1} sx={{alignItems: 'center', margin: 'auto'}}>
          <Typography>{item?.image_id}</Typography>
        </Grid>
        <Grid item md={1} sx={{alignItems: 'center', margin: 'auto'}}>
          <Typography>{item?.category?.name}</Typography>
        </Grid>

        <Grid item md={1} sx={{alignItems: 'center', margin: 'auto'}}>
          <Typography>{item?.size.name}</Typography>
        </Grid>
        <Grid item md={2.5} sx={{alignItems: 'center', margin: 'auto'}}>
          <CustomTextfield
            className={styles.textfield}
            size='small'
            error={errorEmail}
            name='description'
            helperText={errorEmail ?  t('error_email_valid') : ''}
            variant='outlined'
            type="email"
            label={t('Email Address')}
            value={shareEmail}
            onChange={(e: any) => setShareEmail(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleModalShare}>
                    <SendIcon sx={{ color: '#c11b1e' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={2} sx={{alignItems: 'center', margin: 'auto'}}>
          <Button
            variant='contained'
            className={styles.textfieldButton}
            onClick={downloadByCredits}
          >
            {t('Download')}
          </Button>
        </Grid>
        <Grid item md={2} sx={{alignItems: 'center', margin: 'auto'}}>
          <Button
            variant='contained'
            className={styles.textfieldButton}
            onClick={downloadBySubscription}
          >
            {t('Download')}
          </Button>
        </Grid>
        <Grid item md={1} sx={{alignItems: 'center', margin: 'auto'}}>
          <Button
            variant='contained'
            className={styles.deleteButton}
            onClick={handleOpen}
          >
            {t('Delete')}
          </Button>
        </Grid>
      </Grid>
      <Divider variant="inset" />
    </>
  )
}
