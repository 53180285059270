import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { useState } from 'react'
import { CustomTextfield } from './CustomTextField'

interface IProps {
  label: string
  value: string
  changePassword: any
  error?: boolean
  disabled?: boolean;
}

export const CustomPasswordField = (props: IProps) => {
  const { label, value, changePassword, error, disabled } = props

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  return (
    <CustomTextfield
      variant='outlined'
      value={value}
      disabled={disabled}
      type={showPassword ? 'text' : 'password'}
      label={label}
      error={error}
      onChange={(event: any) => {
        changePassword(event.target.value)
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? (
                <Visibility style={{ color: '#c11b1e' }} />
              ) : (
                <VisibilityOff style={{ color: '#c11b1e' }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
