import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Popper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { getLanguage } from '../../../providers/axios.config'

const languages = [
  {
    id: 1,
    code: 'en',
    name: 'English',
    image: '/languages/english.gif'
  },
  // {
  //   id: 2,
  //   code: 'ro',
  //   name: 'Romanian',
  //   image: '/languages/romanian.jpeg'
  // },
  // {
  //   id: 3,
  //   code: 'fr',
  //   name: 'French',
  //   image: '/languages/french.gif'
  // },
]
const getCurrentLanguage = (code: string) => {
  const findLanguage = languages.find((lang: any) => lang.code === code);
  return {
    image: findLanguage?.image,
    name: findLanguage?.name
  }
}
export const Language = () => {
  const {  i18n } = useTranslation()
  const initialLanguage = getLanguage() || i18n.language;

  const changeLanguageHandler = (code: string) =>
  {
    localStorage.setItem('language', code)
    i18n.changeLanguage(code).then((t) => {
      t('key'); // -> same as i18next.t
    });
    setAnchorEl(null)
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    if (languages.length > 1) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <>
      <Button aria-describedby={id} type='button' onClick={handleClick} sx={{color: '#fff', fontSize: 12}}>
        <img style={{marginRight: 2}} width={20} height={15} src={getCurrentLanguage(initialLanguage).image} alt={getCurrentLanguage(initialLanguage).name} />
        {getCurrentLanguage(initialLanguage).name}
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 9999}}>
      <Box sx={{backgroundColor: 'rgba(26, 26, 26, .9)', padding:1}}>
          <Grid container spacing={2}>
            {languages.map((lang: any) => (
              <Grid item xs={6} md={4} key={lang.code} sx={{display: 'flex', alignItems: 'center', padding: 1, cursor: 'pointer'}} onClick={() => changeLanguageHandler(lang.code)}>
                <img width={25} height={15} src={lang.image} alt={lang.name}/>
                <Typography sx={{color: '#fff', marginLeft: 1}}>{lang.name}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popper>
    </>
  )
}


export default Language;