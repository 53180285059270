import Checkbox from '@mui/material/Checkbox'

export const CustomCheckbox = (props: any) => {
  return (
    <Checkbox
      {...props}
      size='small'
      sx={{
        '&.Mui-checked': {
          color: '#c11b1e',
        },
      }}
    />
  )
}
