import i18n from '../i18n'

export const MAXIMUM_CHARACTERS_DESCRIPTION = 250;
export const MINIMUM_CHARACTERS_DESCRIPTION = 30;
export const MAXIMUM_CHARACTERS_KEYWORDS = 50;
export const MINIMUM_CHARACTERS_KEYWORDS = 7;

export const SUBSCRIPTIONS = 'subscriptions';
export const CREDITS = 'credits';
export const FAST_DOWNLOAD = 'fast-download';

export const BASE_URL = 'https://layerstock.com/';
export const SELLER = 'seller';
export const BUYER = 'buyer';
export const VALID = 'valid';
export const NOT_CHECKED = 'not_checked';
export const FILESIZEPNG = 0;
export const FILESIZEEPS = 0;
export const FILESIZEJPG = 0;

export const DECLINED = 'declined'

export const WAITING = 'waiting';
export const CONFIRMED = 'confirmed';
export const APPROVED = 'approved';
export const PAID = 'paid';
export const DENIED = 'denied';

export const KEYWORDS_PNG = ['transparent', 'transparent background', 'png', 'transparent png'];
export const KEYWORDS_EPS = ['transparent', 'transparent background', 'eps', 'transparent eps'];
export const KEYWORDS_EPS_PNG = ['transparent', 'transparent background', 'eps', 'transparent eps', 'png', 'transparent png'];

export const KEYWORDS_IS_ADULT = ['adult'];
export const KEYWORDS_IS_AI = ['ai'];
export const KEYWORDS_IS_EDITORIAL = ['editorial'];

export const ROUTES = {
  UNFINISHED: 'unfinished',
  UPLOAD: 'upload',
  PUBLICATIONS: 'publications',
  EXAMINATION: 'examination',
  ACCOUNT: 'account',
  SETTINGS: 'settings',
  LICENSING: 'licensing',
  DECLINED: 'declined',
  RELEASES: 'releases',
  EARNINGS:'earnings',
  STATISTICS: 'statistics',
  MYPAYMENT:'myPayment',
  HISTORY: 'history',
  TAXCENTER:'tax-center',
  LIGHTBOX: 'lightBox',
  DOWNLOADS: 'downloads',
  PAYMENT: 'payment',
  SUBSCRIPTIONS: 'subscriptions',
  CREDITS: 'credits',
  INVOICES: 'invoices',
  NOTIFICATIONS: 'notifications',
  CONFIRM_PAYOUT: 'confirm-payment-payout/:token',
  PLAN_PRICES: 'plans-prices',
  ASSETS: 'assets',
  LOGIN: 'login',
  REGISTER: 'register',
  SUPPORT: 'support'
}

export interface IFilters {
  category?: 'JPG' | 'PNG' | 'EPS'
  searchFilter?: string
  sortByFilter?: string
  typeFilter?: string[]
  advancedSearch?: {
    itemId?: string
    author?: string
    date?: Date
  }
  orientation?: string
  items?: number
  ai_network?: string
  model?: string
  minor?: string
}
export const initialFiltersConst = {
  orientation: 'all',
  items: 100,
}
interface TextAndImageMap {
  [key: number]: {
    title: string
    description: string
    image: NodeRequire
  }
}

interface IPlanAndPricesOption {
  description: number
  price: number
}

export interface ISubscriptionsCreditsProps {
  name?: string
  id?: string
  recommendedText: string
  covers: string[]
  options?: IPlanAndPricesOption[]
  category: 'Credits' | 'Subscriptions'
  isCenter?: boolean
  plans?: any
  price?: number,
  qty?: number,
  description?: string,
  type?: string,
}

interface IPlansAndPricesProps {
  Subscriptions:  ISubscriptionsCreditsProps[],
  Credits: ISubscriptionsCreditsProps[],
  others: ISubscriptionsCreditsProps[]
}

export const constants = {
  logo_layerstock: require('../images/logo-layerstock.png'),
  crop_png: require('../images/png-crop.jpg'),
  crop_jpg: require('../images/jpg-crop.jpg'),
  crop_eps: require('../images/eps-crop.jpg'),
  img_background1: require('../images/bg1.jpg'),
  img_background2: require('../images/bg2.jpg'),
  img_background3: require('../images/bg3.jpg'),
  img_jpg: require('../images/jpg.png'),
  img_png: require('../images/png.png'),
  img_eps: require('../images/eps.png'),
  img_wide: require('../images/default_banner.jpg'),
  img_avatar: require('../images/default_avatar.jpg'),
  img_new: require('../images/cat.jpg')
}

export const textAndImage: TextAndImageMap = {
  0: {
    title: i18n.t('Title_PNG'),
    description: i18n.t('Description_PNG'),
    image: constants.img_png,
  },
  1: {
    title: i18n.t('Title_EPS'),
    description: i18n.t('Description_EPS'),
    image: constants.img_eps,
  },
  2: {
    title: i18n.t('Title_JPG'),
    description: i18n.t('Description_JPG'),
    image: constants.img_jpg,
  },
}

export const plansAndPrices:  IPlansAndPricesProps  = {
  Subscriptions: [
  {
    name: i18n.t('One year Subscriptions'),
    recommendedText: i18n.t('Year_Subscriptions_description'),
    covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free')],
    options: [
      {
        description: 5,
        price: 40,
      },
      {
        description: 10,
        price: 70,
      },
      {
        description: 25,
        price: 150,
      },
    ],
    category: 'Subscriptions'
  },
  {
    name: i18n.t('One month Subscriptions'),
    recommendedText: i18n.t('Month_Subscriptions_description'),
    covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free')],
    options: [
      {
        description: 75,
        price: 75,
      },
      {
        description: 150,
        price: 90,
      },
      {
        description: 300,
        price: 120,
      },
    ],
    isCenter: true,
    category: 'Subscriptions'
  }, 
  {
    name: i18n.t('One day Subscriptionss'),
    recommendedText: i18n.t('Day_Subscriptions_description'),
    covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free')],
    options: [
      {
        description: 10,
        price: 50,
      },
    ],
    category: 'Subscriptions'
  }],
  Credits: [
    {
      name: '',
      recommendedText: i18n.t('Credits_description'),
      covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free'), i18n.t('Extended License')],
      options: [
        {
          description: 11,
          price: 15,
        },
        {
          description: 15,
          price: 18,
        },
        {
          description: 30,
          price: 33,
        },
                {
          description: 60,
          price: 60,
        },
      ],
      category: 'Credits'
    },
    {
      name: '',
      recommendedText: i18n.t('Credits_description'),
      covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free'), i18n.t('Extended License')],
      options: [
        {
          description: 120,
          price: 108,
        },
        {
          description: 250,
          price: 200,
        },
        {
          description: 500,
          price: 350,
        },
                {
          description: 1000,
          price: 600,
        },
      ],
      category: 'Credits'
    },
  ],
  others: [
    {
      name: i18n.t('Fast download'),
      recommendedText: i18n.t('Fast_download_description'),
      covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free')],
      options: [
        {
          description: 1,
          price: 10,
        },
      ],
      category: 'Subscriptions'
    },
    {
      name: i18n.t('Extended license Subscriptionss'),
      recommendedText: i18n.t('Extended_Subscriptions_description'),
      covers: [i18n.t('PNG'), i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free'), i18n.t('Extended License')],
      options: [
        {
          description: 2,
          price: 150,
        },
        {
          description: 5,
          price: 350,
        },
        {
          description: 25,
          price: 1250,
        },
      ],
      category: 'Subscriptions'
    },
  ]
}

export enum NavigationEnum {
  HOME = '/',
  ASSETS = '/assets',
  ASSET_DETAILS = '/assets/:assetName',
  ASSET_DETAILS_BUY = '/assets/:assetName/buy',
  ABOUT = '/about',
  PLANS_PRICES = '/plans-prices',
  LOGIN = '/login',
  REGISTER = '/register',
  FORGOT_PASSWORD = '/forgot-password',
  ACCOUNT = '/account',
  USERS = '/users',
  NOWHERE = '',
  RESETPASSWORD = '/reset-password/:token'
}

export const navItems = [
  { title: i18n.t('Home'), value: 1, to: NavigationEnum.HOME },
  {
    title: i18n.t('Stock'),
    // listOfItems: [
    //   { name: i18n.t('Images'), value: 21, to: NavigationEnum.ASSETS },
    //   { name: i18n.t('Lightboxes'), value: 22, to: `${ROUTES.ACCOUNT}/${ROUTES.LIGHTBOX}` },
    // ],
    value: 2,
    to: NavigationEnum.ASSETS,
  },
  {
    title: i18n.t('Categories'),
    listOfItems: [
      { name: i18n.t('EPS'), value: 31, to: NavigationEnum.ASSETS, params: 'eps' },
      { name: i18n.t('JPG'), value: 32, to: NavigationEnum.ASSETS, params: 'jpg' },
      { name: i18n.t('PNG'), value: 33, to: NavigationEnum.ASSETS, params: 'png' },
    ],
    value: 3,
    to: NavigationEnum.NOWHERE,
  },
  {
    title: i18n.t('Site info'),
    listOfItems: [
      { name: i18n.t('About'), value: 41, to: NavigationEnum.ABOUT },
    ],
    value: 4,
    to: NavigationEnum.NOWHERE,
  },
]
