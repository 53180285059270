import { createSlice } from '@reduxjs/toolkit'
type country = {
  label: string,
  value: number
}

const initialState: { countries: country[]; errorFeedback: string } = {
  countries: [],
  errorFeedback: '',
}

const countriesSlice = createSlice({
  name: 'countriesSlice',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload
    },
    setErrorFeedback: (state, action) => {
      state.errorFeedback = action.payload
    },
  },
})

export const { setCountries, setErrorFeedback } = countriesSlice.actions

export default countriesSlice.reducer
