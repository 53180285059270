import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { FormTab, IFormInput } from './FormTab'
import { useTranslation } from 'react-i18next'
import Notiflix from 'notiflix'
import instance from '../../../providers/axios.config'
import { typeResponse } from '../../../utils/types'

function CustomTabPanel(props: any) {
  const { children, value, indexData, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== indexData}
      id={`simple-tabpanel-${indexData}`}
      aria-labelledby={`simple-tab-${indexData}`}
      {...other}
    >
      {value === indexData && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
type IAssetsFormTabProps = {
  imageId: string | number | null | undefined

}
export const AssetsFormTab = (props: IAssetsFormTabProps) => {
  const { t } = useTranslation()
  const [valueTab, setValueTab] = useState(0);

  const handleSubmitForm = async (data: IFormInput) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator
    try {
      const setUrl = valueTab === 0 ? `images/share-friend` : `images/report`;
      const resp = await instance.post(
        setUrl,
        {
          ...data,
          image_id: props.imageId,
        }
      ) as typeResponse;
      if ((resp as any).status === 'success') {
        Notiflix.Loading.remove() // Remove loading indicator
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
      return resp;
    } catch (e) {
      Notiflix.Loading.remove() // Remove loading indicator
      Notiflix.Notify.failure(t('error'))
    }
  }

  return(
    <Box>
      <Tabs sx={{paddingTop: 2}} value={valueTab} onChange={(event: React.SyntheticEvent, newValue: number) => setValueTab(newValue)} aria-label="basic tabs example">
        <Tab label={t('tell_friend')} value={0} />
        <Tab  label={t('report_violation')}  value={1} />
      </Tabs>
      <CustomTabPanel value={Number(valueTab)} indexData={0}>
       <FormTab key="tell_friend" tab={Number(valueTab)} onSubmitForm={handleSubmitForm}/>
      </CustomTabPanel>
      <CustomTabPanel value={Number(valueTab)} indexData={1}>
        <FormTab key="report_violation" tab={Number(valueTab)}  onSubmitForm={handleSubmitForm}/>
      </CustomTabPanel>
    </Box>
  )
}