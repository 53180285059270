import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import styles from '../../main-components/content/account-details/sections/seller/payment/Payment.module.scss'
import Skeleton from '@mui/material/Skeleton'
import { CustomPagination } from '../pagination/CustomPagination'
import { useTranslation } from 'react-i18next'
import { setTypeStatus } from '../../main-components/content/account-details/sections/seller/payment/PaymentHistory'

type Props = {
  isLoading?: boolean;
  history?: any;
  pagination: {
    currentPage: number,
    perPage: number,
    totalPages: number
  },
  onChange?: (ev: any, key: number) => void
}

export const PaymentHistoryTable = (props: Props) => {
  const { t } = useTranslation()
  const {isLoading, history, pagination, onChange} = props;

  if(!isLoading && !history.length) {
    return <>
      <Typography>{t('no_history_payments')}</Typography>
    </>
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.titleCell}>
                {t('Date')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                {t('Payment')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                {t('status')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                {t('paid_at')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                {t('payment_method')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                <TableRow key={`${number}-skeleton-history`} className={styles.tableRow}>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                </TableRow>
              ))}
            </> : <>
              {history.map((item: any) => (
                <TableRow key={`${item.id}-history`} className={styles.tableRow}>
                  <TableCell>{item?.date}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{setTypeStatus(item.status, item.displayStatus)}</TableCell>
                  <TableCell>{item?.paid_at || '-'}</TableCell>
                  <TableCell>{item.payout_method}</TableCell>
                </TableRow>
              ))}
            </>}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination?.totalPages > 1 && <CustomPagination
        shape='rounded'
        count={pagination?.totalPages}
        page={pagination?.currentPage}
        onChange={onChange}
        size='large'
        className={styles.pagination}
      />}
    </>
  )
}