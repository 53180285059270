import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import instance from '../providers/axios.config'
import {
  setError,
  setIsAuthenticated, setRole,
  setUserData
} from '../redux/reducers/usersReducer'
import Notiflix from 'notiflix'
import { t } from 'i18next'
import { RootState } from '../redux/reducers/rootReducer'

const useAuthentication = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticatedState] = useState(false)
  const profile = useSelector((state: RootState) => state.user.usersData)
  useEffect(() => {
    const getProfile = async () => {
      if (localStorage.getItem('authToken') && !profile)
        try {
          dispatch(setError(null))
          setIsLoading(true)

          const response = await instance.get('user/profile')
          dispatch(setUserData({...response.data}))
          dispatch(setRole(response.data.role))
          dispatch(setIsAuthenticated(true))
          setIsAuthenticatedState(true)
          setIsLoading(false)
        } catch (error) {
          console.error('User is not logged in:', error)

          localStorage.removeItem('token')
          dispatch(setError((error as any).message || 'Error occurred'))
          setIsAuthenticated(false)
          setIsAuthenticatedState(false)
          navigate('/login')
          setIsLoading(false)

          // Show an error notification using Notiflix
          Notiflix.Report.failure(
            t('Authentication failed. Please log in again.'),
            t(''),
            t('OK')
          )
        }
      else setIsLoading(false)
    }

    getProfile()

    return () => {
    }
  }, [dispatch, navigate, profile, localStorage.getItem('authToken')])

  return { isLoading, isAuthenticated }
}

export default useAuthentication
