import { useTranslation } from 'react-i18next'
import { Title } from '../../Title'
import styles from './SubscriptionsCredits.module.scss'
import React, { useEffect, useState } from 'react'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import Skeleton from '@mui/material/Skeleton'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import CardSubscriptions from './cardSubscriptions'
import { IUserSubscription, typeResponse } from '../../../../../../../utils/types'

interface IResponseSubscriptions extends typeResponse {
  data:{
    subscriptions: IUserSubscription[]
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}

export const MyCredits = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [myCreditsActive, setMyCreditsActive] = useState<IUserSubscription[]>([])
  const [myCreditsHistory, setMyCreditsHistory] = useState<IUserSubscription[]>([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 5,
    totalPages: 1
  })
  const getMySubscriptions = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`subscriptions/credits?active=1&limit=5&page=${pagination.currentPage}`) as IResponseSubscriptions
      setMyCreditsActive(res?.data?.subscriptions || [])
      const resHistory = await instance.get(`subscriptions/credits?active=0&limit=5&page=${pagination.currentPage}`) as IResponseSubscriptions
      setMyCreditsHistory(resHistory?.data?.subscriptions || [])
      setPagination({ ...resHistory?.data?.pagination })
      setIsLoading(false)
    } catch (error) {

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    getMySubscriptions()
  }, [])
  const handleChange = (event: any, value: any) => {
    setPagination({...pagination, currentPage: value})
  }
  const loadingRender = () => {
    return (
      <div>
        <div className={styles.bold}>
          <Skeleton />
        </div>
        <div>
          <span className={styles.bold}> <Skeleton /></span>{' '}
          <Skeleton />
        </div>
        <div>
          <span className={styles.bold}> <Skeleton /></span>{' '}
          <Skeleton />
        </div>
      </div>
    )
  }
  if(!isLoading && !myCreditsActive?.length) {
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <Title title={t('My Credits')} />
        </div>
        <div className={styles.content}>
          {t('no_credits_active')}
        </div>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Title title={t('My Credits')} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.title}>{t('Active Credits')}</div>
          {isLoading ? loadingRender() : <>
            {myCreditsActive.map((active: any) => (
              <>
                <div key={`active-${active?.id}`}>
                <CardSubscriptions item={{name: active?.name, expiresAt: active?.expiresAt, downloads: active?.credits, used: active?.credits_used}}/>
                </div>
            </>))}
          </>}
          {myCreditsHistory?.length ? <div className={styles.title}>{t('history_credits')}</div> : null}
          {isLoading ? loadingRender() : <div className={styles.contentHistory}>
            {myCreditsHistory.map((active: any) => (
              <div key={`history-${active?.id}`}>
                <CardSubscriptions item={{name: active?.name, expiresAt: active?.expiresAt, downloads: active?.credits, used: active?.credits_used}}/>
              </div>
            ))}
          </div>}
        </div>
        {pagination?.totalPages > 1 && <CustomPagination
          shape='rounded'
          count={pagination?.totalPages}
          page={pagination?.currentPage}
          onChange={handleChange}
          size='large'
          className={styles.pagination}
        />}
    </div>
</div>
)
}
