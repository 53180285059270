import { Box, Grid, Paper, Typography } from '@mui/material'
import styles from './Widget.module.scss';
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import { cardSeller } from '../../main-components/content/account-details/sections/seller/home/HomeSeller'


const CustomBox = (props: any) => (
  <Box className={styles.iconBox} sx={{ backgroundColor: props?.backgroundColor }}>{props.children}</Box>
);

type propsType = {
  data?: cardSeller,
  loading?: boolean
}

export const WidgetHomeSeller = (props: propsType) => {
  const {data, loading} = props;
  const navigate = useNavigate()
  const handleRedirect = () => {
    if(data?.path) {
      navigate(data?.path)
    }
  }
  return (
    <Paper sx={{width: '28rem', cursor: 'pointer', backgroundColor: '#fdfbfb', '&:hover': {
      backgroundColor: '#eeeeee'}}} onClick={handleRedirect}>
      <Box sx={{ padding: 4 }}>
        <Grid container>
          <Grid item xs={8}>
            <Box>
              {loading ? <Skeleton /> : <span className={styles.textSubtitle} style={{color: data?.color}}>{data?.count}</span>}
              {loading ? <Skeleton /> : <Typography fontSize={20} className={styles.textTitle}>{data?.title}</Typography>}
            </Box>
          </Grid>
          <Grid item xs={4} className={styles.gridIcon}>
            {loading ? <Skeleton /> :<CustomBox backgroundColor={data?.color}>
              {data?.icon}
            </CustomBox>}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
export default WidgetHomeSeller;