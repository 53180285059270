import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../../../../../utils/constants'
import { Title } from '../../Title'
import { ReleaseForm } from './ReleaseForm'

const releasesForms = [
  {
    logo: constants.crop_eps,
    releaseFormType: i18next.t('Property Release forms'),
    pdfReleaseFormType: i18next.t('PDF Property Release form'),
    digitalReleaseFormType: i18next.t('Digital Property Release form'),
    pathPdf: 'forms/property_release.jpg',
    pathDigital: 'forms/property_release.jpg',
    name: 'property_release'
  },
  {
    logo: constants.crop_eps,
    releaseFormType: i18next.t('Model Release forms'),
    pdfReleaseFormType: i18next.t('PDF Model Release form'),
    digitalReleaseFormType: i18next.t('Digital Model Release form'),
    pathPdf: 'forms/model_release.jpg',
    pathDigital: 'forms/model_release.jpg',
    name: 'model_release'
  },
  {
    logo: constants.crop_eps,
    releaseFormType: i18next.t('Minor Release forms'),
    pdfReleaseFormType: i18next.t('PDF Minor Release form'),
    digitalReleaseFormType: i18next.t('Digital Minor Release form'),
    pathPdf: 'forms/minor_release.jpg',
    pathDigital: 'forms/minor_release.jpg',
    name: 'minor_release'
  },
]

export const ReleaseFormsList = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Title title={t('Releases Forms')} />

      <div>
        {releasesForms.map((releaseForm, index) => (
          <ReleaseForm key={index} {...releaseForm} />
        ))}
      </div>
    </div>
  )
}
