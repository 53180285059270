import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../dialogs/ConfirmDialog'
import styles from './PublicationImageDeclinedCard.module.scss'

interface IProps {
  item: {
    thumbURL?: string,
    id?: string | number,
    reason?: string,
  },
  onDeleteById?: (key: any) => void
}

export const PublicationImageDeclinedCard = (props: IProps) => {
  const {item, onDeleteById} = props;
  const { thumbURL,id, reason } = item

  const { t } = useTranslation()

  const [openDeletePopup, setOpenDeletePopup] = useState(false)

  const handleConfirm = () => {
    if(onDeleteById && id) {
      onDeleteById(id)
    }
    setOpenDeletePopup(false)
  }

  const handleOpen = () => {
    setOpenDeletePopup(!openDeletePopup)
  }

  return (
    <>
      {openDeletePopup && (
        <ConfirmDialog
          open={openDeletePopup}
          handleOpen={() => {
            handleOpen()
          }}
          handleConfirm={() => {
            handleConfirm()
          }}
          dialogTitle={t('Delete Image')}
          dialogMessage={t('Are you sure to delete imageId', {
            imageId: id,
          })}
        />
      )}
      <div className={styles.container}>
        <div className={styles.left}>
          <img src={thumbURL} alt='' className={styles.image} />
          <div>
            <span className={styles.imageText}>
              {t('Image ID') + ': ' + id}
            </span>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.reasonForRejection}>
            {t('Reason for rejection')}
          </div>
          <div className={styles.reasonForRejectionText}>
            {reason}
          </div>
        </div>

        <Button
          className={styles.deleteButton}
          onClick={() => setOpenDeletePopup(true)}
        >
          {t('Delete')}
        </Button>
      </div>
    </>
  )
}
