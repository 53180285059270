import Pagination from '@mui/material/Pagination'

export const CustomPagination = ({ ...props }) => {
  return (
    <Pagination
      {...props}
      siblingCount={0}
      sx={{
        '& .MuiPaginationItem-root': {
          backgroundColor: '#white',
          '&:hover': {
            backgroundColor: '#c11b1e',
            color: 'white',
          },
          '&.Mui-selected': {
            backgroundColor: '#c11b1e',
            color: 'white',
            '&:hover': {
              backgroundColor: '#c11b1e',
              color: 'white',
            },
          },
        },
      }}
    />
  )
}
