import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPasswordField } from '../../textfield/CustomPasswordField'
import styles from './Dialogs.module.scss'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key: string, key1: string) => void
  dialogTitle: string
  password: string
}
const reggexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{6,}$/

export const ChangePasswordDialog = (props: IProps) => {
  const { open, handleOpen, handleConfirm, dialogTitle, password } = props

  const [errorPass, setErrorPass] = useState(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [error, setError] = useState(false);

  const { t } = useTranslation()

  // useEffect(() => {
  //   setNewPassword(password)
  // }, [password])

  const handleSave = () => {
    if(newPassword !== confirmNewPassword) {
      setError(true)
    }
    if(!reggexPassword.test(newPassword)) {
      setErrorPass(true)
    }
    else {
      handleConfirm(newPassword, confirmNewPassword)
      setNewPassword('')
      setConfirmNewPassword('')
      setErrorPass(false)
      setError(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <CustomPasswordField
            label={t('New Password')}
            value={newPassword}
            changePassword={setNewPassword}
          />

          <CustomPasswordField
            label={t('Confirm new Password')}
            value={confirmNewPassword}
            changePassword={setConfirmNewPassword}
          />
        </div>
        <Typography fontSize={12}>{t('password_message_validator')}</Typography>
        {error && <Typography color="primary">{t('password_does_not_match')}</Typography>}
        {errorPass && <Typography color="primary">{t('password_message_validator')}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} className={styles.cancelButton}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSave} className={styles.confirmButton}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
