import styles from '../../main-components/content/account-details/sections/seller/upload/Step3.module.scss'
import { Box, Chip } from '@mui/material'
import { PublicationImageCard } from '../card/PublicationImageCard'
import { CustomTextarea } from '../textfield/CustomTextArea'
import { useTranslation } from 'react-i18next'

export const SavedImages = (props: any) => {
  const { t } = useTranslation()
  const { images } = props
  return (
    <div className={styles.elements}>
      {images.map((el: any) => {
        return (
          <div key={el?.imageId} className={styles.elementContainer}>
            <PublicationImageCard
              name={el?.originalFilename}
              image={
                el?.thumbnail
              }
              imageId={
                el?.imageId || ''
              }
              canBeDeleted={false}
            />

            <div className={styles.elementButtonsContainer}>
              <div className={styles.descriptionContainer}>
                <CustomTextarea
                  size='small'
                  name='description'
                  variant='outlined'
                  placeholder='Description'
                  multiline
                  disabled
                  // helperText={`Left: ${MAXIMUM_CHARACTERS_DESCRIPTION - (findElement?.description?.length || 0)} from  ${MINIMUM_CHARACTERS_DESCRIPTION} to ${MAXIMUM_CHARACTERS_DESCRIPTION}`}
                  value={el?.description || ''}
                  label={t('Description')}
                />
              </div>
              <Box>
                {(el?.keywords || []).map((chip: any) => (
                  <Chip
                    sx={{margin: '2px'}}
                    key={chip}
                    label={chip}
                    disabled
                  />
                ))}
              </Box>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SavedImages