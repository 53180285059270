import { ReactNode, useEffect, useRef, useState } from 'react'

interface IProps {
  children: ReactNode
  animationStyle: string
}

export const AnimationContainer = (props: IProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const callback = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      })
    }

    const observer = new IntersectionObserver(callback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    })

    if (containerRef?.current) {
      observer.observe(containerRef.current as Element)
    }
  }, [])

  return (
    <div ref={containerRef} className={`${isVisible && props.animationStyle}`}>
      {props.children}
    </div>
  )
}
