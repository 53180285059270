import { Button, List, ListItemButton, ListItemText } from '@mui/material'
import { useState } from 'react'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { NavigationEnum } from '../../../utils/constants'
import styles from './NavButton.module.scss'

interface IItemList {
  name: string
  value: number
  to: NavigationEnum
  params?: string
}

interface IProps {
  title: string
  value?: number
  listOfItems?: Array<IItemList>
  selected?: boolean
  hovered?: boolean
  buttonOnMouseEnter?: Function
  to: NavigationEnum
  params?: 'JPG' | 'PNG' | 'EPS',
  setSelectedButton: (key: number) => void
}

export const NavButton = (props: IProps) => {
  const {
    title,
    listOfItems,
    selected = true,
    buttonOnMouseEnter,
    hovered,
    value,
    to,
    setSelectedButton
  } = props

  const customNavigate = useCustomNavigation()

  const [hoveredListItem, setHoveredListItem] = useState<
    number | string | undefined
  >()

  const handleClick = (valueList: number | string, to: NavigationEnum, params?: any) => {
    setSelectedButton(Number(value))
    to !== NavigationEnum.NOWHERE && customNavigate(to,null, params)
  }
  return (
    <div
      className={styles.container}
      onMouseLeave={() => buttonOnMouseEnter && buttonOnMouseEnter()}
    >
      <Button
        className={
          selected || hovered ? styles.buttonSelected : styles.buttonNotSelected
        }
        onMouseEnter={() => buttonOnMouseEnter && buttonOnMouseEnter(value)}
        onClick={() => {
          setSelectedButton(Number(value))
          to !== NavigationEnum.NOWHERE && customNavigate(to);
        }}
      >
        {title}
      </Button>

      {hovered && listOfItems && (
        <List className={styles.list}>
          {listOfItems.map((item, index) => (
            <ListItemButton
              className={
                hoveredListItem === item.value ? styles.listItemHovered : ''
              }
              key={index}
              onMouseEnter={() => setHoveredListItem(item.value)}
              onMouseLeave={() => setHoveredListItem(undefined)}
              onClick={() => handleClick(item.value, item.to, item?.params)}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          ))}
        </List>
      )}
    </div>
  )
}
