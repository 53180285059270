import { ToggleButton } from '@mui/material'

export const CustomToggleButton = ({ ...props }) => (
  <ToggleButton
    {...props}
    value={props.value}
    sx={{
      borderRadius: '45%',
      fontSize: 14,
      fontWeight: 600,
      paddingLeft: 2,
      paddingRight: 2,
      borderColor: '#c11b1e',
      '&.Mui-selected': {
        backgroundColor: '#c11b1e',
        color: 'white',
        '&:hover': {
          backgroundColor: '#c11b1e',
          color: 'white',
        },
      },
      '&:hover': {
        backgroundColor: '#c11b1eB0',
        color: 'white',
      },
    }}
  >
    {props.children}
  </ToggleButton>
)
