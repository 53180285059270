import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
const USER_REGISTERED = 'user-registered';
const USER_DELETED_ACCOUNT = 'user-deleted-account';
// seller
const SELLER_EXAMINATION_APPROVED = 'seller-examination-approved';
const SELLER_EXAMINATION_DENIED = 'seller-examination-denied';
const SELLER_BUYER_BOUGHT_IMAGE = 'seller-buyer-bought-image';
const SELLER_TAX_FORM_APPROVED = 'seller-tax-form-approved';
const SELLER_TAX_FORM_DENIED = 'seller-tax-form-denied';
const SELLER_PAYOUT_APPROVED = 'seller-payout-approved';
const SELLER_PAYOUT_DENIED = 'seller-payout-denied';
const SELLER_IMAGE_100_VIEWS = 'seller-image-100-views';
const SELLER_IMAGE_500_VIEWS = 'seller-image-500-views';
const SELLER_IMAGE_1000_VIEWS = 'seller-image-1000-views';
const SELLER_IMAGE_50_BOUGHT = 'seller-image-50-bought';
const SELLER_IMAGE_100_BOUGHT = 'seller-image-100-bought';
const SELLER_IMAGE_500_BOUGHT = 'seller-image-500-bought';
const SELLER_STATUS_INOPERABLE = 'seller-status-inoperable';
// buyer
const BUYER_BOUGHT_SUBSCRIPTION = 'buyer-bought-subscription';
const BUYER_INVOICE_REFUND = 'buyer-invoice-refund';

export const notificationsIcons = (type: string) => {
  switch (type) {
    case USER_REGISTERED:
      return <AccountCircleIcon />;
    case SELLER_EXAMINATION_APPROVED:
      return <TaskAltOutlinedIcon />
    case SELLER_EXAMINATION_DENIED:
      return <DoNotDisturbOnOutlinedIcon />
    case SELLER_BUYER_BOUGHT_IMAGE:
      return <DoNotDisturbOnOutlinedIcon />
    case SELLER_TAX_FORM_APPROVED:
      return <AssignmentTurnedInOutlinedIcon />
    case SELLER_TAX_FORM_DENIED:
      return <DoNotDisturbOnOutlinedIcon />
    case SELLER_PAYOUT_APPROVED:
      return <SavingsOutlinedIcon />
    case SELLER_PAYOUT_DENIED:
      return <DoNotDisturbOnOutlinedIcon />
    case SELLER_IMAGE_100_VIEWS:
      return <InsertPhotoOutlinedIcon />
    case SELLER_IMAGE_500_VIEWS:
      return <InsertPhotoOutlinedIcon />
    case SELLER_IMAGE_1000_VIEWS:
      return <InsertPhotoOutlinedIcon />
    case SELLER_IMAGE_50_BOUGHT:
      return <AttachMoneyOutlinedIcon />
    case SELLER_IMAGE_100_BOUGHT:
      return <AttachMoneyOutlinedIcon />
    case SELLER_IMAGE_500_BOUGHT:
      return <AttachMoneyOutlinedIcon />
  }
}