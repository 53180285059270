import { Box, Button, Chip, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React  from 'react'
import moment from 'moment'
import { CustomTextfield } from '../../../../../../../shared-components/textfield/CustomTextField'
import { UploaderContainerStep4 } from '../UploadContainerStep4'
import styles from './ReleasesTypes.module.scss'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../redux/reducers/rootReducer'
import { setInitialValuesMinor } from '../Step4'
import { IMinor } from '../../../../../../../../utils/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  image: string
  id: string
  control?: any,
  index?: number,
  field?: IMinor,
  minorAppend?: (key?: any) => {},
  fieldArrayName?: string,
  errors?: any,
  onDeleteMinor?: (key?: any) => void,
  watch?: any,
  setValue?: any,
  name?: string,
}

export const MinorForm = (props: IProps) => {
  const {
    image,
    id,
    minorAppend,
    errors,
    fieldArrayName,
    index,
    field,
    onDeleteMinor,
    control,
    watch,
    setValue,
    name,
  } = props
  const { t } = useTranslation()
  const accountInfo = useSelector((state: RootState) => state.user.usersData);

  const dataFile = watch(`${fieldArrayName}.minor[${index}].file`)
  const dataFileMinorFile = watch(`${fieldArrayName}.minor[${index}].minor.minor_path`)
  return (
    <Box display={'flex'} flexDirection='column' alignItems={'center'}>
      <Controller
        name={`${fieldArrayName}.minor[${index}].file`}
        control={control}
        render={({ field: { ref, ...newField } }) =>
          <UploaderContainerStep4
            title={''}
            description={t('drop_pdf_jpg')}
            value={dataFile}
            handleAddedFile={(file: File | null) => {
              if(file) {
                setValue(`${fieldArrayName}.minor[${index}].minor`, {
                  minor_name: '',
                  minor_path: undefined,
                  minor_dob: '',
                })
                setValue(`${fieldArrayName}.minor[${index}].parent`, {
                  parent_name: '',
                  parent_address: '',
                  parent_phone: '',
                  parent_email: '',
                  parent_date_signed: '',
                  parent_signature: undefined,
                })
                setValue(`${fieldArrayName}.minor[${index}].photographer`, {
                  photographer_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
                  photographer_email: accountInfo?.email,
                  photographer_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
                  photographer_phone:  `${accountInfo?.addresses?.[0]?.phone || ''}`,
                  photographer_date_signed: new Date(),
                  photographer_signature: undefined,
                })
                setValue(`${fieldArrayName}.model[${index}].witness`, {
                  witness_name: '',
                  witness_date_signed: '',
                  witness_signature: undefined,
                })
              }
              setValue(`${fieldArrayName}.minor[${index}].file`, file)
              newField.onChange({
                target: {
                  value: file,
                  name: `${fieldArrayName}.minor[${index}].file`
                }
              })
            }}
          />}
      />
      {dataFile &&  <Box sx={{width: 250}}><Controller
        name={`${fieldArrayName}.minor[${index}].name_minor`}
        control={control}
        render={({ field: { ref, ...newField } }) =>
          <CustomTextfield className={styles.textfield}
                           size='small'
                           inputRef={ref}
                           error={errors?.name_minor}
                           helperText={errors?.name_minor ? t(errors?.name_minor?.message) : ''}
                           variant='outlined'
                           label={t('Full Name')}
                           {...newField} />}
      /></Box>}
      <>
        <Divider
          sx={{
            borderColor: 'grey.500',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Chip label={t('OR')}></Chip>
        </Divider>
        <Button
          className={styles.newPropertyButton}
          onClick={() => {
            if(minorAppend) {
              minorAppend({...setInitialValuesMinor(accountInfo)})}
          }}
        >
          {t('New Minor Release')}
        </Button>
        <div style={{ position: 'relative' }} className={styles.minorPdf}>
          {dataFile?.name && <div className={styles.mask}></div>}
          <div className={styles.termsAndConditions}>
            {t('Minor_release_terms')}
          </div>

          <div>
            <div className={styles.contentFileUpload}>
              <div>
                <img src={image} alt='img' className={styles.image} />
                <br />
                <span className={styles.titleText}>{t('Name')}:</span>{' '}
                <span>{name}</span>
              </div>
              <div>
                <Controller
                  name={`${fieldArrayName}.minor[${index}].minor.minor_path`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      description={t('drop_pdf_jpg')}
                      value={dataFileMinorFile}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `${fieldArrayName}.minor[${index}].minor.minor_path`
                          }
                        })
                      }}
                    />}
                />
                <div
                  className={styles.errorText}>{errors?.minor?.minor_path ? t(errors?.minor?.minor_path?.message) : ''}</div>
              </div>

            </div>
            <br />
            <br />
            <p className={styles.titleText}>Minor Information: </p>
            <Controller
              name={`${fieldArrayName}.minor[${index}].minor.minor_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 error={errors?.minor?.minor_name}
                                 helperText={errors?.minor?.minor_name ? t(errors?.minor?.minor_name?.message)  : ''}
                                 inputRef={ref}
                                 variant='outlined'
                                 label={t('Full Name')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.minor[${index}].minor.minor_dob`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Birth Date')}
                    format='dd-MM-yyyy'
                    disableFuture
                    minDate={new Date(moment().subtract(18, 'years').format('DD-MM-YYYY'))}
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <span
              className={styles.errorText}>{errors?.minor?.minor_dob ? t(errors?.minor?.minor_dob?.message) : ''}</span>
            <br />
            <br />
            <p className={styles.titleText}>Parent/Guardian Information: </p>
            <Controller
              name={`${fieldArrayName}.minor[${index}].parent.parent_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 error={errors?.parent?.parent_name}
                                 helperText={errors?.parent?.parent_name ? t(errors?.parent?.parent_name?.message) : ''}
                                 variant='outlined'
                                 label={t('Full Name')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.minor[${index}].parent.parent_address`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 error={errors?.parent?.parent_address}
                                 helperText={errors?.parent?.parent_address ? t(errors?.parent?.parent_address?.message) : ''}
                                 label={t('Full Address')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.minor[${index}].parent.parent_phone`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 error={errors?.parent?.parent_phone}
                                 helperText={errors?.parent?.parent_phone ? t(errors?.parent?.parent_phone?.message) : ''}
                                 label={t('Phone')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.minor[${index}].parent.parent_email`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 error={errors?.parent?.parent_email}
                                 helperText={errors?.parent?.parent_email ? t(errors?.parent?.parent_email?.message) : ''}
                                 label={t('Email')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.minor[${index}].parent.parent_date_signed`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <span
              className={styles.errorText}>{errors?.witness?.parent_date_signed ? t(errors?.parent?.parent_date_signed?.message) : ''}</span>
            <div>
              <span className={styles.titleText}>Signature</span>
              <Controller
                name={`${fieldArrayName}.minor[${index}].parent.parent_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg')}
                    value={field?.file}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.minor[${index}].parent.parent_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.witness?.parent_signature ? t(errors?.parent?.parent_signature?.message) : ''}</span>
            </div>
            <br />
            <div className={styles.formContainer}>
              <div className={styles.titleText}>{t('author_information')}</div>
              <Controller
                name={`${fieldArrayName}.minor[${index}].photographer.photographer_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Full Name')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.minor[${index}].photographer.photographer_address`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled={!!accountInfo?.addresses?.[0]?.address}
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Full Address')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.minor[${index}].photographer.photographer_phone`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   inputRef={ref}
                                   disabled={!!accountInfo?.addresses?.[0]?.phone}
                                   variant='outlined'
                                   label={t('Phone')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.minor[${index}].photographer.photographer_email`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Email')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.minor[${index}].photographer.photographer_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      disabled
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small' }
                      }}
                    />
                  </LocalizationProvider>}
              />
              <div>
                <span className={styles.titleText}>Signature</span>
                <Controller
                  name={`${fieldArrayName}.minor[${index}].photographer.photographer_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      description={t('drop_pdf_jpg')}
                      value={field?.file}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `${fieldArrayName}.minor[${index}].photographer.photographer_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.photographer?.photographer_signature ? t(errors?.photographer?.photographer_signature?.message) : ''}</span>
              </div>
            </div>
            <br />
            <p className={styles.titleText}>Witness Information: </p>
            <Controller
              name={`${fieldArrayName}.minor[${index}].witness.witness_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 error={errors?.witness?.witness_name}
                                 helperText={errors?.witness?.witness_name ? t(errors?.witness?.witness_name?.message) : ''}
                                 inputRef={ref}
                                 variant='outlined'
                                 label={t('Full Name')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.minor[${index}].witness.witness_date_signed`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <span
              className={styles.errorText}>{errors?.witness?.witness_date_signed ? t(errors?.witness?.witness_date_signed?.message) : ''}</span>
            <div>
              <span className={styles.titleText}>Signature</span>
              <Controller
                name={`${fieldArrayName}.minor[${index}].witness.witness_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg')}
                    value={field?.file}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.minor[${index}].witness.witness_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.witness?.witness_signature ? t(errors?.witness?.witness_signature?.message) : ''}</span>
            </div>
            {index && index >= 1 ? <Button
              className={styles.deleteModelButton}
              onClick={() => {
                if (onDeleteMinor) {
                  onDeleteMinor(index)
                }
              }}
            >
              {t('delete_minor')}
            </Button> : null }
          </div>
        </div>
      </>
    </Box>
  )
}
