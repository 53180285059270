import { MenuItem } from '@mui/material'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import { useTranslation } from 'react-i18next'
import { CustomTextfield } from './CustomTextField'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

countries.registerLocale(enLocale)
const countryObj = countries.getNames('en', { select: 'official' })

interface IProps {
  setSelectedCountry: (event: any) => void
  selectedCountry: string
  className: string
  size: string
  error?: boolean
}
export const CustomCountrySelect = (props: IProps) => {
  const { selectedCountry, setSelectedCountry, className, error } = props
  const countries = useSelector((state: RootState) => state.countries.countries)

  const { t } = useTranslation()
  return (
    <CustomTextfield
      variant='outlined'
      select
      className={className}
      size={props.size}
      label={t('Country')}
      error={error}
      onChange={(event: any) => setSelectedCountry(event.target.value)}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '200px',
            },
          },
        },
      }}
      value={selectedCountry || ''}
    >
      {countries.map((country) => (
        <MenuItem
          key={country.value}
          value={country.value}
        >
          {country.label}
        </MenuItem>
      ))}
    </CustomTextfield>
  )
}
