import { useTranslation } from 'react-i18next'
import styles from './Step1.module.scss'
import { ContainerUploadStep } from '../../../../../../shared-components/containerUploadStep'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import instance from '../../../../../../../providers/axios.config'
import { setUploadSession, setUploadSessionStep1 } from '../../../../../../../redux/reducers/uploadSessionReducer'
import Notiflix from 'notiflix'
import { useEffect, useState } from 'react'
import { IUploadStep1 } from '../../../../../../../utils/types'
import { setUserData } from '../../../../../../../redux/reducers/usersReducer'

const initialImages = {
  images: {
    jpg: [],
    eps: [],
    png: [],
  },
  sessionId: 0,
  inExamination: false,
  unsavedImages: []
}
const initialImagesLoading = {
  images: {
    jpg: [],
    eps: [],
    png: [],
  },
}

export const Step1: React.FC<{
  maxLength?: number
  setFirstStepFiles: React.Dispatch<React.SetStateAction<File[]>>
}> = ({ setFirstStepFiles, maxLength }) => {
  const { t } = useTranslation()
  const [imagesloading, setImagesLoading] = useState({
   ...initialImagesLoading
  })
  const [isLoading, setIsLoading] = useState(false);
  const [newResponseUpload, setNewResponseUpload] = useState<IUploadStep1>({})
  const dispatch = useDispatch()
  const uploadImages = useSelector(
    (state: RootState) => state.uploadSession.imagesFirstStep
  )

  useEffect(() => {
    if(newResponseUpload.sessionId && newResponseUpload?.images) {
      setImagesLoading({...initialImagesLoading})
      dispatch(setUploadSessionStep1({
        ...uploadImages,
        images: {
          jpg: [...uploadImages?.images?.jpg, ...newResponseUpload?.images?.jpg],
          png: [...uploadImages?.images?.png, ...newResponseUpload?.images?.png],
          eps: [...uploadImages?.images?.eps, ...newResponseUpload?.images?.eps]
        },
        unsavedImages: newResponseUpload?.unsavedImages ? [...newResponseUpload?.unsavedImages] : []
      }))
      setNewResponseUpload({})
    }
  }, [newResponseUpload?.sessionId])
  const handleUploadToServer = async (files: any, type: any) => {
    setImagesLoading({
      images: {
        ...imagesloading?.images,
        [type]: [...files]
      }
    })
    const formData = new FormData()
    files.forEach((file: any, index: any) => {
      formData.append(`files[${index}]`, file)
      // setImages((prevFiles: any) => [...prevFiles, URL.createObjectURL(file)])
    })
    formData.append('nextStep', JSON.stringify(0))
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>', // Set the Content-Type header for FormData
      },
    }
    try {
      // Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post('session/upload', formData, config) as any
      if ((resp as any).status === 'success') {
        setNewResponseUpload(resp?.data)
        const response = await instance.get('user/profile')
        dispatch(setUserData({...response.data}))
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
        /**
         * images: {
        ...imagesloading?.images,
        [type]: [...files]
      }
         */
        // setImagesLoading(prevState => {

        //   return { ...prevState };
        // });
        setImagesLoading({...initialImagesLoading});
      }
    } catch (error) {
      Notiflix.Loading.remove()
      // Handle error
      setImagesLoading({...initialImagesLoading})
    }
  }
  const handleDeleteImage = async (imageId: number, type: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.delete(`session/delete?images[0]=${imageId}`)
      if ((resp as any).status === 'success') {
        const deleteImage = (uploadImages?.images[type] || []).filter((image: any) => image.imageId !== imageId) as any;
        dispatch(setUploadSessionStep1({...uploadImages, images: {
            ...uploadImages.images,
            [type]: deleteImage
          }}))
      }
    } catch (error) {
      // Handle error
      console.error('Error delete files:', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  return (
    <div>
      <div className={styles.titleContainer}>
        <span className={styles.step}>{t('Step', { step: 1 })}</span>
        <span className={styles.title}>{t('Upload Formats/Photos')}</span>
      </div>
      <div className={styles.description}>{t('Step1_description')}</div>

      <ContainerUploadStep  acceptFile={{'image/jpeg': [],}}  title={t('Format Uploader', { format: 'JPG' })}
                            type="jpg"
                            key="jpg"
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                            maxLength={Number(maxLength) - Number(uploadImages?.images?.jpg?.length)}
                            unsavedImages={(uploadImages?.unsavedImages || []).filter((img: any) => img.extension === 'jpg' || img.extension === 'jpeg')}
                            uploadImages={[...uploadImages?.images?.jpg, ...imagesloading?.images?.jpg] || []}
                            handleDeleteImage={handleDeleteImage}
                            description={t('Drop format here', { format: 'JPG' })} onUpload={handleUploadToServer}/>
      <ContainerUploadStep  acceptFile={{'image/png': [],}}  title={t('Format Uploader', { format: 'PNG' })}
                            handleDeleteImage={handleDeleteImage}
                            type="png"
                            key="png"
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                            maxLength={Number(maxLength) - Number(uploadImages?.images?.png?.length)}
                            unsavedImages={(uploadImages?.unsavedImages || []).filter((img: any) => img.extension === 'png')}
                            description={t('Drop format here', { format: 'PNG' })} onUpload={handleUploadToServer} uploadImages={[...uploadImages?.images?.png, ...imagesloading?.images?.png] || []}/>
      <ContainerUploadStep  acceptFile={{'application/postscript': [],}} title={t('Format Uploader', { format: 'EPS' })}
                            handleDeleteImage={handleDeleteImage}
                            type="eps"
                            key="eps"
                            setIsLoading={setIsLoading}
                            isLoading={isLoading}
                            maxLength={Number(maxLength) - Number(uploadImages?.images?.eps?.length)}
                            unsavedImages={(uploadImages?.unsavedImages || []).filter((img: any) => img.extension === 'eps')}
                            description={t('Drop format here', { format: 'EPS' })} onUpload={handleUploadToServer} uploadImages={[...uploadImages?.images?.eps, ...imagesloading?.images?.eps]|| []}/>
    </div>
  )
}
