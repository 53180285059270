import { Provider } from 'react-redux'
import store from './redux/store/store'
import Main from './components/main-components/main/Main'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { theme } from './utils/theme'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Main />
          </QueryParamProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

export default App
