import { HeroSection } from './HeroSection'
import { LicensesSection } from './LicensesSection'
import { ServicesSection } from './ServicesSection'

export const Home = () => {
  return (
    <>
      <HeroSection />
      <ServicesSection />
      <LicensesSection />
    </>
  )
}
