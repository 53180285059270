import { Box, Container } from '@mui/material'
import { Content } from '../content/Content'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'

export default function Main() {
  return (
    <Box sx={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'column'}}>
      <Box>
        <Header />
      </Box>
      <Container maxWidth={false} disableGutters sx={{width: '100%', flex: 1}}>
        <Content />
      </Container>
      <Box sx={{flex: 0}}>
        <Footer />
      </Box>
    </Box>
  )
}
