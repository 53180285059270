import React from 'react'
import { Navigate } from 'react-router-dom'
import Notiflix from 'notiflix'
import useAuthentication from '../../../hooks/useAuthentication'
import { RootState } from '../../../redux/reducers/rootReducer'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { BUYER, ROUTES, SELLER, VALID } from '../../../utils/constants'
import { AccountDetailsSeller } from './account-details/AccountDetailsSeller'
import { AccountDetailsBuyer } from './account-details/AccountDetailsBuyer'
import HomeSeller from './account-details/sections/seller/home/HomeSeller'
import { BuyerHome } from './account-details/sections/buyer/home/BuyerHome'

export const ProtectedRoute: React.FC<{ element: React.ReactNode }> = ({
                                                                         element,
                                                                       }) => {
  const { isAuthenticated, isLoading } = useAuthentication()
  const profile = useSelector((state: RootState) => state.user.usersData)
  React.useEffect(() => {
    if (isLoading) {
      // Show loading notification while authentication check is in progress
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    } else {
      // Close the loading notification once authentication check is complete
      Notiflix.Loading.remove()
    }
  }, [isLoading])

  if (!isAuthenticated && !isLoading && !profile) {
    // Notiflix.Report.failure(t('You need to log in first.'), t(''), t('OK'))
    Notiflix.Loading.remove()
    return <Navigate to='/login' />
  }
  Notiflix.Loading.remove()
  return <>{element}</>
}
export const RedirectAccount = ()=> {
  const { isAuthenticated, isLoading } = useAuthentication()
  const profile = useSelector((state: RootState) => state.user.usersData)
  React.useEffect(() => {
    if (isLoading) {
      // Show loading notification while authentication check is in progress
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    } else {
      // Close the loading notification once authentication check is complete
      Notiflix.Loading.remove()
    }
  }, [isLoading])

  if (!isAuthenticated && !isLoading && !profile) {
    // Notiflix.Report.failure(t('You need to log in first.'), t(''), t('OK'))
    return <Navigate to='/login' />
  }
  if(profile && profile.role.slug === SELLER && !isLoading ) {
    Notiflix.Loading.remove()
    return <AccountDetailsSeller />
  }
  if(profile && profile.role.slug === BUYER && !isLoading) {
    Notiflix.Loading.remove()
    return <AccountDetailsBuyer />
  } else if(profile && !isLoading) {
    Notiflix.Loading.remove()
    return <Navigate to='/' />
  }
}

export const RedirectHome = () => {
  const { isAuthenticated, isLoading } = useAuthentication()
  const profile = useSelector((state: RootState) => state.user.usersData)
  React.useEffect(() => {
    if (isLoading) {
      // Show loading notification while authentication check is in progress
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    } else {
      // Close the loading notification once authentication check is complete
      Notiflix.Loading.remove()
    }
  }, [isLoading, profile?.role?.slug])

  if (!isAuthenticated && !isLoading && !profile) {
    // Notiflix.Report.failure(t('You need to log in first.'), t(''), t('OK'))
    return <Navigate to='/login' />
  }
  if(profile && profile.role.slug === SELLER && !isLoading) {
    Notiflix.Loading.remove()
    return <HomeSeller />
  }
  if(profile && profile.role.slug === BUYER && !isLoading) {
    Notiflix.Loading.remove()
    return <BuyerHome />
  } else if(profile && !isLoading) {
    Notiflix.Loading.remove()
    return <Navigate to={`/${ROUTES.ACCOUNT}`} />
  }
}

export const ProtectedRouteBuyer: React.FC<{ element: React.ReactNode }> = ({
  element,
}) => {
  const { isAuthenticated, isLoading } = useAuthentication()
  const profile = useSelector((state: RootState) => state.user.usersData)
  React.useEffect(() => {
    if (isLoading) {
      // Show loading notification while authentication check is in progress
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    } else {
      // Close the loading notification once authentication check is complete
      Notiflix.Loading.remove()
    }
  }, [isLoading])
  if (!isAuthenticated && !isLoading && !profile) {
    // Notiflix.Report.failure(t('You need to log in first.'), t(''), t('OK'))
    return <Navigate to='/login' />
  }
  if(profile && profile.role.slug !== BUYER && !isLoading) {
    Notiflix.Loading.remove()
    return <Navigate to={`/${ROUTES.ACCOUNT}`} />
  } else if(profile && !isLoading) {
    Notiflix.Loading.remove()
    return <>{element}</>
  }

}
export const ProtectedRouteSeller: React.FC<{ element: React.ReactNode, path?: string }> = ({
                                                                         element, path,
                                                                       }) => {
  const { isAuthenticated, isLoading } = useAuthentication()
  const profile = useSelector((state: RootState) => state.user.usersData)
  React.useEffect(() => {
    if (isLoading) {
      // Show loading notification while authentication check is in progress
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    } else {
      // Close the loading notification once authentication check is complete
      Notiflix.Loading.remove()
    }
  }, [isLoading])

  if (!isAuthenticated && !isLoading && !profile ) {
    // Notiflix.Report.failure(t('You need to log in first.'), t(''), t('OK'))
    return <Navigate to='/login' />
  }
  if(profile && profile.role.slug !== SELLER && !isLoading) {
    Notiflix.Loading.remove()
    return <Navigate to='/' />
  }
  if(profile && profile.role.slug === SELLER && !isLoading && path === ROUTES.UPLOAD && profile.status !== VALID){
    return <Navigate to={`/${ROUTES.ACCOUNT}/${ROUTES.PUBLICATIONS}/${ROUTES.EXAMINATION}`} />
  }

  return <>{element}</>
}

export const GuestRoute: React.FC<{ element: React.ReactNode }> = ({
  element,
}) => {
  const { isAuthenticated, isLoading } = useAuthentication()

  React.useEffect(() => {
    if (isLoading) {
      // Show loading notification while authentication check is in progress
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    } else {
      // Close the loading notification once authentication check is complete
      Notiflix.Loading.remove()
    }
  }, [isLoading])

  if (isAuthenticated) {
    Notiflix.Loading.remove()
    // If user is already authenticated, redirect away from login or register routes
    Notiflix.Report.success(t('You are already logged in'), t(''), t('OK'))
    return <Navigate to='/account' />
  }
  Notiflix.Loading.remove()
  return <>{element}</>
}

export default GuestRoute
