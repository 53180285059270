import { Box, Chip } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../utils/theme'
import { MAXIMUM_CHARACTERS_KEYWORDS, MINIMUM_CHARACTERS_KEYWORDS } from '../../../utils/constants'
import { CustomTextarea } from '../textfield/CustomTextArea'


export const InputChip = (props: any) => {
  const {onChangeKeywords, keywords, defaultKeywords, allDefaultKeywords, allCheckbox} = props;
  const { t } = useTranslation()
  const [value, setValue] = useState('');
  const [chips, setChips] = useState<string[]>([]);

  const handleKeyDown = (event:any) => {
    if (['Enter', ',', '.', ';'].includes(event.key)) {
      event.preventDefault();

      const value = event.target.value.trim();

      if(allDefaultKeywords?.length && allCheckbox) {
        if (value && !allDefaultKeywords.includes(value) && !keywords.includes(value)) {
          setChips([...chips, value]);
          onChangeKeywords([...keywords, value])
          setValue('')
        }
      } else  {
        if (value && !(keywords.concat(defaultKeywords || [])).includes(value)) {
          setChips([...chips, value]);
          onChangeKeywords([...keywords, value])
          setValue('')
        }
      }
    }
  };

  const handleDelete = (chipToDelete: string) => {
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
    onChangeKeywords(keywords.filter((chip: any) => chip !== chipToDelete));
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataSplitComma = e.target.value.split(/[,;.]+/)
    if(dataSplitComma.length > 1) {
      let newValue: string[] = [];
      dataSplitComma.map((item: any) => {
        const value = item.trim();
        if (value && !keywords.includes(value)) {
          newValue.push(value)
        }
      })
      setChips([...chips, ...newValue]);
      onChangeKeywords([...keywords, ...newValue])
      setValue('')

    } else {
      setValue(e.target.value)
    }
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <CustomTextarea
        multiline
        size="small"
        variant='outlined'
        onKeyDown={handleKeyDown}
        label={t('Keywords')}
        value={value}
        disabled={keywords?.length === MAXIMUM_CHARACTERS_KEYWORDS}
        onChange={onChangeInput}
        helperText={(
          <span>Left: {MAXIMUM_CHARACTERS_KEYWORDS - keywords?.length} <span style={{color: theme.palette.primary.main}}>from {MINIMUM_CHARACTERS_KEYWORDS}</span> <span style={{ color: theme.palette.primary.main }}>to {MAXIMUM_CHARACTERS_KEYWORDS}</span></span>
        )}
      />
      <Box sx={{paddingTop: 2}}>
        {(defaultKeywords || []).map((chip: any) => (
          <Chip
            sx={{margin: '2px'}}
            key={chip}
            label={chip}
            disabled
          />
        ))}
        {(keywords || []).map((chip: any) => (
          <Chip
            sx={{margin: '2px'}}
            key={chip}
            label={chip}
            onDelete={() => handleDelete(chip)}
          />
        ))}
      </Box>
    </Box>
  );
};
