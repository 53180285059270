import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import searchReducer from "../reducers/searchReducer"
import uploadImagesReducer from '../reducers/uploadImagesReducer'
import uploadSessionReducer from '../reducers/uploadSessionReducer'
import usersReducer from "../reducers/usersReducer";
import lightBoxReducer from '../reducers/lightBoxReducer'
import countriesReducer from '../reducers/countriesReducer'
import notificationsReducer from '../reducers/notificationsReducer'

const rootReducer = combineReducers({
  user: usersReducer,
  search: searchReducer,
  uploadImages: uploadImagesReducer,
  uploadSession: uploadSessionReducer,
  lightBox: lightBoxReducer,
  countries: countriesReducer,
  notifications: notificationsReducer
})

const store = configureStore({
  reducer: rootReducer,
});

export type AppStore = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppStore,
  unknown,
  Action
>;

export default store;