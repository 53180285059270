import {
  Alert, Avatar, Box,
  Button,
  FormControl,
  FormControlLabel, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
  Typography
} from '@mui/material'
import Notiflix from 'notiflix'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import instance from '../../../../../../../providers/axios.config'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import {
  IRelease,
  setUploadSession
} from '../../../../../../../redux/reducers/uploadSessionReducer'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import { CustomCheckbox } from '../../../../../../shared-components/checkbox/CustomCheckbox'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import styles from './Step4.module.scss'
import { TabValue } from '../../../AccountDetailsSeller'
import moment from 'moment'
import { useFieldArray, useForm } from 'react-hook-form'
import ImageFieldArray from './ImageFieldArray'
import uploadSchemaValidator from '../../../../../../../utils/uploadSchemaValidator'
import { yupResolver } from '@hookform/resolvers/yup'
import { areObjectValuesEmpty } from '../../../../../../../utils/helpers'
import { IMinor, IModel, IProperty } from '../../../../../../../utils/types'
import { VALID } from '../../../../../../../utils/constants'
import { setImagesleftToUpload, setIsExamination, setUserData } from '../../../../../../../redux/reducers/usersReducer'
import { CustomTabPanel } from '../../../../../../shared-components/customTabPanel'
import { ModelReleaseList } from '../../../../../../shared-components/card/ModelReleaseList'
import { t } from 'i18next'

type Props = {
  setCurrentStep?: (key: number) => void,
  onChangeSections?: (key: TabValue) => void,
  onDeleteAllImages?: () => void;
  handleDeleteImage?: (key?: string | number) => void
}

export const setInitialValuesProperty = (accountInfo: any) => {
  return {
    name: 'property',
    file: undefined,
    created_date: new Date(),
    owner: {
      owner_name: '',
      owner_address: '',
      owner_date_signed: '',
      owner_email: '',
      owner_phone: '',
      owner_signature: undefined
    },
    witness: {
      witness_name: ``,
      witness_date_signed: new Date(),
      witness_signature: undefined
    },
    author: {
      author_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
      author_email: `${accountInfo?.email}`,
      author_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
      author_phone: `${accountInfo?.addresses?.[0]?.phone || ''}`,
      author_date_signed: new Date(),
      author_signature: undefined
    }
  }
}
export const setInitialValuesModel = (accountInfo: any) => {
  return {
    name: 'model', file: undefined,
    model: {
      model_name: '',
      model_address: '',
      model_date_signed: '',
      model_dob: '',
      model_email: '',
      model_phone: '',
      model_signature: undefined,
      model_path: undefined
    },
    photographer: {
      photographer_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
      photographer_email: `${accountInfo?.email}`,
      photographer_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
      photographer_phone: `${accountInfo?.addresses?.[0]?.phone || ''}`,
      photographer_signature: undefined,
      photographer_date_signed: new Date()
    },
    witness: {
      witness_name: '',
      witness_date_signed: '',
      witness_signature: undefined
    }
  }
}
export const setInitialValuesMinor = (accountInfo: any) => {
  return {
    name: 'minor', file: undefined,
    minor: {
      minor_name: '',
      minor_path: undefined,
      minor_dob: ''
    },
    parent: {
      parent_name: '',
      parent_address: '',
      parent_phone: '',
      parent_email: '',
      parent_date_signed: '',
      parent_signature: undefined
    },
    photographer: {
      photographer_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
      photographer_email: accountInfo?.email,
      photographer_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
      photographer_phone: `${accountInfo?.addresses?.[0]?.phone || ''}`,
      photographer_date_signed: new Date(),
      photographer_signature: undefined
    },
    witness: {
      witness_name: '',
      witness_date_signed: '',
      witness_signature: undefined
    }
  }
}
export type ImageType = {
  name: string,
  imageId: number,
  property: IProperty[],
  model: IModel[],
  minor: IMinor[],
  saveStep?: any,
}

export const Step4 = (props: Props) => {
  const { setCurrentStep, onChangeSections, onDeleteAllImages, handleDeleteImage } = props
  const { t } = useTranslation()
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const [modalSaveLicensing, setModalSaveLicensing] = useState(false)
  const [modalSave, setModalSave] = useState(false)
  const account = useSelector((state: RootState) => state.user.usersData)
  const accountInfo = useSelector((state: RootState) => state.user.usersData)
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false)
  const [addedToSelectionElements, setAddedToSelectionElements] = useState<
    number[] | number[]
  >([])

  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false)
  const [allowAIGeneration, setAllowAIGeneration] = useState(true)
  const [errorFeedback, setErrorFeedback] = useState<string>('')

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isDirty },
    reset,
    watch,
    getValues
  } = useForm<any>({
    defaultValues: {
      images: []
    },
    resolver: yupResolver(uploadSchemaValidator)
  })
  const {
    fields: imagesFields,
    append: imagesAppend,
    remove: imagesRemove
  } = useFieldArray({ control, name: 'images' })

  const handleDeleteAll = () => {
    if (onDeleteAllImages) {
      onDeleteAllImages()
    }
    handleOpen()
  }
  const dispatch = useDispatch()
  const handleOpen = () => {
    setOpenDeleteAllDialog(!openDeleteAllDialog)
  }
  const [releases, setReleases] = useState<IRelease[]>([])
  const [imageSelected, setImageSelected] = useState<any>({})
  const [valueTab, setValueTab] = useState(0);

  const handleAddtoSelection = (element: number) => {
    const findImageAppend = getValues() && getValues()?.images?.length ? (getValues()?.images || []).find((value: any) => value.imageId === element) : {} as any
    const findImageIndex = getValues() && getValues()?.images?.length ? (getValues()?.images || []).findIndex((value: any) => value.imageId === element) : {} as any
    const findImage = uploadSession?.images.find((value: any) => value.imageId === Number(element))
    setImageSelected(findImage)
    if (!findImageAppend || !findImageAppend?.imageId) {
      imagesAppend({
        name: '', imageId: element,
        property: [{ ...setInitialValuesProperty(accountInfo) }],
        model: [{ ...setInitialValuesModel(accountInfo) }],
        minor: [{ ...setInitialValuesMinor(accountInfo) }]
      })
    } else {
      setImageSelected({})
      imagesRemove(findImageIndex)
    }
  }

  const handleSubmitForm = async () => {
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>' // Set the Content-Type header for FormData
      }
    }
    const formDataSave = new FormData()
    const formData = handleSaveFormData(getValues(), formDataSave)
    formData.append('nextStep', JSON.stringify(0))
    if(allowAIGeneration){
      const allowAi = allowAIGeneration ? 1 : 0
      formData.append(`can_used_for_ai`, allowAi.toString())
    }
    try {
      const saveData = await instance.post(
        `/session/upload`,
        formData,
        config
      ) as any
      if (saveData?.status === 'error') {
        Notiflix.Report.failure(
          'Error',
          `${saveData?.message}`,
          'OK'
        )
      } else {
        setModalSave(false)
        setImageSelected({})
        setValue('images', [])
        dispatch(setImagesleftToUpload(accountInfo?.noImagesLeftToUpload - 1))
        dispatch(setIsExamination(saveData?.data?.inExamination))
        Notiflix.Notify.success(t('Success'))
        await sessionRetrieve()

      }
      if (onChangeSections) {
        onChangeSections('PUBLICATION')
      }
    } catch (error: any) {
      Notiflix.Report.failure(
        'Error',
        'Failed to update images. Please try again later.',
        'OK'
      )
    }
  }
  const handleSaveFormData = (data: any, formData: any) => {
    (data?.images || []).map((data: ImageType, index: number) => {
      const elementAppend = `releases[${index}]`
      formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
      (data?.property || []).map((property: any, indexProperty: any) => {
        if (property?.file) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          formData.append(`${elementAppend}[property][${indexProperty}][file]`, property?.file)
        } else if (!areObjectValuesEmpty(property?.owner)) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          Object.keys(property?.owner).map((key: string) => {
            formData.append(`${elementAppend}[property][${indexProperty}][owner][${key}]`, key === 'owner_date_signed' ? moment(property?.owner?.owner_date_signed).format('YYYY-MM-DD') : property?.owner?.[key])
          })
          Object.keys(property?.author).map((key?: string) => {
            formData.append(`${elementAppend}[property][${indexProperty}][author][${key}]`, key === 'author_date_signed' ? moment(property?.author?.[key]).format('YYYY-MM-DD') : key ? property?.author?.[key] : '')
          })
          if (!areObjectValuesEmpty(property?.witness)) {
            formData.append(`${elementAppend}[property][${indexProperty}][witness][witness_name]`, property?.witness?.witness_name)
            formData.append(`${elementAppend}[property][${indexProperty}][witness][witness_date_signed]`, moment(property?.witness?.witness_date_signed).format('YYYY-MM-DD'))
            formData.append(`${elementAppend}[property][${indexProperty}][witness][witness_signature]`, property?.witness?.witness_signature)
          }
        } else {
          return null
        }
      });
      (data?.minor || []).map((minor: any, indexMinor: number) => {
        if (minor?.file) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          formData.append(`${elementAppend}[minor][${indexMinor}][file]`, minor?.file)
          formData.append(`${elementAppend}[minor][${indexMinor}][minor][minor_name]`, minor?.name_minor)
        } else if (!areObjectValuesEmpty(minor?.minor) || !areObjectValuesEmpty(minor?.parent)) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          Object.keys(minor?.photographer).map((key?: string) => {
            formData.append(`${elementAppend}[minor][${indexMinor}][photographer][${key}]`, key === 'photographer_date_signed' ? moment(minor?.photographer?.[key]).format('YYYY-MM-DD') : key ? minor?.photographer?.[key] : '')
          })
          Object.keys(minor?.minor).map((key?: string) => {
            formData.append(`${elementAppend}[minor][${indexMinor}][minor][${key}]`, key === 'minor_dob' ? moment(minor?.minor?.[key]).format('YYYY-MM-DD') : key ? minor?.minor?.[key] : '')
          })
          Object.keys(minor?.parent).map((key?: string) => {
            formData.append(`${elementAppend}[minor][${indexMinor}][parent][${key}]`, key === 'parent_date_signed' ? moment(minor?.parent?.[key]).format('YYYY-MM-DD') : key ? minor?.parent?.[key] : '')
          })
          if (minor?.witness?.witness_name) {
            formData.append(`${elementAppend}[minor][${indexMinor}][witness][witness_name]`, minor?.witness?.witness_name)
            formData.append(`${elementAppend}[minor][${indexMinor}][witness][witness_date_signed]`, moment(minor?.witness?.witness_date_signed).format('YYYY-MM-DD'))
            formData.append(`${elementAppend}[minor][${indexMinor}][witness][witness_signature]`, minor?.witness?.witness_signature)
          }
        } else {
          return null
        }
      });
      (data?.model || []).map((model: any, indexModel: number) => {
        if (model?.file) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          formData.append(`${elementAppend}[model][${indexModel}][file]`, model?.file)
          formData.append(`${elementAppend}[model][${indexModel}][model][model_name]`, model?.name_model)
        } else if (!areObjectValuesEmpty(model?.model) || !areObjectValuesEmpty(model?.witness)) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          if (!areObjectValuesEmpty(model?.model)) {
            Object.keys(model?.model).map((key: string) => {
              if (model?.model?.[key]) {
                formData.append(`${elementAppend}[model][${indexModel}][model][${key}]`, (key === 'model_dob' || key === 'model_date_signed') ? moment(model?.model?.[key]).format('YYYY-MM-DD') : model?.model?.[key])
              }
            })
          }
          if (!areObjectValuesEmpty(model?.witness)) {
            Object.keys(model?.witness).map((key: string) => {
              formData.append(`${elementAppend}[model][${indexModel}][witness][${key}]`, key === 'witness_date_signed' ? moment(model?.witness_date_signed?.[key]).format('YYYY-MM-DD') : model?.witness?.[key])
            })
          }
          Object.keys(model?.photographer).map((key: string) => {
            formData.append(`${elementAppend}[model][${indexModel}][photographer][${key}]`, key === 'photographer_date_signed' ? moment(model?.photographer?.[key]).format('YYYY-MM-DD') : model?.photographer?.[key])
          })
        } else {
          return null
        }
      })
    })
    return formData
  }
  const saveToLicensing = async () => {
    setModalSaveLicensing(false)
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>' // Set the Content-Type header for FormData
      }
    }
    const formDataSave = new FormData()
    const formData = handleSaveFormData(getValues(), formDataSave)
    const allowAi = allowAIGeneration ? 1 : 0
    formData.append(`can_used_for_ai`, allowAi.toString())
    formData.append('nextStep', JSON.stringify(1))
    try {
      const saveData = await instance.post(
        `/session/upload`,
        formData,
        config
      ) as any
      if (saveData?.status === 'error') {
        Notiflix.Report.failure(
          'Error',
          `${saveData?.message}`,
          'OK'
        )
      } else {
        setValue('images', [])
        dispatch(setIsExamination(saveData?.data?.inExamination))
        const response = await instance.get('user/profile')
        dispatch(setUserData({...response.data}))
        Notiflix.Notify.success(t('Success'))

      }
      if (onChangeSections) {
        onChangeSections('PUBLICATION')
      }
      await sessionRetrieve()
    } catch (error: any) {
      Notiflix.Report.failure(
        'Error',
        'Failed to update images. Please try again later.',
        'OK'
      )
    }
  }
  const handleSaveReleases = async () => {
    setModalSave(!modalSave)
  }
  const handleSendReleases = async () => {
    if (!termsAndConditionsChecked) {
      setErrorFeedback(
        t('Allow AI Generation and Terms & Conditions need to be checked.')
      )
      return
    } else {
      setModalSaveLicensing(!modalSaveLicensing)
    }
  }

  const sessionRetrieve = async () => {
    try {
      const res = await instance.get('session/retrieve')
      if (!res?.data?.step) {
        if (setCurrentStep) {
          setCurrentStep(1)
        }
        dispatch(setUploadSession(res.data))
      }
      else {
        dispatch(setUploadSession(res.data))
      }
    } catch (error: any) {
      Notiflix.Report.failure(
        'Error',
        'Failed to update images. Please try again later.',
        'OK'
      )
    }

  }
  return (
    <>
      <ConfirmDialog
        open={openDeleteAllDialog}
        handleOpen={handleOpen}
        handleConfirm={handleDeleteAll}
        dialogMessage={t('Are you sure to delete all of the images')}
        dialogTitle={t('Delete All Images')}
      />
      <form onSubmit={handleSubmit(handleSaveReleases)}>
        <div>
          <div className={styles.titleContainer}>
            <span className={styles.step}>{t('Step', { step: 4 })}</span>
            <span className={styles.title}>
            {t('Property and Model Realeases')}
          </span>
          </div>
          <div className={styles.description}>{t('Step4_description')}</div>
          <br/>
          <Alert severity='info'>{t('The images can be sent to licensing separately')}</Alert>

          <div className={styles.topElementsContainer}>
            {uploadSession?.images.map((element, index) => (
              <div key={element.imageId} className={styles.element}>
                <PublicationImageCard
                  image={element.thumbnail}
                  imageId={element.imageId}
                  name={element?.originalFilename}
                  canBeDeleted={accountInfo?.displayStatus?.toLowerCase() === VALID}
                  onDeleteImage={handleDeleteImage}
                />
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                  <Button
                    disabled={getValues()?.images?.length && (getValues()?.images || []).find((el: any) => el?.imageId !== element?.imageId)}
                    variant={(getValues()?.images || []).find((el: any) => el?.imageId === element?.imageId) ? 'contained' : 'outlined'}
                    size='small'
                    onClick={() => handleAddtoSelection(element.imageId)}
                  >
                    {(getValues()?.images || []).find((el: any) => el?.imageId === element?.imageId) ? t('remove_selection') : t('Add to selection')}
                  </Button>
                </Box>
              </div>
            ))}
          </div>
          <Tabs sx={{paddingTop: 2}} value={valueTab} onChange={(event: React.SyntheticEvent, newValue: number) => setValueTab(newValue)} aria-label="basic tabs example">
            <Tab label="Unsaved Images" value={0} />
            <Tab disabled={!uploadSession?.savedImages?.length} label={`Images saved (${uploadSession?.savedImages?.length})`}  value={1} />
          </Tabs>
          <CustomTabPanel value={Number(valueTab)} indexData={0}>
            <div className={styles.containerStepFourAlerts}>
              {(uploadSession?.images || []).filter((img: any) => img.isAdult === 1)?.length 
                ? (<Alert severity='warning'>
                    {t('info_step4_images_adult', {
                      files: ((uploadSession?.images || []).filter((img: any) => img.isAdult === 1) ||[]).map((d: any) => `${d.originalFilename}`).join(', '),
                    })}
                  </Alert>) 
                : null
              }
              {(uploadSession?.images || []).filter((img: any) => img.isAi === 1)?.length 
                ? (
                  <>
                    <Alert severity='warning'>
                      {t('info_step4_images_ai', {
                        files: ((uploadSession?.images || []).filter((img: any) => img.isAi === 1) ||[]).map((d: any) => `${d.originalFilename}`).join(', '),
                      })}
                    </Alert>
                    <Alert severity='warning'>
                      {t('info_step4_images_ai_second')}
                    </Alert>
                  </>
                ) 
                : null
              }
            </div>
            {imagesFields.map((imageField: any, index: number) => (
              <div key={index} className={styles.elementContainer}>
                <ImageFieldArray
                  imageField={imageField}
                  control={control}
                  index={index}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  addedToSelectionElements={addedToSelectionElements}
                  releases={releases}
                  setReleases={setReleases} 
                />
              </div>
            ))}
            <div className={styles.bottom}>
              <FormControl component='fieldset'>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={allowAIGeneration}
                      onChange={(e: any) => setAllowAIGeneration(e.target.checked)}
                    />
                  }
                  label={t('I allow the use in AI generation')}
                  className={styles.bottomCheckbox}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={termsAndConditionsChecked}
                      onChange={(e: any) =>
                        setTermsAndConditionsChecked(e.target.checked)
                      }
                    />
                  }
                  label={(
                    <Typography>
                      <a href='https://www.google.ro/' target='_blank'>{t('Carefully read Terms & Conditions')}</a>
                    </Typography>)
                  }
                  className={styles.bottomCheckbox}
                />
              </FormControl>
              <Typography color={'red'}>{errorFeedback}</Typography>
              {imageSelected?.imageId && <Button
                type='submit'
                // onClick={handleSendReleases}
                className={styles.toLicensingButton}
              >
                {t('image_to_licensing')}
              </Button>}
              <div className={styles.bottomButtons}>
                {/*<Button className={styles.outsideButton} onClick={handleSaveAll}>{t('Save All')}</Button>*/}
                <Button className={styles.outsideButton} onClick={handleOpen}>
                  {t('Delete All')}
                </Button>
              </div>
            </div>
            <br />
            {errors?.images && <Alert severity='warning'>
              {t('releases_required')}
            </Alert>}
            <Button
              onClick={handleSendReleases}
              className={styles.toLicensingButton}
            >
              {t('all_images_to_licensing')}
            </Button>
          </CustomTabPanel>
          <CustomTabPanel  value={Number(valueTab)} indexData={1}>
            <div className={styles.elements}>
              <TableContainer component={Paper} className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow className={styles.tableRow}>
                      <TableCell>{t('Image')}</TableCell>
                      <TableCell align='left'>{t('Image ID')}</TableCell>
                      <TableCell align='left'>{t('Releases')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(uploadSession?.savedImages || []).map((image: any) => (
                      <TableRow key={image?.id} className={styles.tableRow}>
                        <TableCell component='th' scope='row'>
                          <Avatar
                            sx={{ width: 60, height: 60 }}
                            alt={image?.title}
                            variant='rounded'
                            src={image?.thumbnail}
                          />
                        </TableCell>
                        <TableCell align='left'>{image?.imageId}</TableCell>
                        <TableCell align='left'>
                          {
                            <ModelReleaseList
                              releases={image?.releases || []}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </div>
      </form>
      <ConfirmDialog
        open={modalSaveLicensing}
        handleOpen={handleSendReleases}
        handleConfirm={saveToLicensing}
        dialogMessage={t('Do you want to send all images to licensing?')}
        dialogTitle={t('Licensing')}
      />
      <ConfirmDialog
        open={modalSave}
        handleOpen={handleSaveReleases}
        handleConfirm={handleSubmitForm}
        dialogMessage={t('Do you want to send this image for licensing?')}
        dialogTitle={t('Licensing')}
      />
    </>
  )
}

