import { useTranslation } from 'react-i18next'
import styles from '../authentication/Register.module.scss'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { CustomPasswordField } from '../../../shared-components/textfield/CustomPasswordField'
import React, { useState } from 'react'
import { Recaptcha } from '../../../shared-components/recaptcha/Recaptcha'
import { Link, useParams } from 'react-router-dom'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import instance from '../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { useCustomNavigation } from '../../../../hooks/useCustomNavigate'
import { NavigationEnum, ROUTES } from '../../../../utils/constants'
import InputCode from '../../../shared-components/inputCode'


export const ResetPassword = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const navigate = useCustomNavigation()
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validationNumber, setValidationNumber] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorFeedback, setErrorFeedback] = useState({ message: '', name: '' })
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false)


  const resetPassword = async () => {
    if (!isCaptchaOpen) {
      setErrorFeedback({ message: t('Captcha is required'), name: 'captcha' })
      setIsLoading(false)
      return
    }
    if (!password || !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{6,}$/.test(password)) {
      setErrorFeedback({ message: t('password_message_validator'), name: 'password' })
      return
    }
    if (!confirmPassword || !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{6,}$/.test(confirmPassword)) {
      setErrorFeedback({ message: t('password_message_validator'), name: 'confirmPassword' })
      return
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setErrorFeedback({ message: t('Invalid email address'), name: 'email' })
      return
    }
    if (!validationNumber || validationNumber?.length !== 6) {
      setErrorFeedback({ message: t('validation_number_required'), name: 'validationNumber' })
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    try {
      if (password !== confirmPassword) {
        setErrorFeedback({
          message: t('Passwords do not match'),
          name: 'confirmPassword'
        })
        return
      } else {
        const response = await instance.post(`auth/reset-password/${token}`, {
          password,
          email,
          password_confirmation: confirmPassword,
          code: validationNumber
        }) as any;
        if (response.status === ('success' as unknown as number)) {
          Notiflix.Report.success(t('reset_password_successfully'), t(''), t('OK'))
          setErrorFeedback({
            message: '',
            name: ''
          })
          navigate('/login')
        } else {
          setErrorFeedback({ message: response.message, name: 'apiError' })
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        setErrorFeedback({
          message: error.response.data.message,
          name: 'apiError',
        })
      } else {
        setErrorFeedback({
          message: t('error_during_reset_password'),
          name: 'apiError',
        })
      }
    }
    finally {
      setIsLoading(false)
    }
  }
  const onChangeVerification = (value: any) => {
    setValidationNumber(value)
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <Typography className={styles.welcome}>{t('password_reset')} </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.gridContent}>
              <CustomTextfield
                variant='outlined'
                error={errorFeedback.name === 'email'}
                label={t('Email')}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
              <br/>
              <CustomPasswordField
                label={t('new_password')}
                error={errorFeedback.name === 'password'}
                value={password}
                changePassword={setPassword}
              />
              <br />
              <CustomPasswordField
                label={t('Confirm password')}
                error={errorFeedback.name === 'confirmPassword'}
                value={confirmPassword}
                changePassword={setConfirmPassword}
              />
              <Typography fontSize={12}>{t('password_message_validator')}</Typography>
            </div>
            <br />
            <div className={styles.gridContent}>
              <InputCode classLabel="text-sm"
                         label={t('code_verify')}
                         onGetCode={(newCode: string) => onChangeVerification(newCode)}/>

              <Recaptcha isOpen={isCaptchaOpen} setIsOpen={setIsCaptchaOpen} />
            </div>
            <br/>
          </Box>
          <Typography variant='body2' color={'red'}>
            {errorFeedback.message}
          </Typography>
          <br/>
          <Typography className={styles.text}>
            <Link to={NavigationEnum.FORGOT_PASSWORD} className={styles.linkText}>
              {t('Forgot password')}
            </Link>
          </Typography>
          <Button
            onClick={!isLoading ? resetPassword : undefined}
            className={styles.button}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress /> : t('password_reset')}
          </Button>
        </div>
      </div>
    </>
  )
}
