import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import styles from './CreateNewLightboxDialog.module.scss'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: (key: string, key1: string) => void
  dialogTitle: string
}

export const CreateNewLightboxDialog = (props: IProps) => {
  const { open, handleOpen, handleConfirm, dialogTitle } = props

  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  return (
    <Dialog open={open} onClose={handleOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <CustomTextfield
            size='small'
            name='title'
            variant='outlined'
            label={t('Title')}
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
          />

          <CustomTextfield
            size='small'
            name='title'
            variant='outlined'
            label={t('Description')}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} variant="outlined">
          {t('Cancel')}
        </Button>
        <Button onClick={() => handleConfirm(title, description)} disabled={!description && !title} variant="contained" color="primary">
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
