import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { NavigationEnum } from '../../../utils/constants'
import styles from './MobileDrawer.module.scss'

interface IProps {
  items: Array<any>
  open: boolean
  handleDrawerToggle: Function
}

const drawerWidth = 200

export const MobileDrawer = (props: IProps) => {
  const { items, open, handleDrawerToggle } = props
  const { t } = useTranslation()
  const customNavigate = useCustomNavigation()

  const [selectedButton, setSelectedButton] = useState(1)

  const handleNavigate = (to: NavigationEnum) => {
    customNavigate(to)
    handleDrawerToggle()
  }

  const handleSubItemPressed = (value: string | number, to: NavigationEnum) => {
    to !== NavigationEnum.NOWHERE && handleNavigate(to)
  }

  return (
    <Drawer
      variant='temporary'
      open={open}
      onClose={() => handleDrawerToggle()}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
    >
      <Box className={styles.drawerContainer}>
        <Box>
          <List>
            {items.map((item) => (
              <Fragment key={item.title}>
                <ListItem disablePadding>
                  <ListItemButton
                    className={
                      selectedButton === item.value
                        ? styles.buttonSelected
                        : styles.button
                    }
                    onClick={() => {
                      if (item.to !== NavigationEnum.NOWHERE) {
                        handleNavigate(item.to)
                      } else {
                        setSelectedButton(item.value)
                      }
                    }}
                  >
                    <ListItemText primary={item.title} sx={{textAlign: 'left'}}/>
                  </ListItemButton>
                </ListItem>
                {selectedButton === item.value && item.listOfItems && (
                  <List>
                    {item.listOfItems.map((subItem: any) => (
                      <ListItemButton
                        key={subItem.value}
                        className={styles.subItemButton}
                        onClick={() =>
                          handleSubItemPressed(subItem.value, subItem.to)
                        }
                      >
                        <ListItemText primary={'• ' + subItem.name} sx={{textAlign: 'left'}}/>
                      </ListItemButton>
                    ))}
                  </List>
                )}
              </Fragment>
            ))}
          </List>
        </Box>

        <Divider className={styles.divider} />

        <Box>
          <List>
            <ListItem disablePadding>
              <ListItemButton className={styles.button}>
                <ListItemText primary={t('Plans & Prices')} sx={{textAlign: 'left'}}/>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton className={styles.buttonMobileLogout} sx={{color: '#fff'}}>
                <PersonIcon fontSize='small' />
                {t('Login')}
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Drawer>
  )
}
