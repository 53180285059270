import { Box, Container } from '@mui/material'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import styles from './ImagesLicensingList.module.scss'
import { useEffect, useState } from 'react'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import moment from 'moment/moment'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import SkeletonImageWithPagination from '../../../../../../shared-components/skeleton/imageWithPagination'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import { ROUTES, VALID } from '../../../../../../../utils/constants'
import { useCustomNavigation } from '../../../../../../../hooks/useCustomNavigate'

type imageProps = {
  thumbURL: string,
  id: string | number,
  createdAt: moment.Moment,
  height?: number;
  width?: number;
}

interface IProps {
  handleOpenDetails?: any
}

export const UnfinishedImagesList = (props: IProps) => {
  const { handleOpenDetails } = props
  const customNavigate = useCustomNavigation()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [unfinishedImages, setUnfinishedImages] = useState<imageProps[]>([])
  const account = useSelector((state: RootState) => state.user.usersData)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 1
  })

  const getUnfinishedImages = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/images/pending?limit=30&page=${pagination.currentPage}`)
      setUnfinishedImages(res?.data?.images || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getUnfinishedImages()
  }, [])

  const handleRedirect = () => {
    customNavigate(`/${ROUTES.ACCOUNT}/${ROUTES.UPLOAD}`)
  }

  const handleChange = (event: any, value: any) => {
    setPagination({ ...pagination, currentPage: value })
  }
  const handleDeleteImage= async (id: any) => {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' })
        // const findImage = unfinishedImages.find((image) => image.id === id) as any;
        const resp = await instance.delete(`session/delete?images[0]=${id}`)
        if ((resp as any).status === 'success') {
          const deleteImage = unfinishedImages.filter((image) => image.id !== id) as any;
          setUnfinishedImages([...deleteImage])
        }
      } catch (error) {
        // Handle error
        console.error('Error delete files:', error)
      } finally {
        Notiflix.Loading.remove()
      }
  }
  return (
    <Container maxWidth={false}>
      <Box className={styles.container}>
        {!isLoading && !unfinishedImages?.length ? <>{t('no_unfinished_images')}</> : null}
        {isLoading ? <SkeletonImageWithPagination /> : <>
          {unfinishedImages.map((item: imageProps) => (
            <PublicationImageCard
              key={item?.id}
              height={item?.height}
              isLoading={isLoading}
              imageId={item?.id}
              image={item?.thumbURL}
              canBeDeleted={account?.status === VALID}
              handleOpenDetails={handleRedirect}
              onDeleteImage={handleDeleteImage}
            />
          ))}
        </>}
      </Box>
      {pagination?.totalPages > 1 && <Box>
          <CustomPagination
            shape='rounded'
            count={pagination?.totalPages}
            page={pagination?.currentPage}
            onChange={handleChange}
            size='large'
            className={styles.pagination}
          />
      </Box>}
    </Container>
  )
}
