import { Button, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import instance from '../../../../providers/axios.config'
import { NavigationEnum, ROUTES } from '../../../../utils/constants'
import { Recaptcha } from '../../../shared-components/recaptcha/Recaptcha'
import { CustomPasswordField } from '../../../shared-components/textfield/CustomPasswordField'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import styles from './Login.module.scss'
import { useCustomNavigation } from '../../../../hooks/useCustomNavigate'
import useAuthentication from '../../../../hooks/useAuthentication'
import { LoadingButton } from '@mui/lab'

export const Login = () => {
  const { t } = useTranslation()
  const { isAuthenticated, isLoading } = useAuthentication()
  const customNavigate = useCustomNavigation()
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorFeedback, setErrorFeedback] = useState({ message: '', name: '' })
  const [isLoadingState, setIsLoadingState] = useState(false)

  const handleLogin = async () => {
    setIsLoadingState(true)
    try {
      if (!isCaptchaOpen) {
        setErrorFeedback({ message: t('Captcha is required'), name: 'captcha' })
        setIsLoadingState(false)
        return
      }
      // Reset previous error feedback
      setErrorFeedback({ message: '', name: '' })

      // Check if any required field is empty
      const requiredFields = ['username', 'password']
      for (const fieldName of requiredFields) {
        const formattedFieldName =
          fieldName.charAt(0).toLowerCase() + fieldName.slice(1)
        if (!eval(fieldName)) {
          setErrorFeedback({
            message: `${formattedFieldName} is required.`,
            name: formattedFieldName,
          })
          return
        }
      }

      // API call to handle login
      const response = await instance.post('auth/login', {
        username,
        password,
      }) as any;

      // Handle the API response (success)
      if (response.status === ('success' as unknown as number)) {
        // Save the token to localStorage
        localStorage.setItem('authToken', response.data.token)
        customNavigate(`/${ROUTES.ACCOUNT}`)
        // Redirect or show success message to the user
      } else {
        setErrorFeedback({ message: response.message, name: 'apiError' })
      }
    } catch (error: any) {
      console.error('Login failed:', error)

      if (error.response && error.response.data) {
        setErrorFeedback({
          message: error.response.data.message,
          name: 'apiError',
        })
      } else {
        setErrorFeedback({
          message: 'An error occurred during login',
          name: 'apiError',
        })
      }
    } finally {
      setIsLoadingState(false)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography className={styles.welcome}>{t('Sign in')} </Typography>
        <CustomTextfield
          variant='outlined'
          label={t('Username')}
          className={styles.textField}
          value={username}
          error={errorFeedback.name === 'username'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUsername(e.target.value)
          }
        />
        <CustomPasswordField
          label={t('Password')}
          value={password}
          changePassword={setPassword}
          error={errorFeedback.name === 'password'}
        />
        <Typography
          maxWidth={304}
          textAlign='center'
          variant='body2'
          color={'red'}
        >
          {errorFeedback.message}
        </Typography>
        <Recaptcha isOpen={isCaptchaOpen} setIsOpen={setIsCaptchaOpen} />

        <Typography className={styles.text}>
          <Link to={NavigationEnum.FORGOT_PASSWORD} className={styles.linkText}>
            {t('Forgot password')}
          </Link>
        </Typography>

        <LoadingButton
          onClick={handleLogin}
          loading={isLoadingState}
          endIcon={<></>}
          loadingPosition="end"
          variant="contained"
          className={styles.button}
        >
          { t('Sign in')}
        </LoadingButton>

        <div className={styles.no_account}>
          <Typography>{t('No account')}</Typography>
          <Typography>
            <Link to={NavigationEnum.REGISTER} className={styles.linkText}>
              {t('Sign up')}
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}
