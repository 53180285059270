import styles from '../../main-components/content/asset-details/AssetDetails.module.scss'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Notiflix from 'notiflix'
import { useEffect, useState } from 'react'
import instance from '../../../providers/axios.config'
import { typeImageForDownload } from '../../main-components/content/asset-details/AssetDetails'
import { DialogSuccessPaymentImage } from '../dialogSuccessPaymentImage'
import { DialogCancelPaymentImage } from '../dislogCancelPayment'
import { DialogFastDownload } from '../dialogFastDownloadGuest'
import { BASE_URL } from '../../../utils/constants'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

export const FastDownload = (props: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { assetName } = useParams()
  const {sizeSelected, assetId, imageInformation, disabledButton} = props;
  const { search } = useLocation()
  const user = useSelector((state: RootState) => state.user.usersData)
  const data = new URLSearchParams(search)
  const [openSuccessPayment, setOpenSuccessPayment] = useState(false)
  const [openCancelPayment, setOpenCancelPayment] = useState(false)
  const [openModalFastDownload, setOpenFastDownload] = useState(false)

  useEffect(() => {
    if (data.get('session_id') && data?.get('size')) {
      const category = data?.get('category')
      const splitData = category?.split('?')
      if (splitData && splitData.length && splitData[1] === 'success') {
        setOpenSuccessPayment(true)
      } else if (splitData && splitData.length && splitData[1] === 'cancel') {
        setOpenCancelPayment(true)
      }

    }
  }, [data.get('session_id')])


  const downloadImageFree = async (typeDownload: string) => {
    const response = await instance.get(`images/${assetId}/download?image_size_id=${sizeSelected?.id?.toString()}&category=${sizeSelected?.type}&method=${typeDownload}`, { responseType: 'arraybuffer' }) as any
    let blob = new Blob([response], { type: `image/${typeImageForDownload(sizeSelected?.type)}` }),
      url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${imageInformation?.title}.${sizeSelected?.type}`)
    document.body.appendChild(link)
    link.click()
  }


  const handleFastDownload = async () => {
    if (sizeSelected.price === 0) {
      await downloadImageFree('fast-download')
    } else {

      const params = {
        plan: 'fast-download',
        category: sizeSelected?.type || '',
        size: sizeSelected?.id?.toString()
      }
      // @ts-ignore
      navigate({
        pathname: `/assets/${assetName}/buy`,
        search: `?${new URLSearchParams(params).toString()}`
      })
    }
  }

  const handleCloseFastDownload = () => {
    setOpenFastDownload(false)
  }
  const handleCloseSuccessPayment = () => {
    setOpenSuccessPayment(false)
  }
  const handleCloseCancelPayment= () => {
    setOpenCancelPayment(false)
  }

  const handleSubmitPayment = async (data: any) => {

    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const dataPayloadBilling = {
        name: `${data?.firstName} ${data?.lastName}`,
        phone_number: data?.phone_number,
        email: data?.email,
        address: data?.address,
        city: data?.city,
        zip: data?.zip,
        country_id: data?.country,
        state: data?.state,
        vat_number: data?.vatNumber
      }
      const dataPayload = {
        url: `${BASE_URL}assets/${assetName}?size=${sizeSelected.id}&category=${sizeSelected?.type}`,
        image_id: assetId,
        payment_method: data?.payment,
        image_size_id: sizeSelected?.id?.toString(),
        category: sizeSelected?.type,
        method: 'fast-download',
        billing_address: dataPayloadBilling,
      }
      const dataEditPaylod = {
        url: `${BASE_URL}assets/${assetName}?size=${sizeSelected.id}&category=${sizeSelected?.type}`,
        image_id: Number(assetId),
        image_size_id: Number(sizeSelected?.id),
        payment_method: data?.payment,
        category: sizeSelected?.type
      }
      if(user && data?.edit) {
        await instance.put(`user/profile`, JSON.stringify({...dataPayloadBilling, first_name: data?.firstName, last_name: data?.lastName }))
      }
      const response = await instance.post(`subscriptions/fast-download`, data?.edit ? { ...dataPayload } :{ ...dataEditPaylod }) as any;
      setOpenFastDownload(false)
      if(data.payment === 'paypal' && response?.links?.length) {
        window.open(response?.links[1].href, '_self')
      }
      if (response && typeof response === 'string' && data?.payment === 'stripe') {
        window.open(response, '_self')
      }
      Notiflix.Loading.remove()
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }


  return (
    <>
    <Button
      disabled={disabledButton}
      variant='contained'
      color="primary"
      onClick={handleFastDownload}
      className={styles.fastDownloadButton}
      // sx={{
      //   backgroundColor: disabledButton ? 'gray' : 'initial',
      //   '&:hover': {
      //     backgroundColor: '#c52d2f'
      //   }
      // }}
    >
      {sizeSelected?.price === 0 ? t('free_fast_download') :  t('Fast Download')}
    </Button>
      {openSuccessPayment ? <DialogSuccessPaymentImage open={openSuccessPayment} handleClose={handleCloseSuccessPayment}
                                                       dataPayload={{
                                                         image_id: Number(assetId),
                                                         method: 'fast-download',
                                                       }} /> : null}
      {openCancelPayment? <DialogCancelPaymentImage open={openCancelPayment} handleClose={handleCloseCancelPayment} /> : null}
      {openModalFastDownload ? <DialogFastDownload open={openModalFastDownload} handleOpen={handleCloseFastDownload}
                                                   handleConfirm={handleSubmitPayment} /> : null}
      </>
  )
}

export default FastDownload;
