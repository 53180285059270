import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import styles from './Statistics.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import Skeleton from '@mui/material/Skeleton'

type headerStatisticsType = {
  total_downloads?: number,
  total_earnings?: string,
  total_unpaid_earnings?: string,
}
type statisticType = {
  downloads?: number,
  earnings?: string,
  id?: number,
  thumb?: string,
  upload_date?: Date
}
export const Statistics = () => {
  const { t } = useTranslation()

  const [date, setDate] = useState()
  const [selectedValueMonth, setSelectedValueMonth] = useState('12')
  const [isLoading, setIsLoading] = useState(false)
  const [statistics, setStatistics] = useState<statisticType[]>([])
  const [headerStatistics, setHeaderStatistics] = useState<headerStatisticsType>({})
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })
  const handleChange = (event: any, value: any) => {
    setPagination(value)
  }
  const getMyStatistics= async () => {
    setIsLoading(true)
    try {
      const constructUrlDate = date ? `&filters[date]=${moment(date).format('MM.YYYY')}` : '';
      const res = await instance.get(`seller/statistics?limit=10&page=${pagination.currentPage}&filters[months]=${selectedValueMonth}${constructUrlDate}`)
      setStatistics(res?.data?.list)
      setHeaderStatistics({total_downloads: res?.data?.total_downloads,
        total_earnings: res?.data?.total_earnings,
        total_unpaid_earnings: res?.data?.total_unpaid_earnings,})
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getMyStatistics()
  }, [pagination?.currentPage, selectedValueMonth])

  if(!isLoading && !statistics?.length){
    return (
      <div className={styles.totalStatsContainer}>
        <Typography>{t('no_statistics')}</Typography>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.totalStatsContainer}>
        <span>
          <span className={styles.text}>{t('Total Downloads')}</span>
          {isLoading ? <Skeleton /> : <span className={styles.boldText}>{headerStatistics?.total_downloads}</span>}
        </span>
        <span>
          <span className={styles.text}>{t('Total Earnings')}</span>
          {isLoading ? <Skeleton /> :<span className={styles.boldText}>{headerStatistics?.total_earnings}</span>}
        </span>
        <span>
          <span className={styles.text}>{t('Unpaid Earnings')}</span>
          {isLoading ? <Skeleton /> :<span className={styles.boldText}>{headerStatistics?.total_unpaid_earnings}</span>}
        </span>
      </div>
      <div className={styles.filtersContainer}>
        <CustomTextfield
          select
          label={t('Number of months')}
          variant='outlined'
          value={selectedValueMonth}
          onChange={(e: any) => setSelectedValueMonth(e.target.value)}
        >
          <MenuItem value={'12'}>{t('Year')}</MenuItem>
          <MenuItem value={'3'}>{t('Months', { month: 3 })}</MenuItem>
          <MenuItem value={'6'}>{t('Months', { month: 6 })}</MenuItem>
        </CustomTextfield>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className={styles.textfield}
            label={t('Date')}
            value={date}
            format='MM-yyyy'
            views={['month', 'year']}
            onChange={(newValue: any) => {
              setDate(newValue)
            }}
            slots={{
              textField: CustomTextfield,
            }}
            slotProps={{
              textField: { size: 'small' },
            }}
          />
        </LocalizationProvider>
      </div>

      <div className={styles.table}>
        <TableContainer component={Paper} className={styles.table}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableRow}>
                <TableCell />
                <TableCell align='center'>{t('Upload Date')}</TableCell>
                <TableCell align='center'>
                  {t('Total Image Downloads')}
                </TableCell>
                <TableCell align='center'>
                  {t('Total Image Earnings')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? <>
                {[1, 2, 3, 4, 5].map((number) => (
                  <TableRow key={number} className={styles.tableRow}>
                    <TableCell>
                    </TableCell>
                    <TableCell align='center'>
                      <Skeleton />
                    </TableCell>
                    <TableCell align='center'> <Skeleton /></TableCell>
                    <TableCell align='center'> <Skeleton /></TableCell>
                  </TableRow>
                ))}
              </> : <>
                {
                  (statistics || []).map((statistic) =>
                    <TableRow key={statistic.id} className={styles.tableRow}>
                      <TableCell>
                        {/*<PublicationImageCard*/}
                        {/*  image={constants.crop_jpg}*/}
                        {/*  imageId={1234}*/}
                        {/*  canBeDeleted={false}*/}
                        {/*/>*/}
                      </TableCell>
                      <TableCell align='center'>
                        {moment(statistic?.upload_date).format('MM-DD-YYYY')}
                      </TableCell>
                      <TableCell align='center'>{statistic?.downloads}</TableCell>
                      <TableCell align='center'>{statistic?.earnings}</TableCell>
                    </TableRow>)
                }
              </>}

            </TableBody>
          </Table>
        </TableContainer>

        {pagination?.totalPages > 1 && <CustomPagination
          shape='rounded'
          count={pagination?.totalPages}
          page={pagination?.currentPage}
          onChange={handleChange}
          size='large'
          className={styles.pagination}
        />}
      </div>
    </div>
  )
}
