import { useEffect, useState } from 'react'
import styles from './Payment.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { PaymentHistoryTable } from '../../../../../../shared-components/paymentHistory/PaymentHistoryTable'
import { PaymentTaxForm } from '../../../../../../shared-components/paymentHistory/PaymentTaxForm'
import { IPaymentHistory, ITaxForm, typeResponse } from '../../../../../../../utils/types'
import { Chip } from '@mui/material'
import { APPROVED, CONFIRMED, DENIED, PAID, WAITING } from '../../../../../../../utils/constants'

export const setTypeStatus = (status: string, label: string) => {
  switch (status) {
    case WAITING:
      return <Chip label={label} sx={{ color: 'orange' }} size='small' />
    case DENIED:
      return <Chip label={label} color='primary' size='small' />
    case APPROVED:
      return <Chip label={label} sx={{ color: 'green' }} size='small' />
    case PAID:
      return <Chip label={label} sx={{ color: 'green' }} size='small' />
    case CONFIRMED:
      return <Chip label={label} sx={{ color: 'green' }} size='small' />
  }
}
interface IResponseTaxForm  extends typeResponse {
  data:{
    tax_forms: ITaxForm[],
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}
interface IResponseHistory  extends typeResponse {
  data:{
    tax_forms: IPaymentHistory[],
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}
export const PaymentHistory = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [history, setHistory] = useState<IPaymentHistory[]>([])
  const [taxForms, setTaxForms] = useState<ITaxForm[]>([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })
  const [paginationTaxForm, setPaginationTaxForm] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })
  const handleChange = (event: any, value: number) => {
    setPagination({ ...pagination, currentPage: value })
  }
  const handleChangePaginationTaxForm = (event: any, value: number) => {
    setPaginationTaxForm({ ...paginationTaxForm, currentPage: value })
  }
  const getMyTaxForm = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/tax-center?limit=${paginationTaxForm.perPage}&page=${paginationTaxForm.currentPage}`) as IResponseTaxForm;
      setTaxForms(res?.data?.tax_forms || [])
      setPaginationTaxForm({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  const getMyInvoices = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/tax-center/payment-history?limit=${pagination.perPage}&page=${pagination.currentPage}`) as IResponseHistory;
      setHistory(res?.data?.tax_forms || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    getMyInvoices()
    getMyTaxForm()
  }, [])

  const handleDownload = async (linkDownload?: string) => {
    try {
      const response = await instance.get(`${linkDownload}`, { responseType: 'arraybuffer' }) as any
      let blob = new Blob([response], { type: 'application/pdf' })
      let url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `tax-form.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (e) {

    }

  }
  return (
    <div className={styles.tableHistory}>
      <PaymentHistoryTable isLoading={isLoading} history={history} onChange={handleChange} pagination={pagination} />
      <br />
      <PaymentTaxForm isLoading={isLoading} taxForms={taxForms} onChange={handleChangePaginationTaxForm}
                      pagination={paginationTaxForm} handleDownload={handleDownload} />
    </div>
  )
}
