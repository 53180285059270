import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#c11b1e',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    caption: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#6c757d',
      '@media (max-width:600px)': {
        fontSize: '0.75rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          color: "white",
          left: '-6px',
        }
      }
    }
  }
})
