import { Button, ToggleButtonGroup } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BASE_URL, FAST_DOWNLOAD, ISubscriptionsCreditsProps } from '../../../utils/constants'
import { CustomToggleButton } from '../button/CustomToggleButton'
// import { FilterChipsList } from './FilterChipsList'
import styles from './PlanCard.module.scss'
// import i18n from '../../../i18n'
import { MethodPayment } from '../methodPayment'
import instance from '../../../providers/axios.config'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import { useNavigate } from 'react-router-dom'
import Notiflix from 'notiflix'

export const PlanCard = (props: ISubscriptionsCreditsProps) => {
  const { name, options,plans, qty, price, description, id, category, recommendedText, isCenter, type } =
    props
  // const covers = ['PNG', i18n.t('EPS'), i18n.t('JPG'), i18n.t('Royalty free')];
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated)
  const userRole = useSelector((state: RootState) => state.user.userRole)
  const showOptionPlans = userRole?.slug !== 'seller';
  const [openModalTypePayment, setOpenModalTypePayment] = useState(false);
  const [selectedOption, setSelectedOption] = useState(qty)
  const [planOptions, setPlanOptions] = useState({qty: qty || 0, price: price || 0, id: id, description: description || '' })

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelected: number
  ) => {
    if (newSelected) {
      const findPlan = plans.find((option: any) => option?.qty === newSelected)
      if(!findPlan) {
        setPlanOptions({qty: qty || 0, price: price || 0, id: id, description: description || ''})
      } else {
        setPlanOptions({...findPlan})
      }
      setSelectedOption(newSelected)
    }
  }
  const handleBuyPlan = () => {
    if(!isAuthenticated) {
      navigate('/login')
    } else {
      setOpenModalTypePayment(true)
    }
  }
  const handleCloseModal = async (payment?: string) => {

    if(payment && isAuthenticated) {
      try {
        const response = await instance.post(`subscriptions`, {plan: planOptions?.id, payment_method: payment, url: `${BASE_URL}plans-prices?plan`}) as any
        if(response && typeof response === 'string' && payment === 'stripe') {
          window.open(response,"_self")
        }
        if(payment === 'paypal' && response?.links?.length) {
          window.open(response?.links[1].href, '_self')
        }
      } catch (error: any) {
        Notiflix.Report.failure(
          'Error',
          `${error?.response?.data?.message}`,
          'OK'
        )
      }
    }
    setOpenModalTypePayment(!openModalTypePayment)
  }
  const handleCancelPayment = () => {
    setOpenModalTypePayment(!openModalTypePayment)
  }
  return (
    <>
    <MethodPayment open={openModalTypePayment} onClose={handleCancelPayment} handleOpen={handleCloseModal} handleConfirm={handleCloseModal}  dialogTitle={t('set_payment_type')} />
    <div className={styles.mainContainer}>
      <div className={`${styles.container} ${isCenter && styles.isCenter}`}>
        <div className={styles.licenceTitle}>{name}</div>
        {category !== 'Credits' && (
          <div className={styles.recommendationSection}>
            <span>{t('cover_any_png_jpg_eps_images')}</span><br />
            <span>{type === 'extended' ? t('original_size').toLowerCase() : t('any_size').toLowerCase()}</span><br />
            <span>{type === 'extended' ? t('for 3 years with Extended License') : t('Royality-free license')}</span>
          </div>
        )}
        <div className={styles.recommendationSection}>{planOptions?.description}</div>

        {/* <div className={styles.coversContainer}>
          <FilterChipsList titles={covers} />
        </div> */}

        <div className={styles.type}>
          {category === 'Credits' ? t('Credits') : t('Images')}
        </div>

        <ToggleButtonGroup
          value={selectedOption}
          exclusive
          onChange={handleChange}
          className={styles.optionsContainer}
        >
          <CustomToggleButton
            size='small'
            key={qty}
            value={qty}
          >
            {qty}
          </CustomToggleButton>
          {(plans || []).map((option: any) => (
            <CustomToggleButton
              size='small'
              key={option?.qty}
              value={option?.qty}
            >
              {option?.qty}
            </CustomToggleButton>
          ))}
        </ToggleButtonGroup>

        <div className={styles.priceContainer}>
          <div className={styles.price}>{`${planOptions?.price} EUR`}</div>
          {category === 'Subscriptions' && (
            <span className={styles.perImage}>{`${
              planOptions?.price / planOptions?.qty
            } EUR ${t('per image')}`}</span>
          )}
        </div>

        {showOptionPlans ? <>
          {type === FAST_DOWNLOAD ? <div style={{height: '56px'}} /> :
          <Button onClick={handleBuyPlan} className={styles.button}>{t('Buy now')}</Button>}
        </> : <></>}
      </div>
    </div>
      </>
  )
}
