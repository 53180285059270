import { createSlice } from '@reduxjs/toolkit'

const initialState: { lightBox: any[]; errorFeedback: string } = {
  lightBox: [],
  errorFeedback: '',
}

const lightBoxSlice = createSlice({
  name: 'lightBoxSlice',
  initialState,
  reducers: {
    setLightBox: (state, action) => {
      state.lightBox = action.payload
    },
    addLightBox: (state, action) => {
      state.lightBox.push(action.payload) // Push the file name or URL instead of the entire File object
    },
    setErrorFeedback: (state, action) => {
      state.errorFeedback = action.payload
    },
  },
})

export const { setLightBox, addLightBox, setErrorFeedback } = lightBoxSlice.actions

export default lightBoxSlice.reducer
