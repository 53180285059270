import { Box, Button, Card, CardActions, CardContent, FormControlLabel, Paper, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import { BuyFastDownload } from '../FastDownload/BuyFastDownload'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../utils/constants'

type ICardCreditSubscriptionProps = {
  imageId?: string
  planInformation?: any
  imageInformation?: any
  onDownloadImage?: () => void
  hideData?: boolean;
}
const textBold = (text?: string) => (
  <Box
    component='span'
    sx={{ fontWeight: 'bold' }}
  >
    {text}
  </Box>
)
export const CardCreditSubscription = (props: ICardCreditSubscriptionProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { imageId, planInformation, imageInformation, onDownloadImage, hideData } = props
  const [checked, setChecked] = React.useState(false)
  const onRedirectPlan = () => {
    navigate(`/${ROUTES.PLAN_PRICES}`)
  }
  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {t('stock_image_id')}: {textBold(imageId)}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {t('download_details')}: {textBold(imageInformation?.downloadDetails)}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {t('license')}: {textBold(imageInformation?.license)}
        </Typography>
        <Paper sx={{ padding: 2 }}>
          <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            Plan: {textBold(t(`${planInformation?.plan}`))}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            {t('price')}: {textBold(planInformation?.price)}
          </Typography>
          {!hideData && <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            {t('download_left')}: {textBold(planInformation?.download_left)}
          </Typography>}
          {!hideData && <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            {t('plan_expired')}: {textBold(planInformation?.expire)}
          </Typography>}
        </Paper>
        <Typography marginTop={2} sx={{ fontSize: 14 }} color='primary' gutterBottom>
          {t('Terms & Conditions')}
        </Typography>
        <Paper sx={{ padding: 2 }}>
          <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            {t('terms_conditions_image_download')}
          </Typography>
        </Paper>
        <FormControlLabel control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
                          label={t('i_agree')} />
      </CardContent>
      <CardActions>
        {hideData ? <BuyFastDownload disabled={!checked} assetId={imageId} size={imageInformation?.size}
                                     category={imageInformation.category} /> :
          <>
          {planInformation.disabled ?  <Button size='small' variant='contained' disabled={!checked}
                                               onClick={onRedirectPlan}>{t('buy_credits_subscription')}</Button> :
          <Button size='small' variant='contained' disabled={!checked}
                  onClick={onDownloadImage}>{t('download_image')}</Button>}
          </>
        }
      </CardActions>
    </Card>
  )
}