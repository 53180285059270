import CloseIcon from '@mui/icons-material/Close'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddToLightboxDialog } from '../../../main-components/content/account-details/sections/buyer/lightbox/AddToLightboxDialog'
import styles from './ImageZoomInModal.module.scss'
import Notiflix from 'notiflix'
import { BASE_URL } from '../../../../utils/constants'
import instance from '../../../../providers/axios.config'

interface IProps {
  image?: string
  open: boolean
  handleOpen: () => void
  title?: string
  showOptionsUser?: boolean
}

export const ImageZoomInModal = (props: IProps) => {
  const { image, open, handleOpen, title, showOptionsUser } = props

  const { t } = useTranslation()

  const [openAddToLightbox, setOpenAddToLightbox] = useState(false)

  const handleOpenAddToLightbox = () => {
    setOpenAddToLightbox(!openAddToLightbox)
  }

  const handleDownload = async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    // @ts-ignore
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "downloaded-file.jpg";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
    Notiflix.Loading.remove()
  }

  const handleConfirmAddToLightbox = () => {
    handleOpenAddToLightbox()
  }

  return (
    <>
      <AddToLightboxDialog
        open={openAddToLightbox}
        dialogTitle={t('Lightbox')}
        handleOpen={handleOpenAddToLightbox}
        handleConfirm={handleConfirmAddToLightbox}
      />
      <Dialog
        open={open}
        onClose={handleOpen}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '90vw',
            maxHeight: '90vh',
            width: '80vw',
            height: '80vh',
          },
        }}
      >
        <DialogContent>
          <div className={styles.container}>
            <div className={styles.buttons}>
              {showOptionsUser && <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: '#50505090',
                  '&:hover': {
                    backgroundColor: '#c11b1e90',
                  },
                }}
                onClick={() => handleDownload()}
              >
                <FileDownloadIcon sx={{ height: 30, width: 30 }} />
              </IconButton>}
              <IconButton
                onClick={handleOpen}
                sx={{
                  color: 'white',
                  backgroundColor: '#50505090',
                  '&:hover': {
                    backgroundColor: '#c11b1e90',
                  },
                }}
              >
                <CloseIcon sx={{ height: 30, width: 30 }} />
              </IconButton>
            </div>

            <div>
              <img src={image} alt='zoom' className={styles.image} />
            </div>

            <div className={styles.titleContainer}>
              <div className={styles.titleContent}>{title}</div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
