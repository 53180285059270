import { useTranslation } from 'react-i18next'
import { constants } from '../../../../utils/constants'
import { AnimationContainer } from '../../../shared-components/AnimationContainer'
import styles from './LicensesSection.module.scss'

export const LicensesSection = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <AnimationContainer animationStyle={styles.animate}>
        <h1 className={styles.title}>{t('Licenses_Formats')}</h1>
      </AnimationContainer>
      <AnimationContainer animationStyle={styles.animate}>
        <div className={styles.description}>
          {t('Licenses_Formats_Description')}
        </div>
      </AnimationContainer>

      <div className={styles.imageContainer}>
        <AnimationContainer animationStyle={styles.animate}>
          <div>
            <img src={constants.crop_png} alt="" />
            <div className={styles.text}>{t('PNG file format')}</div>
          </div>
        </AnimationContainer>

        <AnimationContainer animationStyle={styles.animate}>
          <div>
            <img src={constants.crop_eps} alt="" />
            <div className={styles.text}>{t('EPS file format')}</div>
          </div>
        </AnimationContainer>

        <AnimationContainer animationStyle={styles.animate}>
          <div>
            <img src={constants.crop_jpg} alt="" />
            <div className={styles.text}>{t('JPG file format')}</div>
          </div>
        </AnimationContainer>
      </div>
    </div>
  )
}
