import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, ClickAwayListener, Popper, Typography } from '@mui/material'
import { ReactNode, useState } from 'react'
import styles from './FilterCard.module.scss'

interface IProps {
  summary: string
  children: ReactNode
}

export const FilterCard = (props: IProps) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <div className={styles.container}>
      <Button
        onClick={handleClick}
        endIcon={
          !open ? (
            <ExpandMoreIcon sx={{ color: "#c11b1e" }} />
          ) : (
            <ExpandLessIcon sx={{ color: "#c11b1e" }} />
          )
        }
        className={styles.button}
      >
        <Typography className={styles.summary}>{props.summary}</Typography>
      </Button>
      <Popper
        sx={{zIndex: 1200}}
        id={id}
        open={open}
        anchorEl={anchorEl}
        className={styles.details}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>{props.children}</div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}
