import styles from './Downloads.module.scss'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

type CommentProps = {
  idDownload: number | null,
  comment?: string,
  onSaveComment: (key1: string | null, key2: number | null) => void
}

export const Comment = (props: CommentProps) => {
  const {onSaveComment, idDownload, comment} = props;
  const { t } = useTranslation()
  const [commentState, setCommentState] = useState(comment || '')

  const handleChangeComment = (ev: React.ChangeEvent<HTMLInputElement>) =>{
    setCommentState(ev.target.value)
  }
  const handleSaveComment = () => {
    if(onSaveComment) {
      onSaveComment(commentState, idDownload)
    }
  }

  return (
    <div className={styles.cellContainer}>
      <CustomTextfield
        onChange={handleChangeComment}
        className={styles.searchBar}
        size='small'
        multiline
        value={commentState}
        inputProps={{
          maxlength: 250
        }}
      />

      <Button onClick={handleSaveComment} variant='contained' className={styles.button}>
        {t('Save')}
      </Button>
      </div>
  )
}

export default Comment;