import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { Title } from '../../Title'
import styles from './Invoices.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import Skeleton from '@mui/material/Skeleton'
import { Iinvoice, typeResponse } from '../../../../../../../utils/types'

interface IInvoicesResponse extends typeResponse {
  data:{
    invoices: Iinvoice[],
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}

export const Invoices = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [invoices, setInvoices] = useState<Iinvoice[]>([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })
  const handleChange = (event: any, value: any) => {
    setPagination({...pagination, currentPage: value})
  }
  const getMyInvoices = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`invoices?limit=${pagination.perPage}&page=${pagination.currentPage}`) as IInvoicesResponse
      setInvoices(res?.data?.invoices || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    getMyInvoices()
  }, [pagination.currentPage])

  const handleDownload = async (id: string) => {
    try {
      const response = await instance.get(`invoices/${id}/download`, {responseType: 'arraybuffer'}) as any
      let blob = new Blob([response], {type: 'application/pdf'});
        let url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `INV-${id}.pdf`)
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    }
  }
  const renderTableInvoice = () => {
    return (
      <TableBody>
        {invoices.map((invoice) => (
          <TableRow key={invoice.id} className={styles.tableRow}>
            <TableCell>{invoice?.name}</TableCell>
            <TableCell>{moment(invoice?.updatedAt).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{invoice?.total}</TableCell>
            <TableCell className={styles.downloadInvoiceCell}>
              <Button variant='contained' onClick={() => handleDownload(invoice?.id)} className={styles.textButton}>
                <Typography color='white' variant='body2'>INV-S-{invoice?.id}</Typography>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }
  const renderTableSkeleton = () => {
    return (
      <TableBody>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
          <TableRow key={number} className={styles.tableRow}>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell className={styles.downloadInvoiceCell}>
              <Button variant='contained' className={styles.textButton}>
                <Typography color='white' variant='body2'><Skeleton /></Typography>
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }
  if(!invoices.length) {
    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <Title title={t('My Invoices')} />
        </div>

        <div className={styles.content}>
          <div className={styles.title}>{t('Purchase History')}</div>

          <div className={styles.table}>
            {t('no_invoices')}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Title title={t('My Invoices')} />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{t('Purchase History')}</div>

        <div className={styles.table}>
          <TableContainer component={Paper} className={styles.table}>
            <Table>
              <TableHead>
                <TableRow className={styles.tableRow}>
                  <TableCell className={styles.titleCell}>
                    {t('Purchase Description')}
                  </TableCell>
                  <TableCell className={styles.titleCell}>
                    {t('Date')}
                  </TableCell>
                  <TableCell className={styles.titleCell}>
                    {t('Total')}
                  </TableCell>
                  <TableCell className={styles.titleCell}>
                    {t('Download Invoice')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? renderTableSkeleton() : renderTableInvoice()}
            </Table>
          </TableContainer>

          {pagination?.totalPages > 1 && <CustomPagination
            shape='rounded'
            count={pagination?.totalPages}
            page={pagination?.currentPage}
            onChange={handleChange}
            size='large'
            className={styles.pagination}
          />}
        </div>
      </div>
    </div>
  )
}
