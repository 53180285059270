import React from 'react';
import { Container } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { CustomPagination } from '../pagination/CustomPagination'
import SkeletonImage from './image'


export const SkeletonImageWithPagination = () => {

  return (
    <>
      {[1, 2, 3, 4, 5 ,6].map((item) => (
        <Skeleton key={item} sx={{marginRight: 2}}>
         <SkeletonImage />
        </Skeleton>
      ))}
      <Skeleton>
        <CustomPagination />
      </Skeleton>
    </>
  )
}

export default SkeletonImageWithPagination;