import { Button, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import styles from './ButtonNext.module.scss'

type Props = {
  currentStep: number;
  onNextStep: (key?: any) => void;
  isExamination?: boolean,
}

export const ButtonNext = (props: Props) => {

  const { currentStep, onNextStep, isExamination } = props
  const { t } = useTranslation()
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const uploadSessionStep1 = useSelector(
    (state: RootState) => state.uploadSession.imagesFirstStep
  )

  const setIsDisableButton = () => {
    if (currentStep === 1) {
      const setDisableExamination = (uploadSessionStep1?.images?.jpg?.length < 10);
      const setDisableButton = !uploadSessionStep1?.images?.jpg?.length || (uploadSessionStep1?.images?.jpg?.length > 0 && uploadSessionStep1?.images?.eps?.length > uploadSessionStep1?.images?.jpg?.length && uploadSessionStep1?.images?.png?.length > uploadSessionStep1?.images?.jpg?.length);
      return isExamination ? isExamination && setDisableExamination : setDisableButton;
    }
    if (currentStep === 2) {
      return uploadSession?.images.some(
        (image) => image.isAi && !image.networkAi
      )
    }
    if (currentStep === 3) {
      return uploadSession?.images.some(
          (image) => image.description?.length < 30
        ) ||
        uploadSession?.images.some(
          (image) => {
            return image?.keywords?.length < 7
          }
        )
    }
  }
  const setTitleError = () => {
    if(currentStep === 2 && uploadSession?.images.some(
      (image) => image.isAi && !image.networkAi
    )) {
      return t(
        'ai_network_error'
      )
    }
    if(currentStep === 3 && uploadSession?.images.some(
      (image) =>
        image.description?.length < 30 || !image.description
    )) {
      return t(
        'Some images have descriptions less than 30 characters.'
      )
    }
    if(currentStep === 3 && uploadSession?.images.some(
      (image) => {
        return image?.keywords?.length < 7
      }
    )) {
      return  t(
        'less_keywords'
      )
    }
  }
  return (
    <Tooltip
      title={setTitleError()}
      arrow
    >
      <Button
        className={`${styles.nextStepButton} ${setIsDisableButton() ? styles.disabledButton
            : ''
        }`}
        onClick={() => setIsDisableButton() ? {} : onNextStep(1)}
        style={setIsDisableButton()
          ? {
            color: 'white',
            backgroundColor: '#CCCCCC'
          }
          : { color: 'white', backgroundColor: '#c11b1e' }
        }
      >
        {t('Next Step')}
      </Button>
    </Tooltip>
  )
}

export default ButtonNext
