import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Typography } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import styles from './Lightbox.module.scss'
import { LightboxImage } from './LightboxImage'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface IProps {
  lightboxId: string | number
  title?: string | null
  description?: string | null
  lightboxImage: any,
  onChangeAccordion: (key?: string | number) =>void,
  expandedMain?: number | null,
  onDeleteLightBox: (key?: string) => void,
  onUpdateLightbox: (key?: string, key1?: string, key2?: string) => void,
  listImages: any,
  countImages?: number,
  onDeleteFavoriteImage: (key: string, key1: string, key2?: any, key3?: string) => void
}

export const Lightbox = (props: IProps) => {
  const { lightboxId, title, description, lightboxImage, expandedMain, onChangeAccordion, onDeleteLightBox, onUpdateLightbox, listImages, countImages, onDeleteFavoriteImage } = props

  const { t } = useTranslation()

  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [newTitle, setNewTitle] = useState<string>('')
  const [newDescription, setNewDescriptions] = useState<string>('')

  const handleOpen = () => {
    setOpenDeletePopup(!openDeletePopup)
  }

  const handleConfirm = async () => {
    onDeleteLightBox(lightboxId?.toString())
    setOpenDeletePopup(false)
  }

  useEffect(() => {
    setNewTitle(title || '')
  }, [title])

  useEffect(() => {
    setNewDescriptions(description || '')
  }, [description])

  const handleUpdateLightbox = async () => {
    onUpdateLightbox(lightboxId?.toString(), newTitle, newDescription)
  }
  return (
    <>
      <ConfirmDialog
        open={openDeletePopup}
        handleOpen={handleOpen}
        handleConfirm={handleConfirm}
        dialogTitle={t('Delete Lighbox')}
        dialogMessage={t('Are you sure to delete lightbox', {
          lightboxId,
        })}
      />
      <div className={styles.container}>
        <Accordion
          key={lightboxId}
          expanded={expandedMain === lightboxId}

          className={styles.accordion}
        >
          <AccordionSummary
            expandIcon={countImages !== 0 ? <IconButton onClick={() => {
              if(countImages !== 0) {
                onChangeAccordion(lightboxId)
              }
            }}><ExpandMoreIcon /></IconButton> : <></>}
            className={styles.accordionSummarys}
          >
        <div className={styles.lightboxDetails}>
          <img src={lightboxImage} alt={newTitle} className={styles.image} />

          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='title'
            variant='outlined'
            label={t('Title')}
            value={newTitle}
            onChange={(e: any) => {
              e.stopPropagation()
              setNewTitle(e.target.value)}
            }
          />

          <CustomTextfield
            className={styles.textfield}
            size='small'
            name='description'
            variant='outlined'
            label={t('Description')}
            value={newDescription}
            onChange={(e: any) => {
              e.stopPropagation()
              setNewDescriptions(e.target.value)}
            }
          />

          <Button
            variant='contained'
            disabled={description === newDescription && title === newTitle}
            className={styles.buttonLightbox}
            color="primary"
            onClick={handleUpdateLightbox}
          >
            {t('Save')}
          </Button>

          <Typography className={styles.title} color="primary">
            {t('Lightbox files', { noOfFiles: countImages })}
          </Typography>

          <Button
            variant='contained'
            className={styles.deleteButtonLightbox}
            onClick={handleOpen}
          >
            {t('Delete Lightbox')}
          </Button>
        </div>
          </AccordionSummary>
          <AccordionDetails sx={{ minWidth: 300 }}>
        <div className={styles.imagesList}>
          {(listImages || []).map((image: any) => (
            <Fragment key={`image-${image.list_id}-${image.image_id}-${image.size.id}`}>
              <LightboxImage
                item={{...image}}
                onDeleteFavoriteImage={onDeleteFavoriteImage}
                lightboxId={lightboxId?.toString()}
              />
            </Fragment>
          ))}
        </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}
