import { createSlice } from '@reduxjs/toolkit'

const initialState: { files: any[]; errorFeedback: string } = {
  files: [], // Store file names or URLs instead of the File objects
  errorFeedback: '',
}

const uploadImagesSlice = createSlice({
  name: 'uploadImages',
  initialState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload
    },
    addFile: (state, action) => {
      state.files.push(action.payload) // Push the file name or URL instead of the entire File object
    },
    setErrorFeedback: (state, action) => {
      state.errorFeedback = action.payload
    },
  },
})

export const { setFiles, addFile, setErrorFeedback } = uploadImagesSlice.actions

export default uploadImagesSlice.reducer
