import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import {
  Avatar, Box, Button,
  Container,
  Grid,
  IconButton,
  ImageListItem,
  Link, Typography
} from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import Color, { Palette } from 'color-thief-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import {
  NavigationEnum,
  constants,
  initialFiltersConst,
  IFilters, BUYER, SELLER, ROUTES
} from '../../../../utils/constants'
import { FilterChipsList } from '../../../shared-components/card/FilterChipsList'
import { ImageZoomInModal } from '../../../shared-components/dialogs/images/ImageZoomInModal'
import { SearchBar } from '../../../shared-components/search/SearchBar'
import { AddToLightboxDialog } from '../account-details/sections/buyer/lightbox/AddToLightboxDialog'
import styles from './AssetDetails.module.scss'
import instance from '../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers/rootReducer'
import SkeletonAssetsDetail from '../../../shared-components/skeleton/assetsDetail'
import Skeleton from '@mui/material/Skeleton'
import { constructSizes } from '../../../../utils/helpers'
import AccordionAssetsDetail from '../../../shared-components/accordionAssetsDetail'
import { IExtensionImage, IImage, ISizeSelected } from '../../../../utils/types'
import FastDownload from '../../../shared-components/FastDownload'
import { AssetsFormTab } from '../../../shared-components/assetsFormTab'
import { CustomCarousel } from '../../../shared-components/customCarousel'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useCustomNavigation } from '../../../../hooks/useCustomNavigate'

type responseLightBox = {
  data?: string;
  message?: string;
  status?: string
}

export const typeImageForDownload = (typeImage?: string) => {
  switch (typeImage) {
    case 'eps':
      return 'application/postscript'
    case 'jpeg':
      return 'image/jpeg'
    case 'png':
      return 'image/png'
    default:
      return 'image/jpeg'
  }
}

export const AssetDetails = () => {
  const { t } = useTranslation()
  const { assetName } = useParams()
  const splitData = assetName?.split('-') || []
  const assetId = splitData[splitData?.length - 1];
  const navigate = useNavigate()
  const customNavigate = useCustomNavigation()
  const [openAddToLightbox, setOpenAddToLightbox] = useState(false)
  const initialFilters = withDefault(JsonParam, initialFiltersConst)
  const [filters, setFilters] = useQueryParam<IFilters>(
    'filters',
    initialFilters
  )
  const [imageInformation, setImageInformation] = useState<IImage>({})
  const [extensionImages, setExtensionImages] = useState<IExtensionImage[]>([])
  const [showAllKewords, setShowAllKeywords] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sizeSavedLightBox, setSizeSavedLightBox] = useState<any>({})
  const [sizeSelected, setSizeSelected] = useState<ISizeSelected>({})
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState<string>('0')
  const [expandedMain, setExpandedMain] = useState<number | null>(0)
  const [expandedInner, setExpandedInner] = useState<string | null>('') // Inner accordions (JPEG, EPS, PNG)
  const userRole = useSelector((state: RootState) => state.user.userRole)
  const showOptionsUser = userRole?.slug === BUYER
  const showOptionFastDownload = userRole?.slug !== SELLER
  const anonymousUser = !userRole?.id && userRole?.slug !== BUYER && userRole?.slug !== SELLER

  const [openZoomInModal, setOpenZoomInModal] = useState(false)

  const getImageInformation = async () => {
    setIsLoading(true)
    try {
      const splitData = assetId?.split('-') || []
      const res = await instance.get(`images/${splitData[splitData?.length - 1]}`)
      setImageInformation(res?.data || [])

      const sizes = constructSizes(res?.data || [])
      const firstElement = sizes[0]
      const licensesFirst = [...firstElement?.licenses]
      const getFirstElement = licensesFirst.find((lice: any) => lice?.sizes?.length > 0)
      setExpandedMain(firstElement?.licenseId)
      const selectFirstSize = sizes?.length ? {
        id: getFirstElement?.sizes[0]?.id,
        licenseId: firstElement?.licenseId,
        type: getFirstElement?.type,
        displayPrice: getFirstElement?.sizes[0]?.displayPrice,
        price: getFirstElement?.sizes[0]?.price,
        licenseName: firstElement?.licenseName
      } : {}
      setExtensionImages(sizes)
      setExpandedInner(selectFirstSize?.type || '')
      setSizeSelected({ ...selectFirstSize })

      setIsLoading(false)
    } catch (error) {
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getImageInformation()
  }, [assetId])
  const handleOpen = () => {
    setOpenZoomInModal(!openZoomInModal)
  }

  const handleOpenAddToLightbox = () => {
    setOpenAddToLightbox(!openAddToLightbox)
  }

  const handleSelectPaymentOption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPaymentOption(event.target.value)
  }
  const handleConfirmAddToLightbox = async (id?: string, title?: string, description?: string) => {
    if (id && !title && !description){
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' })
        const resp = await instance.post(`favorites/${assetId}`, {
          list_id: id, new_list: '0', image_size_id: sizeSavedLightBox?.size || sizeSelected.id,
          category: sizeSavedLightBox?.type || sizeSelected.type
        }) as responseLightBox
        if ((resp as responseLightBox).status === 'success') {
          Notiflix.Notify.success(t('Success'), {
            distance: '70px',
          })
          setSizeSavedLightBox({})
          setImageInformation({ ...imageInformation, isFavorite: true })
        } else {
          Notiflix.Report.failure(
            'Error',
            `${resp?.message}`,
            'OK'
          )
        }
        setOpenAddToLightbox(false)
      } catch (error) {
        console.error('Error create lightBox', error)
      } finally {
        Notiflix.Loading.remove()
      }
    } else {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' })
        const resp = await instance.post(`favorites/${assetId}`, {
          list_name: title,
          list_description: description,
          new_list: '1',
          image_size_id: sizeSavedLightBox?.size || sizeSelected?.id,
          category: sizeSavedLightBox?.type || sizeSelected.type
        }) as responseLightBox
        if ((resp as responseLightBox).status === 'success') {
          setSizeSavedLightBox({})
          Notiflix.Notify.success(t('Success'))
        } else {
          Notiflix.Report.failure(
            'Error',
            `${resp?.message}`,
            'OK'
          )
        }
        setOpenAddToLightbox(false)
      } catch (error) {
        console.error('Error create lightBox', error)
      } finally {
        Notiflix.Loading.remove()
      }
    }
    handleOpenAddToLightbox()
  }

  const handleChangeMainAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const findElementToExpand = extensionImages.find((element) => element.licenseId === panel)
      const elementFirstSizes = findElementToExpand?.licenses || []
      const elementFirstSelectedPanel = elementFirstSizes.find((el) => el?.sizes?.length)
      if (elementFirstSelectedPanel && elementFirstSelectedPanel?.sizes?.length) {
        setSizeSelected({
          id: elementFirstSelectedPanel?.sizes[0]?.id,
          type: elementFirstSelectedPanel?.type,
          licenseId: findElementToExpand?.licenseId,
          displayPrice: elementFirstSelectedPanel?.sizes[0]?.displayPrice,
          price: elementFirstSelectedPanel?.sizes[0]?.price,
          licenseName: findElementToExpand?.licenseName
        })
        setExpandedMain(panel)
      }
      setExpandedMain(panel)
    }

  const handleChangeInnerAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedInner(isExpanded ? panel : '')
      if (isExpanded) {
        const findElementExpanded = extensionImages.find((extension: any) => extension.licenseId === expandedMain)
        const elementFirstSizes = findElementExpanded?.licenses || []
        const elementFirstSelectedPanel = elementFirstSizes.find((el: any) => el.type === panel && el.sizes.length)
        if (elementFirstSelectedPanel && elementFirstSelectedPanel?.sizes?.length) {
          setSizeSelected({
            id: elementFirstSelectedPanel?.sizes[0]?.id,
            type: panel,
            licenseId: findElementExpanded?.licenseId,
            displayPrice: elementFirstSelectedPanel?.sizes[0]?.displayPrice,
            price: elementFirstSelectedPanel?.sizes[0]?.price,
            licenseName: findElementExpanded?.licenseName
          })
        }

      }
    }
  const handleSearchKeywords = (keyword?: string) => {

    // console.log('keyyy', keyword)
  }


  const handleChangeSize = (id: any, licenseId: any, displayPrice?: string, price?: any, type?: string, licenseName?: string) => {
    setSizeSelected({
      id: id,
      licenseId: licenseId,
      displayPrice: displayPrice,
      price: price,
      type: type,
      licenseName: licenseName
    })
  }
  const downloadImageFree = async (typeDownload: string) => {
    const response = await instance.get(`images/${assetId}/download?image_size_id=${sizeSelected?.id?.toString()}&category=${sizeSelected?.type}&method=${typeDownload}`, { responseType: 'arraybuffer' }) as any
    let blob = new Blob([response], { type: `image/${typeImageForDownload(sizeSelected?.type)}` }),
      url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${imageInformation?.title}.${sizeSelected?.type}`)
    document.body.appendChild(link)
    link.click()
  }
  const handlePayCredits = async () => {
    if (sizeSelected.price === 0) {
      await downloadImageFree('credits')
    } else {
      if (sizeSelected?.type && sizeSelected?.id) {
        const params = {
          plan: 'credits',
          category: sizeSelected?.type || '',
          size: sizeSelected?.id?.toString()
        }
        // @ts-ignore
        navigate({
          pathname: `/assets/${assetName}/buy`,
          search: `?${new URLSearchParams(params).toString()}`
        })
      }
    }
  }
  const handlePaySubscriptions = async () => {
    if (sizeSelected.price === 0) {
      await downloadImageFree('subscription')
      Notiflix.Loading.remove()
    } else {
      if (sizeSelected?.type && sizeSelected?.id) {
        const params = {
          plan: 'subscription',
          category: sizeSelected?.type || '',
          size: sizeSelected?.id?.toString()
        }
        // @ts-ignore
        navigate({
          pathname: `/assets/${assetName}/buy`,
          search: `?${new URLSearchParams(params).toString()}`
        })
      }
    }
  }
  const openLightBoxSave = (size: any, type: any) => {
    setSizeSavedLightBox({ size: size, type: type })
    handleOpenAddToLightbox()
  }
  const handleImageRedirect = (id: string) => {
    navigate(`/${ROUTES.ASSETS}/${assetName}`)
  }
  return (
    <>
      {openAddToLightbox && <AddToLightboxDialog
        open={openAddToLightbox}
        dialogTitle={t('Lightbox')}
        handleOpen={handleOpenAddToLightbox}
        handleConfirm={handleConfirmAddToLightbox}
      />}
      <ImageZoomInModal
        showOptionsUser
        open={openZoomInModal}
        image={imageInformation?.mediumURL}
        handleOpen={handleOpen}
        title={imageInformation?.title}
      />
      <Grid sx={{ width: '100%' }}>
        <div className={styles.searchBar}>
          <SearchBar />
        </div>
        <Color
          // src={imageInformation?.mediumURL || constants.img_wide}
          src={constants.img_wide}
          crossOrigin='anonymous'
          format='hex'
        >
          {({ data }) => {
            if (data) {
              return (
                <Box key={data || 'color'}>
                  {isLoading ? <SkeletonAssetsDetail data={data} /> : <div
                    style={{
                      background: `linear-gradient(to bottom right, ${
                        data + '40'
                      }, ${data + '10'})`
                    }}
                  >
                    <Container sx={{ paddingTop: 4 }}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={8}>
                          <Box sx={{ paddingLeft: 4 }}>
                            {imageInformation?.isAi ? <Typography color='primary'>
                              {t('created_by_ai')} <span style={{ color: '#000' }}>{imageInformation?.networkAi}</span>
                            </Typography> : null}
                            {imageInformation?.isAdult ? <Typography color='primary'>
                              {t('adult_content')} <span
                              style={{ color: '#000' }}>{t('adult_content_description')}</span>
                            </Typography> : null}
                            {imageInformation?.isEditorial ? <Typography color='primary'>
                              <span style={{ color: '#000' }}>{t('files_for')}</span> {t('editorial_use_only')} <span
                              style={{ color: '#000' }}>{t('edirorial_commercial')}</span>
                            </Typography> : null}
                          </Box>
                          <ImageListItem className={styles.imageWrapper}
                                         key={`${imageInformation?.originalFilename}-${imageInformation?.mediumURL}`}>
                            <img
                              src={imageInformation?.mediumURL}
                              alt={imageInformation?.originalFilename}
                              loading='lazy'
                              className={styles.image}
                            />
                            <IconButton
                              style={{ position: 'absolute', top: 30, right: 30 }}
                              sx={{
                                color: 'white',
                                backgroundColor: '#50505090',
                                '&:hover': {
                                  backgroundColor: '#c11b1e90'
                                }
                              }}
                              onClick={handleOpen}
                            >
                              <ZoomInIcon sx={{ height: 30, width: 30 }} />
                            </IconButton>
                          </ImageListItem>
                          {showOptionsUser &&
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
                              <Button size='small' className={styles.buttonShare} variant='contained'
                                      onClick={handleOpenAddToLightbox}>
                                <FavoriteBorderIcon sx={{ height: 20, width: 20 }} /> {t('add_lightbox_cart')}
                              </Button>
                              <Button sx={{ marginLeft: 1 }} size='small' className={styles.buttonShare}
                                      variant='contained'>
                                <ShareIcon sx={{ height: 20, width: 20 }} /> {t('share')}
                              </Button>
                            </Box>}
                          <div className={styles.relatedKeywordsContainer}>
                            {isLoading ? <Skeleton variant='rectangular' width={100} /> :
                              <div className={styles.stockPhotoId}>
                                {t('Stock Photo ID')}: {imageInformation?.id}
                              </div>}
                            {isLoading ? <Skeleton variant='rectangular' width={200} /> :
                              <div className={styles.description}>
                                {imageInformation?.title}
                              </div>}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{ paddingBottom: 5 }}>
                          <div className={styles.userContainer}>
                            <div className={styles.userImage}>
                              {isLoading ? <Skeleton variant='circular'><Avatar /></Skeleton> :
                                <Avatar sx={{ bgcolor: '#c11b1e' }}
                                        src={imageInformation?.author?.avatar}>{imageInformation?.author?.firstName ? imageInformation?.author?.firstName.charAt(0) : ''}</Avatar>}
                              <Link
                                sx={{ color: '#000', fontWeight: 'bold', cursor: 'pointer', textDecoration: 'none' }}
                                component='span'
                                className={styles.text}
                                onClick={() => {
                                  customNavigate(
                                    `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
                                      JSON.stringify({
                                        ...filters,
                                        advancedSearch: {
                                          ...filters.advancedSearch,
                                          author: imageInformation?.author?.username
                                        }
                                      })
                                    )}`
                                  )
                                }}
                              >
                                {imageInformation?.author?.username}
                              </Link>
                            </div>

                            <div>
                              <span className={styles.text}>{t('ID')}: </span>{' '}
                              <span className={styles.text}>{imageInformation?.author?.id}</span>{' '}
                            </div>
                          </div>
                          {(extensionImages || []).map((item) => {
                            return (
                              <div key={`extension-${item?.licenseId}`}>
                                <AccordionAssetsDetail item={item} expandedMain={expandedMain}
                                                       handlePaySubscriptions={handlePaySubscriptions}
                                                       handlePayCredits={handlePayCredits}
                                                       handleSelectPaymentOption={handleSelectPaymentOption}
                                                       expandedInner={expandedInner}
                                                       handleChangeInnerAccordion={handleChangeInnerAccordion}
                                                       handleChangeSize={handleChangeSize}
                                                       sizeSelected={sizeSelected}
                                                       isFavorite={imageInformation?.isFavorite}
                                                       isAnonymousUser={anonymousUser}
                                                       showOptionsUser={showOptionsUser}
                                                       onOpenLightBox={openLightBoxSave}
                                                       handleChangeMainAccordion={handleChangeMainAccordion} />
                              </div>
                            )
                          })}
                          {showOptionFastDownload && <FastDownload sizeSelected={sizeSelected} assetId={assetId}
                                                                   disabledButton={sizeSelected?.licenseName === 'Extended'}
                                                                   imageInformation={imageInformation} />}
                        </Grid>
                      </Grid>
                    </Container>
                  </div>}
                </Box>
              )
            }
          }}
        </Color>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} sx={{ marginTop: 5 }}>
              <div className={styles.relatedKeywordsContainer}>
                <div className={styles.title}>{t('Related keywords')} &nbsp;<Link
                  component='button'
                  variant='body2'
                  onClick={() => {
                    setShowAllKeywords(!showAllKewords)
                  }}
                >
                  {showAllKewords ? t('show_less') : t('show_more')}
                </Link></div>
                {isLoading ? <Skeleton variant='rectangular' width={300} /> :
                  <Box>
                    <FilterChipsList
                      isVisibleChips={showAllKewords}
                      titles={imageInformation?.keywords?.length ? [...imageInformation?.keywords] : []}
                      icon={'SEARCH'}
                      handleSearch={handleSearchKeywords}
                    /></Box>}
              </div>
              <div className={styles.relatedKeywordsContainer}>
                {imageInformation?.models?.model?.length ? <>
                  {(imageInformation?.models?.model || []).map((model: any) => (
                    <div key={model?.model}>
                      <Box className={styles.title}
                           sx={{ paddingBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>{t('same_model')}</Box><Link
                        component='button'
                        variant='body2'
                        onClick={() => {
                          customNavigate(
                            `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
                              JSON.stringify({
                                ...filters,
                                model: model?.model
                              })
                            )}`
                          )
                        }}
                      >
                        {t('see_all')}
                      </Link> </Box>
                      <CustomCarousel images={model?.images || []} imageRedirect={handleImageRedirect} />
                    </div>
                  ))} </> : <></>}
                {imageInformation?.models?.minor?.length ? <>
                  {(imageInformation?.models?.minor || []).map((minor: any) => (
                    <div key={minor?.model}>
                      <Box className={styles.title}
                           sx={{ paddingBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box> {t('same_minor')}</Box>
                        <Link
                          component='button'
                          variant='body2'
                          onClick={() => {
                            customNavigate(
                              `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
                                JSON.stringify({
                                  ...filters,
                                  minor: minor?.model
                                })
                              )}`
                            )
                          }}
                        >
                          {t('see_all')}
                        </Link>
                      </Box>
                      <CustomCarousel images={minor?.images || []} imageRedirect={handleImageRedirect} />
                    </div>
                  ))}</> : <></>}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ marginBottom: 5 }}>
              <Box sx={{ borderLeft: '1px solid gray', paddingLeft: 1 }}>
                <AssetsFormTab imageId={assetId} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}