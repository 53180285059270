import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PinterestIcon from '@mui/icons-material/Pinterest'
import TwitterIcon from '@mui/icons-material/Twitter'
import {
  Button, FormControl, FormControlLabel, FormLabel,
  InputAdornment, InputLabel, MenuItem, RadioGroup, Select,
  Typography
} from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadPhotoButton } from '../../../shared-components/button/UploadPhotoButton'
import { ConfirmDialog } from '../../../shared-components/dialogs/ConfirmDialog'
import { ChangeEmailDialog } from '../../../shared-components/dialogs/account-settings/ChangeEmailDialog'
import { ChangePasswordDialog } from '../../../shared-components/dialogs/account-settings/ChangePasswordDialog'
import { CustomPasswordField } from '../../../shared-components/textfield/CustomPasswordField'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import styles from './AccountSettings.module.scss'
import { Title } from './sections/Title'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers/rootReducer'
import { Controller, useForm } from 'react-hook-form'
import AutocompleteCountries from '../../../shared-components/textfield/AutocompleteCountries'
import Notiflix from 'notiflix'
import instance from '../../../../providers/axios.config'
import { setUserData } from '../../../../redux/reducers/usersReducer'
import { monthsBetweenDates } from '../../../../utils/helpers'
import moment from 'moment'
import { CustomRadio } from '../../../shared-components/checkbox/CustomRadio'
import { yupResolver } from '@hookform/resolvers/yup'
import { schemaValidatorAccount } from '../../../../utils/shemaValidatorAccount'
import { t } from 'i18next'
import { ChangeNameDialog } from '../../../shared-components/dialogs/account-settings/DialogChangeName'
import { BUYER, SELLER } from '../../../../utils/constants'
import { AxiosResponse } from 'axios'

type Address = {
  address?: string,
  city?: string,
  zip?: string,
  state?: string,
  company?: string,
  vat_number?: string,
  phone_number?: string,
  country?: string,
  vatNumber?: string,
  phone?: string,
};


export const typePayment = [
  {
    id: 'Revolut',
    name: 'Revolut'
  },
  {
    id: 'Skrill',
    name: 'Skrill'
  },
  {
    id: 'PayPal',
    name: 'PayPal'
  },
  {
    id: 'Payoneer',
    name: 'Payoneer'
  }
]

const disableSelectPayment = (paymentInfo: any, selectedTypePayment: string) => {
  const findData = paymentInfo.find((data: any) => data.id === selectedTypePayment)
  return findData && !findData.email ? true : false
}

export const AccountSettings = () => {
  const { t } = useTranslation()
  const profile = useSelector((state: RootState) => state.user.usersData)
  const account = useSelector((state: RootState) => state.user.usersData)
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [fileToDelete, setFileToDelete] = useState('')
  const address = account?.addresses?.length ? { ...account?.addresses[0] } : {} as Address
  const isSeller = account?.role?.slug === SELLER;
  const dispatch = useDispatch()
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {
      website: account?.website !== 'null' ? (account?.website || '') : '',
      facebook: account?.facebook !== 'null' ? (account?.facebook || '') : '',
      twitter: account?.twitter !== 'null' ? (account?.twitter || '') : '',
      linkedin: account?.linkedin !== 'null' ? (account?.linkedin || '') : '',
      pinterest: account?.pinterest !== 'null' ? (account?.pinterest || '') : '',
      instagram: account?.instagram !== 'null' ? (account?.instagram || '') : '',
      firstName: account?.firstName,
      lastName: account?.lastName,
      minimum_payout: account?.minPayout?.toString() || '50',
      address: address?.address || '',
      city: address?.city || '',
      zip: address?.zip || '',
      state: address?.state || '',
      company: address?.company || '',
      vat_number: address?.vatNumber || '',
      phone_number: address?.phone || ''
    },
    resolver: yupResolver(schemaValidatorAccount(isSeller))
  })

  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false)
  const [openChangeName, setOpenChangeName] = useState<boolean>(false)
  const [openChangeEmail, setOpenChangeEmail] = useState<boolean>(false)
  const [openChangeLogin, setOpenChangeLogin] = useState<boolean>(false)
  const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false)
  const countries = useSelector((state: RootState) => state.countries.countries) as any
  const idDataCountry = countries.find((data: any) => data.label === address?.country)
  const [selectedCountry, setSelectedCountry] = useState<{ label?: string, value?: string }>(
    { label: address?.country || '', value: idDataCountry?.value?.toString() }
  )
  const [login, setLogin] = useState<string>(profile?.username || '')
  const [password, setPassword] = useState<string>('')
  const [email, setEmail] = useState<string>(account?.email || '')
  const [identityDocumentPhoto, setIdentityDocumentPhoto] = useState<any>(account?.id_file || '')
  const [designPhoto, setDesignPhoto] = useState<any>(account?.design_photo || '')
  const [profilePicture, setProfilePicture] = useState<any>(
    account?.avatar || ''
  )
  const [paymentInfo, setPaymentInfo] = useState([
    { id: 'Revolut', email: account?.payoutRevolut, isSelected: !!account?.payoutRevolut },
    {
      id: 'PayPal',
      email: account?.payoutPaypal,
      isSelected: !!account?.payoutPaypal
    }, { id: 'Skrill', email: account?.payoutSkrill, isSelected: !!account?.payoutSkrill }, {
      id: 'Payoneer',
      email: account?.payoutPayoneer,
      isSelected: !!account?.payoutPayoneer
    }])
  const [expandedPayment, setExplandedPayment] = useState<string>('')
  const [selectedTypePayment, setSelectedTypePayment] = useState('All')

  useEffect(() => {
    if (idDataCountry?.value) {
      setSelectedCountry({ ...idDataCountry });
    }
  }, [idDataCountry])

  const handleOpenChangePassword = () => {
    setOpenChangePassword(!openChangePassword)
  }

  const handleOpenDeleteAccount = () => {
    setOpenDeleteAccount(!openDeleteAccount)
  }

  const handleOpenChangeEmail = () => {
    setOpenChangeEmail(!openChangeEmail)
  }
  const handleOpenChangeName = () => {
    setOpenChangeName(!openChangeName)
  }

  const handleOpenChangeLogin = () => {
    setOpenChangeLogin(!openChangeLogin)
  }

  const handleConfirmDeleteAccount = () => {
    setOpenDeleteAccount(!openDeleteAccount)
  }

  const handleConfirmChangeLogin = () => {
    setOpenChangeLogin(!openChangeLogin)
  }
  const handleConfirmChangeName = async (firstName: string, lastName: string, reason: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post(`requests/change-name?new_first_name=${firstName}&new_last_name=${lastName}&reason=${reason}`)
      if ((resp as any).status === 'success') {
        Notiflix.Notify.success(t('Success'))
      }
    } catch (error) {
      console.error('Error change password:', error)
    } finally {
      Notiflix.Loading.remove()
    }
    setOpenChangeName(!openChangeName)
  }

  const handleConfirmChangePassword = async (newPassword: string, confirmNewPassword: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.put(`user/profile/password?password=${newPassword}&password_confirmation=${confirmNewPassword}`)
      if ((resp as any).status === 'success') {
        Notiflix.Notify.success(t('Success'))
      }
    } catch (error) {
      console.error('Error change password:', error)
    } finally {
      Notiflix.Loading.remove()
    }
    setOpenChangePassword(!openChangePassword)
  }

  const handleConfirmChangeEmail = async (reason: string, email: string) => {
    setOpenChangeEmail(!openChangeEmail)
      try {
        const formData = new FormData();
        formData.append(`reason`, reason)
        formData.append(`new_email`, email)
        Notiflix.Loading.standard({ svgColor: '#c11b1e' })
        const resp = await instance.post(`requests/change-email`, formData)
        if ((resp as any).status === 'success') {
          Notiflix.Notify.success(t('Success'))
        }
      } catch (error) {
        console.error('Error change password:', error)
      } finally {
        Notiflix.Loading.remove()
      }
  }
  const handleChangePayments = (payment: string) => {
    if (payment === expandedPayment) setExplandedPayment('')
    else setExplandedPayment(payment)
  }
  const handleSubmitForm = async (data: any) => {
    const formData = new FormData()
    const newData = {
      ...data,
      first_name: data.firstName,
      last_name: data.lastName,
      country_id: selectedCountry?.value
    } as any
    delete newData?.firstName
    delete newData?.lastName
    const dataObject = Object.keys(newData)
    paymentInfo.forEach((payment: any) => {
      if (payment.email && payment.isSelected) {
        formData.append(`payout_${payment.id.toLowerCase()}`, payment.email)
      }
    })
    dataObject.forEach((k) => {
      formData.append(`${k}`, newData[`${k}`])
    })
    formData.append(`_method`, 'PUT')
    if (designPhoto && !account?.design_photo) {
      formData.append(`file_design_photo`, designPhoto)
    }
    if (identityDocumentPhoto && !account?.id_file) {
      formData.append(`file_id`, identityDocumentPhoto)
    }
    if (profilePicture && !account?.avatar) {
      formData.append(`file_profile_picture`, profilePicture)
    }
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>' // Set the Content-Type header for FormData
      }
    }
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp: AxiosResponse['data'] = await instance.post(`user/profile`, formData, config)
      if (resp) {
        if ((resp as any).status === 'success') {
          dispatch(setUserData(resp.data))
          Notiflix.Notify.success(t('Success'))
        } else {
          Notiflix.Report.failure(
            'Error',
            `${resp.message}`,
            'OK'
          )
        }
      }
    } catch (error) {
      // Handle error
      Notiflix.Report.failure(
        'Error',
        `${t('error_api')}`,
        'OK'
      )
    } finally {
      Notiflix.Loading.remove()
    }
  }
  const handleChangeEmailTypePayment = (ev: any, id: string) => {
    const findDataToChange = paymentInfo.map((payment) => {
      if (payment.id === id) {
        return {
          ...payment,
          email: ev.target.value
        }
      } else {
        return {
          ...payment
        }
      }
    })
    setPaymentInfo([...findDataToChange])
  }
  const handleDeleteImage = async (type?: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.put(`user/profile/delete-image`, JSON.stringify({ type: type }))
      if ((resp as any).status === 'success') {
        Notiflix.Loading.remove()
        dispatch(setUserData(resp.data))
        if (type === 'design') {
          setDesignPhoto(null)
        }
        if (type === 'identity') {
          setIdentityDocumentPhoto(null)
        }
        if (type === 'avatar') {
          setProfilePicture(null)
        }
        Notiflix.Notify.success(t('Success'))
      }
    } catch (e) {
      Notiflix.Loading.remove()
    }
  }
  const disableButton = () => {
   if(account?.role?.slug === 'seller') {
     if (errors && Object.keys(errors)?.length > 0) {
       return true
     }else if (!selectedCountry?.value) {
       return true
    //  }
      // else if(!identityDocumentPhoto) {
      //  return true
     } else if(paymentInfo.some((payment: any) => payment.isSelected && !payment.email)) {
       return true
     } else {
       return false
     }
   }else {
     if(!selectedCountry?.value) {
       return true
     }
   }
  }
  const handleConfirm = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.put(`user/profile/delete-image`, JSON.stringify({ type: fileToDelete }))
      if ((resp as any).status === 'success') {
        Notiflix.Loading.remove()
        dispatch(setUserData(resp.data))
        if (fileToDelete === 'design') {
          setDesignPhoto(null)
        }
        if (fileToDelete === 'identity') {
          setIdentityDocumentPhoto(null)
        }
        if (fileToDelete === 'avatar') {
          setProfilePicture(null)
        }
        setFileToDelete('')
        setOpenDeletePopup(false)
        Notiflix.Notify.success(t('Success'))
      }
    } catch (e) {
      Notiflix.Loading.remove()
    }
  }
  const handleOpen = () => {
    setOpenDeletePopup(false)
  }
  const handleOpenModalDelete = (type?: string) => {
    if(type) {
      setFileToDelete(type)
      setOpenDeletePopup(true)
    }
  }
  return (
    <>
      <ConfirmDialog
        open={openDeleteAccount}
        handleOpen={handleOpenDeleteAccount}
        handleConfirm={handleConfirmDeleteAccount}
        dialogTitle={t('Delete Account')}
        dialogMessage={t('Delete_Account_Text')}
      />
      <ConfirmDialog
        open={openChangeLogin}
        handleOpen={handleOpenChangeLogin}
        handleConfirm={handleConfirmChangeLogin}
        dialogTitle={t('Change Login')}
        dialogMessage={t('Change_login_text')}
      />
      <ChangePasswordDialog
        open={openChangePassword}
        handleOpen={handleOpenChangePassword}
        handleConfirm={handleConfirmChangePassword}
        dialogTitle={t('Change Password')}
        password={password}
      />
      <ChangeNameDialog
        open={openChangeName}
        handleOpen={handleOpenChangeName}
        handleConfirm={handleConfirmChangeName}
        dialogTitle={t('change_name')}
      />
      <ChangeEmailDialog
        open={openChangeEmail}
        handleOpen={handleOpenChangeEmail}
        handleConfirm={handleConfirmChangeEmail}
        dialogTitle={t('Change Email')}
        email={email}
      />
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <Title title={t('Account Settings')} />
          </div>

          <div className={styles.content}>
            <div className={styles.title}>{t('Common Information')}</div>

            <div className={styles.textfields}>
              <div className={styles.textfieldContainer}>
                <CustomTextfield
                  className={styles.textfield}
                  size='small'
                  name='login'
                  disabled
                  variant='outlined'
                  label={t('First Name')}
                  value={account?.firstName}
                />
                <Button
                  variant='contained'
                  className={styles.textfieldButton}
                  onClick={() => handleOpenChangeName()}
                >
                  Change name
                </Button>
              </div>
              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='login'
                disabled
                variant='outlined'
                label={t('Last Name')}
                value={account?.lastName}
              />
              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='login'
                disabled
                variant='outlined'
                label={t('Login')}
                value={login}
                onChange={(event: any) => {
                  setLogin(event.target.value)
                }}
              />

              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='id'
                disabled
                variant='outlined'
                label={t('ID')}
                value={account?.id}
              />

              <div className={styles.textfieldContainer}>
                <div className={styles.textfield}>
                  <CustomPasswordField
                    label={t('Password')}
                    value='xxxxxxx'
                    disabled={true}
                    changePassword={setPassword}
                  />
                </div>
                <Button
                  variant='contained'
                  className={styles.textfieldButton}
                  onClick={() => handleOpenChangePassword()}
                >
                  {t('Change Password')}
                </Button>
              </div>

              <div className={styles.textfieldContainer}>
                <CustomTextfield
                  className={styles.textfield}
                  size='small'
                  name='email'
                  variant='outlined'
                  label={t('Email')}
                  value={email}
                  disabled
                  onChange={(event: any) => {
                    setEmail(event.target.value)
                  }}
                />

                <Button
                  variant='contained'
                  className={styles.textfieldButton}
                  onClick={() => handleOpenChangeEmail()}
                >
                  {t('Change Email')}
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.title}>{t('Social Media')}</div>

            <div className={styles.textfields}>
              <Controller
                name='website'
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield label={t('Website')}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    className={styles.textfield}
                    {...newField}
                  />
                }
              />
              <Controller
                name='facebook'
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield label={t('Facebook')}
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <FacebookIcon sx={{ color: '#c11b1e' }} />
                        </InputAdornment>
                      )
                    }}
                    {...newField}
                  />
                }
              />
              <Controller
                name='instagram'
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    label={t('Instagram')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <InstagramIcon sx={{ color: '#c11b1e' }} />
                        </InputAdornment>
                      )
                    }}
                    {...newField}
                  />
                }
              />
              <Controller
                name='twitter'
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    inputRef={ref}
                    label={t('Twitter')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <TwitterIcon sx={{ color: '#c11b1e' }} />
                        </InputAdornment>
                      )
                    }}
                    {...newField}
                  />
                }
              />
              <Controller
                name='pinterest'
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    inputRef={ref}
                    label={t('Pinterest')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <PinterestIcon sx={{ color: '#c11b1e' }} />
                        </InputAdornment>
                      )
                    }}
                    {...newField}
                  />
                }
              />
              <Controller
                name='linkedin'
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    label={t('LinkedIn')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start'>
                          <LinkedInIcon sx={{ color: '#c11b1e' }} />
                        </InputAdornment>
                      )
                    }}
                    {...newField}
                  />
                }
              />
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.title}>{t('billing_details')}</div>

            <div className={styles.textfields}>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    inputRef={ref}
                    InputLabelProps={{ required: true, color: 'primary' }}
                    error={errors?.firstName}
                    helperText={errors?.firstName ? 'This field is required' : ''}
                    label={t('First Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='lastName'
                rules={{
                  required: true
                }}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    InputLabelProps={{ required: true, color: 'primary' }}
                    error={errors?.lastName}
                    helperText={errors?.lastName ? 'This field is required' : ''}
                    variant='outlined'
                    label={t('Last Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='address'
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    InputLabelProps={{ required: true, color: 'primary' }}
                    error={errors?.address}
                    helperText={errors?.address ? t('error_address') : ''}
                    label={t('Address')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='city'
                control={control}
                rules={{
                  // required: isSeller // we will uncomment this line if client will say on buyer city is required
                  required: true
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    // InputLabelProps={{ required: isSeller, color: 'primary' }}
                    InputLabelProps={{ required: true, color: 'primary' }}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.city}
                    helperText={errors?.city ? t('error_city') : ''}
                    label={t('City')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='zip'
                control={control}
                rules={{
                  required: true
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    InputLabelProps={{ required: true, color: 'primary' }}
                    variant='outlined'
                    error={errors?.zip}
                    helperText={errors?.zip ? t('error_zip') : ''}
                    label={t('Zip/Postal Code')}
                    {...newField}
                  />
                }
              />

              <AutocompleteCountries selectedCountry={selectedCountry}
                size='small'
                className={styles.textfield}
                setSelectedCountry={setSelectedCountry}
                isRequired
              />
              <Controller
                name='state'
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    InputLabelProps={{ required: false, color: 'primary' }}
                    variant='outlined'
                    inputRef={ref}
                    label={t('State')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='company'
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    inputRef={ref}
                    label={t('Company')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='vat_number'
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    variant='outlined'
                    inputRef={ref}
                    label={t('VAT Number')}
                    {...newField}
                  />
                }
              />
              <Controller
                name='phone_number'
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    // InputLabelProps={{ required: isSeller, color: 'primary' }}
                    InputLabelProps={{ required: false, color: 'primary' }}
                    variant='outlined'
                    error={errors?.phone_number}
                    helperText={errors?.phone_number ? t('error_phone') : ''}
                    inputRef={ref}
                    label={t('Phone Number')}
                    {...newField}
                  />
                }
              />
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.title}>{t('Public Profile')}</div>

            <div className={styles.textfieldContainer}>
              <div>{t('avatar_picture')}</div>

              <UploadPhotoButton
                id='avatar'
                acceptFile='image/*'
                onDeleteImage={handleOpenModalDelete}
                selectedImage={profilePicture}
                setSelectedImage={setProfilePicture}
              />
            </div>

            <div className={styles.textfieldContainer}>
              <div>{t('Design Photo')} </div>

              <UploadPhotoButton
                id='design'
                acceptFile='image/*'
                onDeleteImage={handleOpenModalDelete}
                selectedImage={designPhoto}
                setSelectedImage={setDesignPhoto}
              />
            </div>
          </div>
          {isSeller && <div className={styles.content}>
            <div className={styles.title}>{t('payouts_information')}</div>
            <div className={styles.textfields}>
              <FormControl>
                <InputLabel id='select-type-payment'>{t('type_payment')}</InputLabel>
                <Select
                  size='small'
                  className={styles.textfield}
                  labelId='select-type-payment'
                  id='select-type-payment'
                  label={'Select Type payment'}
                  value={selectedTypePayment}
                  onChange={(ev: any) => {
                    const updateData = paymentInfo.map((data) => {
                      if (data.id === ev.target.value) {
                        const findDataIndex = paymentInfo.findIndex((payment: any) => !payment.email && payment.isSelected);
                        if(findDataIndex !== -1) {
                          paymentInfo[findDataIndex].isSelected = false;
                        }
                        return {
                          ...data,
                          isSelected: true
                        }
                      }
                      return {
                        ...data
                      }
                    })
                    setPaymentInfo([...updateData])
                    setSelectedTypePayment(ev.target.value)
                  }}
                >
                  <MenuItem selected disabled value={"All"}>All</MenuItem>
                  {typePayment.map((type: any) => (
                    <MenuItem key={`${type.id}-typePayment`} value={type.id}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {paymentInfo.map((info) => (
                <Fragment key={`${info.id}-payment`}>
                  {(selectedTypePayment === info.id) && <CustomTextfield
                    key={`${info.id}-payment-info`}
                    className={styles.textfield}
                    size='small'
                    InputLabelProps={{ required: true, color: 'primary' }}
                    onChange={(ev: any) => handleChangeEmailTypePayment(ev, info.id)}
                    variant='outlined'
                    label={t('email_payment_info', { paymentName: info.id })}
                    value={info?.email  || ''}
                    error={selectedTypePayment === info.id && !info.email}
                    helperText={selectedTypePayment === info.id && !info.email ? t('email_required') : ''}
                  />}
                </Fragment>
              ))}
              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='login'
                disabled
                variant='outlined'
                label={t('First Name')}
                value={account?.firstName}
              />
              <CustomTextfield
                className={styles.textfield}
                size='small'
                name='login'
                disabled
                variant='outlined'
                label={t('Last Name')}
                value={account?.lastName}
              />
              <FormControl component='fieldset'>
                <FormLabel component='legend'>{t('minimum_payout')}</FormLabel>
                <Controller
                  control={control}
                  name='minimum_payout'
                  defaultValue='100'
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value='50'
                        control={<CustomRadio />}
                        label={'$50'}
                      />
                      <FormControlLabel
                        value='75'
                        control={<CustomRadio />}
                        label={'$75'}
                      />
                      <FormControlLabel
                        value='100'
                        control={<CustomRadio />}
                        label={'$100'}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <div className={styles.textfieldContainer}>
                <div>{t('Identity Document')} </div>
                <UploadPhotoButton
                  id='identity'
                  acceptFile='image/*'
                  onDeleteImage={handleOpenModalDelete}
                  selectedImage={identityDocumentPhoto}
                  setSelectedImage={setIdentityDocumentPhoto}
                />
              </div>
            </div>
          </div>}

          {/*  <div className={styles.textfieldContainer}>*/}
          {/*    <Accordion*/}
          {/*      expanded={expandedPayment === 'paypal'}*/}
          {/*      onChange={() => handleChangePayments('paypal')}*/}
          {/*      className={styles.accordion}*/}
          {/*    >*/}
          {/*      <AccordionSummary*/}
          {/*        expandIcon={<ExpandMoreIcon />}*/}
          {/*        className={styles.accordionSummarys}*/}
          {/*      >*/}
          {/*        <Typography sx={{ color: 'text.secondary' }}>*/}
          {/*          {t('PayPal')}*/}
          {/*        </Typography>*/}
          {/*      </AccordionSummary>*/}
          {/*      <AccordionDetails sx={{ minWidth: 300 }}>*/}
          {/*        <div className={styles.buttonsContainer}></div>*/}
          {/*      </AccordionDetails>*/}
          {/*    </Accordion>*/}
          {/*  </div>*/}
          {/*  <div className={styles.textfieldContainer}>*/}
          {/*    <Accordion*/}
          {/*      expanded={expandedPayment === 'stripe'}*/}
          {/*      onChange={() => handleChangePayments('stripe')}*/}
          {/*      className={styles.accordion}*/}
          {/*    >*/}
          {/*      <AccordionSummary*/}
          {/*        expandIcon={<ExpandMoreIcon />}*/}
          {/*        className={styles.accordionSummarys}*/}
          {/*      >*/}
          {/*        <Typography sx={{ color: 'text.secondary' }}>*/}
          {/*          {t('Stripe')}*/}
          {/*        </Typography>*/}
          {/*      </AccordionSummary>*/}
          {/*      <AccordionDetails sx={{ minWidth: 300 }}>*/}
          {/*        <div className={styles.buttonsContainer}></div>*/}
          {/*      </AccordionDetails>*/}
          {/*    </Accordion>*/}
          {/*  </div>*/}
          {/*</div>}*/}

          <div className={styles.content}>
            <div className={styles.title}>{t('Delete Account')}</div>

            <div className={styles.deleteAccountContainer}>
              <Button
                variant='contained'
                className={styles.textfieldButton}
                disabled={monthsBetweenDates(moment(account?.createdAt), moment()) <= 6}
                onClick={() => {
                  if (monthsBetweenDates(moment(account?.createdAt), moment()) >= 6) {
                    handleOpenDeleteAccount()
                  }
                }}
              >
                {t('Delete Account')}
              </Button>
            </div>
            <Typography color={'red'}>
              *{monthsBetweenDates(moment(account?.createdAt), moment()) >= 6 ? t('account_delete_after_6_months') : t('account_delete')}
            </Typography>
          </div>

          <div className={styles.buttonContainer}>
            <Button type='submit' disabled={disableButton()}
                    variant='contained' className={styles.textfieldButton}>
              {t('Save')}
            </Button>

            <Button variant='contained' className={styles.textfieldButton}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
        <ConfirmDialog
          open={openDeletePopup}
          handleOpen={handleOpen}
          handleConfirm={handleConfirm}
          dialogTitle={t('Delete Image')}
          dialogMessage={t('are_you_sure_delete_image_name', {
            name: fileToDelete,
          })}
        />
      </form>
    </>
  )
}
