import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPanel } from '../../shared-components/TabPanel'
import { PlanCard } from '../../shared-components/card/PlanCard'
import styles from './PlansPrices.module.scss'
import instance from '../../../providers/axios.config'
import Notiflix, { Loading } from 'notiflix'
import { useLocation } from 'react-router-dom'
import { DialogSuccessPaymentPlan } from '../../shared-components/dialogSuccessPaymentPlan'
import { DialogCancelPaymentImage } from '../../shared-components/dislogCancelPayment'
import SkeletonPlanCard from '../../shared-components/skeleton/planCard'

export const PlansPrices = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const data = new URLSearchParams(search)
  const [tabValue, setTabValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [plansSubscriptions, setPlansSubscriptions] = useState([])
  const [plansCredits, setPlansCredits] = useState([])
  const [openSuccessPayment, setOpenSuccessPayment] = useState(false)
  const [openCancelPayment, setOpenCancelPayment] = useState(false)

  const handleChangeTab = (event: any, newValue: any) => {
    setTabValue(newValue)
  }

  const getPlans = async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' });
    try {
      const resSubscriptions = await instance.get(`plans?category=Subscriptions`)
      const resCredits = await instance.get(`plans?category=credits`)
      setPlansCredits(resCredits?.data)
      setPlansSubscriptions(resSubscriptions?.data)
      Notiflix.Loading.remove();
    } catch (error) {
      console.error('Error fetching data:', error)
      Notiflix.Loading.remove();
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    if (data.get('session_id')) {
      if (data?.get('success')) {
        setOpenSuccessPayment(true)
      } else if (data?.get('cancel')) {
        setOpenCancelPayment(true)
      }

    }
  }, [data.get('session_id')])


  useEffect(() => {
    getPlans()
  }, [])

  const handleCloseSuccessPayment = () => {
    setOpenSuccessPayment(false)
    setOpenCancelPayment(false)
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          variant='fullWidth'
          TabIndicatorProps={{ style: { backgroundColor: '#c11b1e' } }}
        >
          <Tab label={t('Subscriptions')} />
          <Tab label={t('Credits')} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <div className={styles.creditsContainer}>
          {/* <SkeletonPlanCard /> */}
          {plansSubscriptions.map((planAndPrice: any) => (
            <PlanCard key={planAndPrice?.id} {...planAndPrice} />
          ))}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <h4 className={styles.textCenter}>{t('plan_and_price_credits_title')}</h4>
        <div className={styles.creditsContainer}>
          {plansCredits.map((planAndPrice: any) => (
            <PlanCard key={`credits-${planAndPrice?.id}`} {...planAndPrice} />
          ))}
        </div>
      </TabPanel>
      {openSuccessPayment ? <DialogSuccessPaymentPlan open={openSuccessPayment} handleClose={handleCloseSuccessPayment} /> : null}
      {openCancelPayment? <DialogCancelPaymentImage open={openCancelPayment} handleClose={handleCloseSuccessPayment} /> : null}
    </Box>
  )
}
