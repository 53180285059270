import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import styles from '../../main-components/content/account-details/sections/seller/payment/Payment.module.scss'
import Skeleton from '@mui/material/Skeleton'
import { CustomPagination } from '../pagination/CustomPagination'
import { useTranslation } from 'react-i18next'
import { ITaxForm } from '../../../utils/types'
import { setTypeStatus } from '../../main-components/content/account-details/sections/seller/payment/PaymentHistory'

type Props = {
  isLoading?: boolean;
  taxForms: ITaxForm[];
  pagination: {
    currentPage: number,
    perPage: number,
    totalPages: number
  },
  onChange?: (ev: any, key: number) => void
  handleDownload?: (key?: string) => void
}

export const PaymentTaxForm = (props: Props) => {
  const { t } = useTranslation()
  const { isLoading, taxForms, pagination, onChange, handleDownload } = props

  if (!isLoading && !taxForms?.length) {
    return <>
      <Typography>{t('no_history_tax_forms')}</Typography>
    </>
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className={styles.tableRow}>
              <TableCell className={styles.titleCell}>
                {t('download_tax_form')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                {t('Country')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                {t('status')}
              </TableCell>
              <TableCell className={styles.titleCell}>
                Tax Form
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? <>
              {[1, 2, 3].map((number) => (
                <TableRow key={number} className={styles.tableRow}>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                </TableRow>
              ))}
            </> : <>
              {(taxForms || []).map((tax) => (
                <TableRow key={`${tax?.id}-tax-form`} className={styles.tableRow}>
                  <TableCell>{tax?.year}</TableCell>
                  <TableCell>{tax?.country}</TableCell>
                  <TableCell>{setTypeStatus(tax.status, tax.displayStatus)}</TableCell>
                  <TableCell>
                    <Link
                      component='button'
                      variant='body2'
                      onClick={() => {
                        if(handleDownload) {
                          handleDownload(tax?.tax_form)}
                        }
                      }
                    >
                      Tax Form
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </>}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination?.totalPages > 1 && <CustomPagination
        shape='rounded'
        count={pagination?.totalPages}
        page={pagination?.currentPage}
        onChange={onChange}
        size='large'
        className={styles.pagination}
      />}
    </>
  )
}