import SearchIcon from '@mui/icons-material/Search'
import { Alert, Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../../../../../utils/constants'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { Title } from '../../Title'
import { CreateNewLightboxDialog } from './CreateNewLightboxDialog'
import { Lightbox } from './Lightbox'
import styles from './LightboxList.module.scss'
import Notiflix from 'notiflix'
import instance from '../../../../../../../providers/axios.config'
import SkeletonLightBox from '../../../../../../shared-components/skeleton/skeletonLightBox'
import { Iinvoice, typeResponse } from '../../../../../../../utils/types'

type LightBoxType = {
  description?: string;
  id: number;
  images_count?: number,
  name?: string
}
interface IResponse extends typeResponse{
  data:{
    list: LightBoxType[],
    pagination: {
      currentPage: number,
      perPage: number,
      totalPages: number
    }
  }
}
export const LightboxList = () => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const [openCreateNewLightbox, setOpenCreateNewLightbox] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [expandedMain, setExpandedMain] = useState<number | null>(0)
  const [lightbox, setLightBox] = useState<LightBoxType[]>([])
  const [listImages, setListImages] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 15,
    totalPages: 1
  })

  const handleChange = (event: any, value: number) => {
    setPagination({ ...pagination, currentPage: value })
  }
  const getMyLightbox = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`favorites/lists?limit=${pagination.perPage}&page=${pagination.currentPage}`) as IResponse;
      setLightBox(res?.data?.list || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  const handleSearchByTitle = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`favorites/lists?q=${searchValue}`)
      setLightBox(res?.data?.list || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  const getMyImagesLightbox = async (lightBoxId: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.get(`favorites?list_id=${lightBoxId}`)
      if ((resp as any).status === 'success') {
        setListImages(resp?.data?.list || [])
      }
    } catch (error) {
      console.error('Error get data images', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }

  useEffect(() => {
    getMyLightbox()
  }, [pagination?.currentPage])

  const handleSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value)
  }

  const handleOpenCreateNewLightbox = () => {
    setOpenCreateNewLightbox(!openCreateNewLightbox)
  }

  const handleConfirmCreateNewLightbox = async (title: string, description: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post(`favorites/lists`, {
        list_name: title,
        list_description: description
      }) as typeResponse
      if ((resp as any).status === 'success') {
        await getMyLightbox()
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
      setOpenCreateNewLightbox(false)
    } catch (error) {
      console.error('Error create lightBox', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  const handleDeleteLightBox = async (id?: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.delete(`favorites/lists/${id}`) as typeResponse
      if ((resp as any).status === 'success') {
        const updateData = lightbox.filter((light: any) => light.id.toString() !== id)
        setLightBox([...updateData])
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      console.error('Error delete lightBox', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  const handleChangeMainAccordion = async (id: any) => {
    if (expandedMain !== id) {
      await getMyImagesLightbox(id)
      setExpandedMain(id)
    } else {
      setExpandedMain(0)
    }
  }
  const handleUpdateLightBox = async (lightboxId?: string, newTitle?: string, newDescription?: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post(`favorites/lists/${lightboxId}`, {
        list_name: newTitle,
        list_description: newDescription,
        _method: 'PUT'
      }) as typeResponse
      if ((resp as any).status === 'success') {
        const updateData = lightbox.find((light: any) => light.id === lightboxId)
        if (updateData) {
          updateData.name = newTitle
          updateData.description = newDescription
          setLightBox([...lightbox, { ...updateData }])
        }

        // await getMyLightbox()
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      console.error('Error create lightBox', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  const handleDeleteFavoriteImage = async (imageId: string, lightBoxId: string, size: any, category: any) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.delete(`favorites/${imageId}?list_id=${lightBoxId}&image_size_id=${size}&category=${category}`) as typeResponse
      if ((resp as any).status === 'success') {
        const newloghtBox = lightbox.map((light: any) => {
          if(Number(light.id) === Number(lightBoxId)){
            light.images_count = light.images_count && light.images_count > 0 ? light.images_count - 1 : 0;
            return {
              ...light
            }
          }
          return {
            ...light
          }
        })
        setLightBox([...newloghtBox])
        await getMyImagesLightbox(lightBoxId)
        Notiflix.Notify.success(t('Success'))
      } else {
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      console.error('Error create lightBox', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  // if (!isLoading && !lightbox?.length && !searchValue) {
  //   return (
  //     <div className={styles.container}>
  //       <div className={styles.topContainer}>
  //         <Title title={t('Lightbox')} />
  //       </div>
  //       <div className={styles.content}>
  //         <div className={styles.topContent}>
  //           {t('no_lightbox_images')}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  const renderLightbox = () => {
    if(!isLoading && !lightbox?.length && !searchValue) {
      return <Typography textAlign="center"> {t('no_lightbox_images')}</Typography>
    }
    if (searchValue && !lightbox?.length) {
      return renderNoResults()
    }
    return (
      <Paper elevation={3}>
        {lightbox.map((lightboxItem) => (
          <Fragment key={`lightBox-${lightboxItem.id}`}>
            <Lightbox
              onDeleteFavoriteImage={handleDeleteFavoriteImage}
              countImages={lightboxItem?.images_count}
              listImages={listImages}
              onUpdateLightbox={handleUpdateLightBox}
              onDeleteLightBox={handleDeleteLightBox}
              expandedMain={expandedMain}
              onChangeAccordion={handleChangeMainAccordion}
              lightboxId={lightboxItem.id}
              title={lightboxItem?.name}
              description={lightboxItem?.description}
              lightboxImage={constants.crop_eps}
            />
          </Fragment>))}
      </Paper>
    )
  }
  const renderSkeletonLightBox = () => (
    <>
      {[1, 2, 3].map((number) => (
        <Fragment key={`${number}-skeleton`}>
          <SkeletonLightBox />
        </Fragment>
      ))}
    </>
  )
  const renderNoResults = () => {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity='info'>{t('no_results_found')}</Alert>
      </Stack>
    )
  }
  return (
    <>
      <CreateNewLightboxDialog
        open={openCreateNewLightbox}
        handleOpen={handleOpenCreateNewLightbox}
        dialogTitle={t('Create new Lightbox')}
        handleConfirm={handleConfirmCreateNewLightbox}
      />
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <Title title={t('Lightbox')} />
        </div>

        <div className={styles.content}>
          <div className={styles.topContent}>
            <CustomTextfield
              className={styles.searchBar}
              size='small'
              placeholder={t('Lightbox Title')}
              value={searchValue}
              onChange={handleSearchChange}
            />

            <IconButton
              onClick={handleSearchByTitle}
              sx={{
                backgroundColor: '#c11b1e',
                '&:hover': {
                  backgroundColor: '#c52d2f'
                }
              }}
            >
              <SearchIcon style={{ color: 'white' }} />
            </IconButton>

            <Button
              variant='contained'
              className={styles.textfieldButton}
              onClick={() => handleOpenCreateNewLightbox()}
            >
              {t('Create new Lightbox')}
            </Button>
          </div>
          {isLoading ? renderSkeletonLightBox() : renderLightbox()}

          {pagination?.totalPages > 1 && <CustomPagination
            shape='rounded'
            count={pagination?.totalPages}
            page={pagination?.currentPage}
            onChange={handleChange}
            size='large'
            className={styles.pagination}
          />}
        </div>
      </div>
    </>
  )
}
