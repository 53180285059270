import { Autocomplete, TextField } from '@mui/material'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

interface IProps {
  setSelectedCountry: (event: any) => void
  selectedCountry: any
  className: string
  size: string
  error?: boolean
  hideLabel?: boolean,
  isRequired?: boolean;
}

countries.registerLocale(enLocale)

export const AutocompleteCountries = (props: IProps) => {
  const { t } = useTranslation()
  const { selectedCountry, setSelectedCountry, className, error } = props;
  const countries = useSelector((state: RootState) => state.countries.countries)

  return (
    <Autocomplete
      id="countries"
      freeSolo
      value={selectedCountry?.label || ''}
      onChange={(event, newValue) => {
        const idData = countries.find((data: any) => data.label === newValue)
        setSelectedCountry({label: newValue, value: idData?.value})
      }}
      options={countries.map((option) => option.label)}
      renderInput={(params) => <TextField required={props?.isRequired} variant='outlined'  {...params} className={className} size="small"
                                                 label={t('Country')} />}
    />
  )
}

export default AutocompleteCountries;