import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import {
  Alert,
  Avatar, Box,
  Button,
  IconButton,
  InputAdornment,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, Typography
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModelReleaseList } from '../../../../../../shared-components/card/ModelReleaseList'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { Title } from '../../Title'
import { ReleaseFormsList } from './ReleaseFormsList'
import styles from './Releases.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import Skeleton from '@mui/material/Skeleton'

type ImageRelease = {
  id: number,
  mediumURL?: string,
  title: string,
  thumbURL?: string,
  releases?: any
}

export const Releases = () => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [releases, setReleases] = useState([])
  const [openReleaseForms, setOpenReleaseForms] = useState(false)
  const [totalReleases, setTotalReleases] = useState(0)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })

  const getMyReleases = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/images/releases?limit=10&page=${pagination.currentPage}`)
      setReleases(res?.data?.images)
      setPagination({ ...res?.data?.pagination })
      setTotalReleases(res?.data?.total || 0)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching data:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getMyReleases()
  }, [pagination?.currentPage])

  const searchData = async () => {
    if (searchValue) {
      setIsLoading(true)
      try {
        const res = await instance.get(`seller/images/releases?filters[id]=${searchValue}`)
        setReleases(res?.data?.images)
        setPagination({ ...res?.data?.pagination })
        setTotalReleases(res?.data?.total || 0)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error)

        // Show error notification
        Notiflix.Report.failure(
          'Error',
          'Failed to fetch data. Please try again later.',
          'OK'
        )
      }
    } else {
      await getMyReleases()
    }
  }

  const handleChange = (event: any, value: any) => {
    setPagination({ ...pagination, currentPage: value })
  }

  const handleSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value)
  }

  const handleBack = () => {
    setOpenReleaseForms(false)
  }

  const handleAllReleases = async () => {
    setSearchValue('')
    await getMyReleases()
  }

  // if (!isLoading && !releases?.length && !searchValue) {
  //   return (
  //     <div className={styles.container}>
  //       <div className={styles.top}>
  //         <Title title={t('Releases')} />
  //       </div>
  //       <div className={styles.content}>
  //         <Box sx={{
  //           display: 'flex',
  //           justifyContent: 'end'
  //         }}>
  //           <Button
  //             size='small'
  //             className={styles.releasesFormButton}
  //             sx={{
  //               '&:hover': {
  //                 backgroundColor: '#c52d2f'
  //               }
  //             }}
  //             variant='contained'
  //             onClick={() => setOpenReleaseForms(true)}
  //           >
  //             {t('Releases Forms')}
  //           </Button>
  //         </Box>
  //         <div>
  //           {t('no_releases_images')}
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  const renderTableSkeleton = () => {
    return (
      <>
        <TableBody>
          {[1, 2, 3, 4, 5].map((number) => (
            <TableRow key={number} className={styles.tableRow}>
              <TableCell component='th' scope='row'>
                <Skeleton>
                  <Avatar
                    sx={{ width: 60, height: 60 }}
                  />
                </Skeleton>
              </TableCell>
              <TableCell align='left'> <Skeleton /></TableCell>
              <TableCell align='left'>
                <Skeleton />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    )
  }
  const renderTableBody = () => {
    if (searchValue && !releases?.length) {
      return renderNoResultsFound()
    }
    return (
      <>
        <TableBody>
          {releases.map((image: ImageRelease) => (
            <TableRow key={image?.id} className={styles.tableRow}>
              <TableCell component='th' scope='row'>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  alt={image?.title}
                  variant='rounded'
                  src={image?.thumbURL}
                />
              </TableCell>
              <TableCell align='left'>{image?.id}</TableCell>
              <TableCell align='left'>
                {
                  <ModelReleaseList
                    releases={image?.releases || []}
                  />
                }
              </TableCell>
            </TableRow>
          ))} </TableBody>
      </>
    )
  }
  const renderNoResultsFound = () => {
    return (
      <>
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}>
              <Stack sx={{ width: '100%', textAlign: 'center' }} spacing={2}>
                <Alert severity='info' sx={{ display: 'flex', justifyContent: 'center' }}><Typography
                  sx={{ textAlign: 'center' }}>{t('no_results_found')}</Typography></Alert>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {openReleaseForms && (
          <IconButton
            onClick={() => handleBack()}
            className={styles.backIcon}
            size='large'
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Title title={t('Releases')} />
      </div>


      <div className={styles.content}>
        {!openReleaseForms ? (
          <>
            <div className={styles.topContent}>
              {!isLoading && !releases?.length && !searchValue ? '' : <div className={styles.allReleases} onClick={handleAllReleases}>
                {t('All Releases', { releasesNumber: totalReleases })}
              </div>}
              <div className={styles.searchBarContainer}>
                {!isLoading && !releases?.length && !searchValue ? '' : <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                      backgroundColor: '#f5f5f5',
                      paddingRight: 0,
                      height: 36
                    },
                    '& .MuiOutlinedInput-input': {
                      color: 'black'
                    }
                  }}
                  className={styles.searchBar}
                  size='small'
                  placeholder={t('Image ID')}
                  value={searchValue}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Button
                          style={{
                            backgroundColor: '#c11b1e'
                          }}
                        >
                          <SearchIcon onClick={searchData} style={{ color: 'white' }} />
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />}
              </div>

              <Button
                size='small'
                className={styles.releasesFormButton}
                sx={{
                  '&:hover': {
                    backgroundColor: '#c52d2f'
                  }
                }}
                variant='contained'
                onClick={() => setOpenReleaseForms(true)}
              >
                {t('Releases Forms')}
              </Button>
            </div>

            <div className={styles.table}>
              {!isLoading && !releases?.length && !searchValue ? <>{t('no_releases_images')}</>:
              <TableContainer component={Paper} className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow className={styles.tableRow}>
                      <TableCell>{t('Image')}</TableCell>
                      <TableCell align='left'>{t('Image ID')}</TableCell>
                      <TableCell align='left'>{t('Releases')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {isLoading ? renderTableSkeleton() : renderTableBody()}
                </Table>
              </TableContainer>}

              {pagination?.totalPages > 1 && <CustomPagination
                shape='rounded'
                count={pagination?.totalPages}
                page={pagination?.currentPage}
                onChange={handleChange}
                size='large'
                className={styles.pagination}
              />}
            </div>
          </>
        ) : (
          <ReleaseFormsList />
        )}
      </div>
    </div>
  )
}
