import { TableCell, TableRow } from '@mui/material'
import styles from './Earnings.module.scss'
import Skeleton from '@mui/material/Skeleton'
import React from 'react'


export const LoadingTable = () => {
  return (
    <>
      {[1,2,3,4,5].map((number) => (
        <TableRow className={styles.tableRow} key={`loading-${number}`}>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
          <TableCell className={styles.tableCell}>
            <Skeleton />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

export default LoadingTable()