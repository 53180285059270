import { Step1 } from '../upload/Step1'
import { Step2 } from '../upload/Step2'
import { Step3 } from '../upload/Step3'
import { Step4 } from '../upload/Step4'
import ButtonNext from '../upload/ButtonNext'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import Notiflix from 'notiflix'
import instance from '../../../../../../../providers/axios.config'
import { setUploadSession, setUploadSessionStep1 } from '../../../../../../../redux/reducers/uploadSessionReducer'
import { TabValue } from '../../../AccountDetailsSeller'
import Backdrop from '@mui/material/Backdrop'
import { Alert } from '@mui/material';
import useErrorFilesImages from '../../../../../../../hooks/useErrorFilesImages'
import { typeResponse } from '../../../../../../../utils/types'

interface IProps {
  filterValue?: string | null,
  onChangeFilterValue?: (key: any) => void;
  onChangeSections?: (key: TabValue) => void;
}
export const Examination = (props: IProps) => {
  const { filterValue, onChangeSections,  } = props
  const [firstStepFiles, setFirstStepFiles] = useState<File[]>([])
  const { t } = useTranslation()
  const [openImageDetails, setOpenImageDetails] = useState<
    string | number | undefined
  >()

  const [currentStep, setCurrentStep] = useState(1)
  const dispatch = useDispatch()
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const account = useSelector((state: RootState) => state.user.usersData)


  const handleUpload = async (formData: any, save:  1 | 0 = 1) => {
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>', // Set the Content-Type header for FormData
      },
    }
    formData.append('nextStep', JSON.stringify(save))
    try {
      // Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post('session/upload', formData, config) as typeResponse;
      if ((resp as any).status === 'success') {
        setCurrentStep((prev) => prev + 1)
        dispatch(setUploadSessionStep1({
          images: {jpg: [], eps: [], png: []},
          unsavedImages: []
        }))
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      Notiflix.Loading.remove()
      // Handle error
      console.error('Error uploading files:', error)
    }
  }

  const step1Save = async (formData: any, save: 1 | 0 = 1) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator
    await handleUpload(formData, save)
  }

  const step2Save = async (save: 1 | 0 = 1) => {
    const imagesToUpdate = uploadSession?.images

    if (imagesToUpdate && imagesToUpdate.length > 0) {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator

        // Create an array of updated images data using map
        const updatedImagesData = imagesToUpdate.map((image) => ({
          imageId: image.imageId,
          is_ai: Number(image.isAi),
          is_adult: Number(image.isAdult),
          is_editorial: Number(image.isEditorial),
          network_ai: image.networkAi,
        }))

        // Make the API call with updated images data
        const resp = await instance.post(
          `session/upload`,
          {
            nextStep: save,
            options: updatedImagesData,
          }
        )

        // Show success notification
        Notiflix.Notify.success(t('Success'))
      } catch (error) {
        // Handle error
        console.error('Error updating images:', error)

        // Show error notification
        Notiflix.Report.failure(
          'Error',
          'Failed to update images. Please try again later.',
          'OK'
        )
      } finally {
        Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
      }
    }
  }

  const step3Save = async (save: 1 | 0 = 1) => {
    let response: any = {};
    const imagesToUpdate = uploadSession?.images
    const validateSave = uploadSession?.images.some((image) => image.description?.length < 30) ||
      uploadSession?.images.some(
        (image) =>
          image.keywords?.length < 7);
    const checkValidationByStep = save === 0 || !uploadSession?.images?.length ? false : validateSave;
    if (checkValidationByStep) {
      return
    }
    if ((imagesToUpdate && imagesToUpdate.length > 0) || !uploadSession?.images?.length) {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator

        const findElementSelected = (imagesToUpdate || []).filter((element: any) => element.description?.length > 30 && element?.keywords?.length > 6)
        const updatedImagesDataSave = findElementSelected.map((image) => ({
          imageId: image.imageId,
          keywords: image?.defaultKeywords?.length ? [...image.defaultKeywords, ...image.keywords] : [...image.keywords],
          description: image.description,
        }))
        // Create an array of updated images data using map
        const updatedImagesData = (imagesToUpdate || []).map((image) => ({
          imageId: image.imageId,
          keywords: image?.defaultKeywords?.length ? [...image.defaultKeywords, ...image.keywords] : [...image.keywords],
          description: image.description,
        }))
        if(!uploadSession?.images?.length) {
          const resp = await instance.post(
            `session/upload`,
            {
              nextStep: save,
            }
          ) as typeResponse;
          response = resp;
          if ((resp as any).status === 'success') {
            Notiflix.Notify.success(t('Success'))
            await getUploadSession()
          } else {
            Notiflix.Report.failure(
              'Error',
              `${resp?.message}`,
              'OK'
            )
          }
          return resp;
        } else {
          const resp = await instance.post(
            `session/upload`,
            {
              nextStep: save,
              description_and_keywords: save === 0 ? updatedImagesDataSave : updatedImagesData,
            }
          ) as typeResponse;
          response = resp;
          if ((resp as any).status === 'success') {
            await retrieveData()
            Notiflix.Notify.success(t('Success'))
          } else {
            Notiflix.Report.failure(
              'Error',
              `${resp?.message}`,
              'OK'
            )
          }
          return resp;
        }

        // Make the API call with updated images data
      } catch (error) {
        // Handle error
        console.error('Error updating images:', error)

        // Show error notification
        Notiflix.Report.failure(
          'Error',
          'Failed to update images. Please try again later.',
          'OK'
        )
      } finally {
        Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
      }
    }
    return response;
  }

  const retrieveData = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator
      const res = await instance.get('session/retrieve')
      if (res.data.step !== 1) {
        setCurrentStep(res.data.step)
        dispatch(setUploadSession(res.data))
      } else {
        if(res?.data) {
          dispatch(setUploadSessionStep1(res?.data))
        }
      }
      Notiflix.Loading.remove()
    } catch (error) {
      // Handle error
      console.error('Error fetching upload session:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch upload session data. Please try again later.',
        'OK'
      )
    } finally {
      Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
    }
  }

  const goToNextStep = async (save: 1 | 0 = 1) => {
    const formData = new FormData()
    // firstStepFiles.forEach((file, index) => {
    //   formData.append(`files[${index}]`, file)
    // })

    if (currentStep === 1) {
      await step1Save(formData, save)
    } else if (currentStep === 2) {
      await step2Save(save)
    } else if (currentStep === 3) {
      return await step3Save(save)
    }

    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator
      const res = await instance.get('session/retrieve')
      if (res.data.step !== 1) {
        setCurrentStep(res.data.step)
        dispatch(setUploadSession(res.data))
      } else {
        if(res?.data) {
          dispatch(setUploadSessionStep1(res?.data))
        }
      }
      Notiflix.Loading.remove()
    } catch (error) {
      // Handle error
      console.error('Error fetching upload session:', error)

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch upload session data. Please try again later.',
        'OK'
      )
    } finally {
      Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
    }
  }
  const getUploadSession = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const res = await instance.get('session/retrieve') as typeResponse;
      if (res.data?.step && res.data.step !== 1) {
        setCurrentStep(res.data?.step)
        dispatch(setUploadSession(res.data))
      } else {
        if(res?.data) {
          dispatch(setUploadSessionStep1(res?.data))
        }
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching upload session:', error)
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch upload session data. Please try again later.',
        'OK'
      )
    } finally {
      Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
    }
  }
  useEffect(() => {
    if (uploadSession) {
      setCurrentStep(uploadSession.step)
    }
    if (!uploadSession) getUploadSession()
  }, [])

  const handleOpenDetails = (imageId: string | number) => {
    setOpenImageDetails(imageId)
  }

  const handleBack = () => {
    setOpenImageDetails(undefined)
  }

  useEffect(() => {
    setOpenImageDetails(undefined)
  }, [filterValue])

  const handleDeleteAllImages = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.delete(`session/delete`)
      if ((resp as any).status === 'success') {
        setCurrentStep(1)
        setFirstStepFiles([])
        dispatch(setUploadSession(null))
      }
    } catch (error) {
      // Handle error
      console.error('Error delete files:', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  return (
    <div style={{position: "relative"}}>
      {currentStep === 1 && (
        <Step1
          setFirstStepFiles={setFirstStepFiles}
          maxLength={10}
        />
      )}
      {currentStep === 2 && <Step2 onDeleteAllImages={handleDeleteAllImages} saveStep={() => goToNextStep(0)} />}
      {currentStep === 3 && <Step3 onDeleteAllImages={handleDeleteAllImages} saveStep={() => goToNextStep(0)} />}
      {currentStep === 4 && <Step4 setCurrentStep={setCurrentStep} onChangeSections={onChangeSections} onDeleteAllImages={handleDeleteAllImages}/>}
      {currentStep < 4 && (
        <>
          <ButtonNext isExamination currentStep={currentStep} onNextStep={goToNextStep} />
        </>
      )}
      {account?.inExamination && (
        <Backdrop
          sx={{ position: 'absolute', opacity: 0.1, zIndex: (theme) => theme.zIndex.drawer -1 }}
          open={account?.inExamination || !account?.id_file || false}
        >
          <Alert severity="warning">
            {t('examination_alert')}
          </Alert>
        </Backdrop>
      )}
    </div>
  )
}

export default Examination;
