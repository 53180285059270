import React from 'react'
import { Avatar, ListItem, Typography } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export const LoadingNotification = () => {
  return(
    <ListItem>
      <Avatar><Skeleton/></Avatar>
      <Typography sx={{width: '100%'}}><Skeleton/></Typography>
      <Typography sx={{width: '100%'}}><Skeleton/></Typography>
    </ListItem>
  )
}
export default LoadingNotification;