import { Button, CircularProgress, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Recaptcha } from '../../../shared-components/recaptcha/Recaptcha'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import styles from './ForgotPassword.module.scss'
import instance from '../../../../providers/axios.config'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  const [isCaptchaOpen, setIsCaptchaOpen] = useState<boolean>(false)
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [message, setMessage] = useState('')

  const resetPassword = async (email: string) => {
    try {
      const response: any = await instance.post('auth/forgot-password', {
        email,
      })
      if (response.status === 'success') {
        setMessage(response.message)
      } else {
        setErrorMessage(t('Error') + ': ' + response.message)
      }
    } catch (error: any) {
      setErrorMessage(t('Error') + ': ' + error.message)
    }
  }

  const handleResetPassword = async () => {
    setIsSubmitting(true)
    setErrorMessage('')
    setMessage('')
    await resetPassword(email)
    setIsSubmitting(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography className={styles.welcome}>
          {t('Forgot_password')}
        </Typography>
        <CustomTextfield
          variant='outlined'
          label={t('Email')}
          className={styles.textField}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <Recaptcha isOpen={isCaptchaOpen} setIsOpen={setIsCaptchaOpen} />
        {isSubmitting ? (
          <CircularProgress className={styles.progress} />
        ) : (
          <Button
            onClick={handleResetPassword}
            className={styles.button}
            disabled={isSubmitting}
          >
            {t('Continue')}
          </Button>
        )}
        {errorMessage && (
          <Typography className={styles.errorMessage}>
            {errorMessage}
          </Typography>
        )}
        {message && (
          <Typography className={styles.successMessage}>{message}</Typography>
        )}
      </div>
    </div>
  )
}
