import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type advanceSearch = {
  id?: string,
  author?: string,
  date?: string | Date,
}
export interface ISearchStore {
  filters: {
    search: string
    selectedCategories: string
    sortBy: string
    selectedTypes: string[]
    orientation: string
    numberOfItems: string
    advancedSearch?: advanceSearch
    ai_network?: string
    model?: string
  }
}

let initialState: ISearchStore = {
  filters: {
    search: "",
    selectedCategories: "",
    sortBy: "",
    selectedTypes: [],
    orientation: "",
    numberOfItems: "",
    advancedSearch: {},
    ai_network: "",
    model: ""
  },
}

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<string>) => {
      state.filters.selectedCategories = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.filters.sortBy = action.payload
    },
    setTypes: (state, action: PayloadAction<string[]>) => {
      state.filters.selectedTypes = action.payload
    },
    setOrientation: (state, action: PayloadAction<string>) => {
      state.filters.orientation = action.payload
    },
    setNumberOfItems: (state, action: PayloadAction<string>) => {
      state.filters.numberOfItems = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.filters.search = action.payload
    },
    setAdvancedSearch: (state, action: PayloadAction<advanceSearch>) => {
      state.filters.advancedSearch = action.payload
    },
    setAiNetwork: (state, action: PayloadAction<string>) => {
      state.filters.ai_network = action.payload
    },
    setModel: (state, action: PayloadAction<string>) => {
      state.filters.model = action.payload
    },
    resetFilters: (state) => {
      state.filters = {...initialState.filters}
    },
  },
})

export const {
  setSearch,
  setCategories,
  setSortBy,
  setTypes,
  setOrientation,
  setNumberOfItems,
  setAdvancedSearch,
  setAiNetwork,
  resetFilters,
  setModel,
} = searchSlice.actions

export default searchSlice.reducer
