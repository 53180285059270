import { Box, Button, Chip, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { t } from 'i18next'
import React from 'react'
import { CustomTextfield } from '../../../../../../../shared-components/textfield/CustomTextField'
import { UploaderContainerStep4 } from '../UploadContainerStep4'
import styles from './ReleasesTypes.module.scss'
import { Controller } from 'react-hook-form'
import { setInitialValuesModel } from '../Step4'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../redux/reducers/rootReducer'
import { IModel } from '../../../../../../../../utils/types'
import { useTranslation } from 'react-i18next'

interface IProps {
  image: string
  id: string
  control?: any,
  index?: number,
  field?: IModel,
  modelAppend?: (key?: any) => {},
  fieldArrayName?: string,
  errors?: any,
  modelRemove?: any,
  watch?: any,
  setValue?: any,
  name?: string,
}

export const Model = (props: IProps) => {
  const {
    image,
    id,
    control,
    modelRemove,
    fieldArrayName,
    index,
    modelAppend,
    field,
    errors,
    watch,
    setValue,
    name,
  } = props
  const { t } = useTranslation()
  const accountInfo = useSelector((state: RootState) => state.user.usersData)

  const dataFile = watch(`${fieldArrayName}.model[${index}].file`);
  const dataFileModelFile = watch(`${fieldArrayName}.model[${index}].model.model_path`)
  const dataFileModelPhotographer = watch(`${fieldArrayName}.model[${index}].photographer.photographer_signature`)
  const dataFileModelWithness = watch(`${fieldArrayName}.model[${index}].witness.witness_signature`)
  return (
    <Box display={'flex'} flexDirection='column' alignItems={'center'}>
      <Controller
        name={`${fieldArrayName}.model[${index}].file`}
        control={control}
        render={({ field: { ref, ...newField } }) =>
          <UploaderContainerStep4
            title={''}
            value={dataFile}
            description={t('drop_pdf_jpg')}
            handleAddedFile={(file: File | null) => {
              if(file) {
                setValue(`${fieldArrayName}.model[${index}].model`, {
                  model_name: '',
                  model_address: '',
                  model_date_signed: '',
                  model_dob: '',
                  model_email: '',
                  model_phone: '',
                  model_signature: undefined,
                  model_path: undefined,
                })
                setValue(`${fieldArrayName}.model[${index}].witness`, {
                  witness_name: '',
                  witness_date_signed: '',
                  witness_signature: undefined,
                })
              }
              newField.onChange({
                target: {
                  value: file,
                  name: `${fieldArrayName}.model[${index}].file`
                }
              })
              // setValue(`${fieldArrayName}.model[${index}].file`, file)
            }}
          />}
      />

      {dataFile &&  <Box sx={{width: 250}}><Controller
        name={`${fieldArrayName}.model[${index}].name_model`}
        control={control}
        render={({ field: { ref, ...newField } }) =>
          <CustomTextfield className={styles.textfield}
                           size='small'
                           inputRef={ref}
                           error={errors?.name_model}
                           helperText={errors?.name_model ? t(errors?.name_model?.message) : ''}
                           variant='outlined'
                           label={t('Full Name')}
                           {...newField} />}
      /></Box>}
      <>
        <Divider
          sx={{
            borderColor: 'grey.500',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Chip label={t('OR')}></Chip>
        </Divider>
        <Box sx={{alignItems: 'right'}}>
          <Button
            className={styles.newPropertyButton}
            onClick={() => {
              if (modelAppend) {
                modelAppend({ ...setInitialValuesModel(accountInfo) })
              }
            }}
          >
            {t('New Model Release')}
          </Button>
        </Box>
        <div style={{ position: 'relative' }} className={styles.modelPdf}>
          {dataFile?.name && <div className={styles.mask}></div>}

          <div className={styles.termsAndConditions}>
            {t('Model_release_terms')}
          </div>
          <div>
            <div className={styles.contentFileUpload}>
              <div>
                <img src={image} alt='img' className={styles.image} />
                <br />
                <span className={styles.titleText}>{t('Name')}:</span>{' '}
                <span>{name}</span>
              </div>
              <div>
                <span className={styles.titleText}>Model file</span>
                <Controller
                  name={`${fieldArrayName}.model[${index}].model.model_path`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      description={t('drop_pdf_jpg')}
                      value={dataFileModelFile}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `${fieldArrayName}.model[${index}].model.model_path`
                          }
                        })
                      }}
                    />}
                />
                <div
                  className={styles.errorText}>{errors?.model?.model_path ? t(errors?.model?.model_path?.message) : ''}</div>
              </div>
            </div>
            <br />
            <p className={styles.titleText}>Model Information: </p>
            <Controller
              name={`${fieldArrayName}.model[${index}].model.model_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 error={errors?.model?.model_name}
                                 helperText={errors?.model?.model_name ? t(errors?.model?.model_name?.message) : ''}
                                 variant='outlined'
                                 label={t('Full Name')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.model[${index}].model.model_dob`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Birth')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <span
              className={styles.errorText}>{errors?.model?.model_dob ? t(errors?.model?.model_dob?.message) : ''}</span>
            <Controller
              name={`${fieldArrayName}.model[${index}].model.model_address`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 label={t('Full Address')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.model[${index}].model.model_phone`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 error={errors?.model?.model_phone}
                                 helperText={errors?.model?.model_phone ? t(errors?.model?.model_phone?.message) : ''}
                                 variant='outlined'
                                 label={t('Phone')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.model[${index}].model.model_email`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 inputRef={ref}
                                 variant='outlined'
                                 error={errors?.model?.model_email}
                                 helperText={errors?.model?.model_email ? t(errors?.model?.model_email?.message) : ''}
                                 label={t('Email')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.model[${index}].model.model_date_signed`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    {...newField}
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <span
              className={styles.errorText}>{errors?.model?.model_date_signed ? t(errors?.model?.model_date_signed?.message) : ''}</span>
            <div>
              <span className={styles.titleText}>Signature</span>
              <Controller
                name={`${fieldArrayName}.model[${index}].model.model_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg')}
                    value={field?.model?.model_signature}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.model[${index}].model.model_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.model?.model_signature ? t(errors?.model?.model_signature?.message) : ''}</span>
            </div>
            <br />
            <div className={styles.formContainer}>
              <div className={styles.titleText}>Author Information:</div>
              <Controller
                name={`${fieldArrayName}.model[${index}].photographer.photographer_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Full Name')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.model[${index}].photographer.photographer_address`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   inputRef={ref}
                                   disabled={!!accountInfo?.addresses?.[0]?.address}
                                   variant='outlined'
                                   label={t('Full Address')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.model[${index}].photographer.photographer_phone`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled={!!accountInfo?.addresses?.[0]?.phone}
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Phone')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.model[${index}].photographer.photographer_email`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                                   size='small'
                                   disabled
                                   inputRef={ref}
                                   variant='outlined'
                                   label={t('Email')}
                                   {...newField} />}
              />
              <Controller
                name={`${fieldArrayName}.model[${index}].photographer.photographer_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small' }
                      }}
                    />
                  </LocalizationProvider>}
              />
            </div>
            <div>
              <span className={styles.titleText}>Signature</span>
              <Controller
                name={`${fieldArrayName}.model[${index}].photographer.photographer_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg')}
                    value={dataFileModelPhotographer}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.model[${index}].photographer.photographer_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.photographer?.photographer_signature ? t(errors?.photographer?.photographer_signature?.message) : ''}</span>
            </div>
            <br />
            <p className={styles.titleText}>{t('witness_information')} </p>
            <Controller
              name={`${fieldArrayName}.model[${index}].witness.witness_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                                 size='small'
                                 error={errors?.witness?.witness_name}
                                 helperText={errors?.witness?.witness_name ? t(errors?.witness?.witness_name?.message) : ''}
                                 inputRef={ref}
                                 variant='outlined'
                                 label={t('Full Name')}
                                 {...newField} />}
            />
            <Controller
              name={`${fieldArrayName}.model[${index}].witness.witness_date_signed`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...newField}
                    className={styles.textfield}
                    label={t('Date Signed')}
                    format='dd-MM-yyyy'
                    disableFuture
                    slots={{
                      textField: CustomTextfield
                    }}
                    slotProps={{
                      textField: { size: 'small' }
                    }}
                  />
                </LocalizationProvider>}
            />
            <span
              className={styles.errorText}>{errors?.witness?.witness_date_signed ? t(errors?.witness?.witness_date_signed?.message) : ''}</span>
            <div>
              <span className={styles.titleText}>Signature</span>
              <Controller
                name={`${fieldArrayName}.model[${index}].witness.witness_signature`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <UploaderContainerStep4
                    title={''}
                    description={t('drop_pdf_jpg')}
                    value={dataFileModelWithness}
                    handleAddedFile={(file: File | null) => {
                      newField.onChange({
                        target: {
                          value: file,
                          name: `${fieldArrayName}.model[${index}].witness.witness_signature`
                        }
                      })
                    }}
                  />}
              />
              <span
                className={styles.errorText}>{errors?.witness?.witness_signature ? t(errors?.witness?.witness_signature?.message) : ''}</span>
            </div>
            {index && index >= 1 ? <Button
              className={styles.deleteModelButton}
              onClick={() => {
                if (modelRemove) {
                  modelRemove(index)
                }
              }}
            >
              {t('delete_model')}
            </Button> : null }
          </div>
        </div>
      </>
    </Box>
  )
}
