import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const NoImagesFound = () => {
  const { t } = useTranslation()
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: 8}}>

      <Typography fontSize={28} fontWeight="bold">{t('no_results_found')}</Typography>
      <Typography>{t('no_results_found_description')}</Typography>
    </Box>
  )
}

export default NoImagesFound