import styles from './Publications.module.scss'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Title } from '../../Title'
import { Outlet, useLocation } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const getTitleAndDescription = (filterValue: string | null, t:any) => {
  switch (filterValue) {
    // case '':
    //   return {
    //     title: t('My Publications'),
    //     description: t('Deletion_avaiable'),
    //   }
    case 'unfinished':
      return { title: t('Unfinished'), description: '' }
    case 'licensing':
      return { title: t('Licensing'), description: t('Examination_text') }
    case 'declined':
      return { title: t('Declined'), description: t('Examination_text') }
    case 'examination':
      return { title: t('Examination'), description: t('Examination_text') }
    default:
      return {
        title: t('My Publications'),
        description: t('Deletion_avaiable'),
      }
      // return { title: t('Publications'), description: t('Deletion_avaiable') }
  }
}
export const PublicationsRoot = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const splitPathname = location.pathname.split('/')

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {/*{change}*/}
        {/*{openImageDetails && (*/}
        {/*  <IconButton*/}
        {/*    onClick={() => handleBack()}*/}
        {/*    className={styles.backIcon}*/}
        {/*    size='large'*/}
        {/*  >*/}
        {/*    <ArrowBackIcon />*/}
        {/*  </IconButton>*/}
        {/*)}*/}
        <Title
          title={location?.pathname ? getTitleAndDescription(splitPathname[splitPathname.length - 1], t).title : ''}
          description={location?.pathname ? getTitleAndDescription(splitPathname[splitPathname.length - 1], t).description : ''}
        />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}