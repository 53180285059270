import { TextField } from '@mui/material'

export const CustomTextfield = ({ ...props }) => {
  return (
    <TextField
      {...props}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: '#c11b1e',
          },
        '& .MuiFormLabel-root.Mui-focused': {
          color: '#c11b1e',
        },
        '& .MuiFormHelperText-root.Mui-error': {
          fontSize: '12px',
          color: '#c11b1e'
        },

      }}
    />
  )
}
