import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { red } from '@mui/material/colors'

interface IProps {
  open: boolean
  handleClose: () => void
}

export const DialogCancelPaymentImage = (props: IProps) => {
  const { open, handleClose } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation()

  const handleCloseModal = () => {
    searchParams.delete('session_id')
    searchParams.delete('order_id')
    searchParams.delete('cancel')
    setSearchParams(searchParams)
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, flexDirection: 'column', padding: 0}}>
        <Box sx={{backgroundColor: red[500], width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
          <HighlightOffIcon sx={{ color: 'white', padding: '32px 60px', fontSize: 100 }}/>
        </Box>
        <Box sx={{padding: '32px 60px'}}>
          <Typography fontSize={28} sx={{color: red[600]}}>{t('payment_failed')}</Typography>
          <Typography fontSize={16} >{t('payment_failed_description')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  )
}
