import { object, string } from 'yup'

export const schemaValidatorAccount = (isSeller: boolean) => object({
  lastName: string().required(),
  firstName: string().required(),
  address: string().required(),
  city: string().required(),
  zip: string().required(),
  email: string().email(),
  state: string().nullable().notRequired(),
  company: string().nullable(),
  vat_number: string().nullable(),
  phone_number: string().nullable().notRequired(),
  website: string().url().nullable(),
  twitter: string().url().nullable(),
  instagram: string().url().nullable(),
  linkedin: string().url().nullable(),
  facebook: string().url().nullable(),
  pinterest: string().url().nullable(),
  minimum_payout: string().required(),
});
