import { useState, createRef } from 'react';
import styles from './inputCode.module.scss';
import { Box } from '@mui/material'

const InputCode = ({
                     length = 6,
                     name = 'code',
                     label,
                     onGetCode
                   } : {
  length?: number;
  name?: string;
  label?: string;
  classLabel?: string;
  onGetCode?: Function;
}) => {
  const [code, setCode] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [inputRefsArray] = useState(() =>
    Array.from({ length: length }, () => createRef())
  );
  const handleKeyPress = (value: string) => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex < length - 1 ? prevIndex + 1 : 0;
      const nextInput: any = inputRefsArray?.[nextIndex]?.current;
      nextInput.focus();
      nextInput.select();
      if (!nextIndex && typeof onGetCode !== 'undefined') {
        onGetCode(code.join(''));
      }
      return nextIndex;
    });
    setCode(prevCode => {
      prevCode[currentIndex] = value;
      return prevCode;
    });
  };
  const renderInput = () => {
    return inputRefsArray.map((ref: any, index: number) => (
      <input
        className={styles.input}
        onChange={(ev) => handleKeyPress(ev.target.value)}
        key={`${name}${index}`}
        ref={ref}
        type="text"
        id={`${name}${index}`}
        maxLength={1}
        onClick={(e: any) => {
          setCurrentIndex(index);
          e.target.select();
        }}
      />
    ));
  };

  if (!length) return null;
  return (
    <>
      {label && (
        <label htmlFor={name}>
          {label}
        </label>
      )}
      <Box className={styles.inputContainer}>
        {renderInput()}
      </Box>
    </>
  );
};

export default InputCode;
