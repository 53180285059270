import { Outlet, useLocation } from 'react-router-dom'
import { Title } from '../../Title'
import styles from './Payment.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'

const getTitleAndDescription = (pathname: string | null, t:any) => {
  switch (pathname) {
    case 'history':
      return {
        title: t('payment_history'),
        description: '',
      }
    case 'tax-center':
      return {
        title: 'Tax center',
        description: '',
      }
    default: {
      return { title: t('payment_method'), description: '' }
    }

  }
}

export const PaymentRoot = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const splitPathname = location.pathname.split('/')
  return (
    <div>
      <Title
        title={location?.pathname ? getTitleAndDescription(splitPathname[splitPathname.length - 1], t).title : ''}
        description={location?.pathname ? getTitleAndDescription(splitPathname[splitPathname.length - 1], t).description : ''}
      />
      <div className={styles.content}>
    <Outlet />
      </div>
    </div>
  )
}

export default PaymentRoot;