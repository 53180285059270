import DownloadIcon from '@mui/icons-material/Download'
import FavoriteIcon from '@mui/icons-material/Favorite'
import HelpIcon from '@mui/icons-material/Help'
import HomeIcon from '@mui/icons-material/Home'
import ReceiptIcon from '@mui/icons-material/Receipt'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Box, Divider, FormControl, Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, MenuItem, Select, SelectChangeEvent, useMediaQuery
} from '@mui/material'
import i18next from 'i18next'
import { Fragment, useState } from 'react'
import styles from './AccountDetails.module.scss'
import { Banner } from './Banner'
import { Logout } from '@mui/icons-material'
import instance from '../../../../providers/axios.config'
import { Outlet, useLocation } from 'react-router-dom'
import { useCustomNavigation } from '../../../../hooks/useCustomNavigate'
import { ROUTES } from '../../../../utils/constants'
import { setLoading, setRole, setUserData } from '../../../../redux/reducers/usersReducer'
import { useDispatch } from 'react-redux'
import { CustomInputSelectAccount } from '../../../shared-components/customInputSelectAccount'
import { ConfirmDialog } from '../../../shared-components/dialogs/ConfirmDialog'
import { useTranslation } from 'react-i18next'

const tabs = [
  { key: i18next.t('Home'), icon: <HomeIcon />, path: '', value: 'HOME', filters: [] },
  // {
  //   key: i18next.t('Payment'),
  //   icon: <PaymentsIcon />,
  //   value: 'PAYMENT_METHOD',
  //   path: ROUTES.PAYMENT,
  //   filters: [],
  // },
  {
    key: i18next.t('My Downloads'),
    icon: <DownloadIcon />,
    value: 'MY_DOWNLOADS',
    path: ROUTES.DOWNLOADS,
    filters: [],
  },
  {
    key: i18next.t('My Subscriptions'),
    icon: <MonetizationOnIcon />,
    value: 'MY_SUBSCRIPTIONS',
    path: ROUTES.SUBSCRIPTIONS,
    filters: [],
  },
  {
    key: i18next.t('My Credits'),
    icon: <MonetizationOnIcon />,
    value: 'MY_CREDITS',
    path: ROUTES.CREDITS,
    filters: [],
  },

  {
    key: i18next.t('Invoices'),
    icon: <ReceiptIcon />,
    value: 'MY_INVOICES',
    path: ROUTES.INVOICES,
    filters: [],
  },

  {
    key: i18next.t('Lightbox'),
    icon: <FavoriteIcon />,
    value: 'LIGHTBOX',
    path: ROUTES.LIGHTBOX,
    filters: [],
  },
  {
    key: i18next.t('Support'),
    icon: <HelpIcon />,
    value: 'SUPPORT',
    filters: [],
  },
]
const logoutTab = {
  key: i18next.t('Log out'),
  icon: <Logout />,
  value: 'LOGOUT',
  filters: [],
}
export const AccountDetailsBuyer = () => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigation()
  const dispatch = useDispatch()
  const matchesMobile = useMediaQuery('(max-width:600px)');
  const location = useLocation()
  const splitPathname = location.pathname.split('/')
  const [selectedSection, setSelectedSection] = useState<string>(splitPathname[splitPathname.length - 1] === ROUTES.ACCOUNT ? '' : splitPathname[splitPathname.length - 1])
  const [isOpenedConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const handleChangeSection = (newValue: string) => {
    customNavigate(`/${ROUTES.ACCOUNT}/${newValue}`)
  }

  const handleOpenSettings = () => {
   customNavigate(ROUTES.SETTINGS)
  }
  const handleLogout = async () => {
    dispatch(setLoading(true))
    const resp: { status: string } = await instance.post('/logout')
    if (resp.status === 'success') {
      customNavigate('/')
      dispatch(setLoading(false))
      dispatch(setUserData(null))
      dispatch(setRole(null))
      localStorage.removeItem('authToken')
    }
  }
  const handleChange= (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    customNavigate(`/${ROUTES.ACCOUNT}/${value}`)
    setSelectedSection(value)
  }
  const renderListMobile = () => {
    return (
      <FormControl size="small" className={styles.selectMobile}>
      <Select
        labelId="menu-mobile-account-label"
        id="menu-mobile-account"
        value={selectedSection}
        onChange={handleChange}
        input={<CustomInputSelectAccount />}
      >
        {tabs.slice(0, -1).map((item) => (
          <MenuItem value={item.path} key={item.key} sx={{ color: selectedSection === item.value ? '#c11b1e' : ''}}>
            <ListItem disablePadding>
              <ListItemIcon
                sx={{
                  marginRight: 2,
                  borderRadius: 1,
                  minWidth: 0,
                  color: selectedSection === item.value ? '#c11b1e' : '',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.key}
                primaryTypographyProps={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: '#6c757d',
                }}
              />
            </ListItem>
          </MenuItem>))}
        <Divider/>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setOpenConfirmDialog(true)}>
            <ListItemIcon
              sx={{
                marginRight: 2,
                borderRadius: 1,
                backgroundColor: '#f5f5f5',
                minWidth: 0,
              }}
            >
              {logoutTab.icon}
            </ListItemIcon>
            <ListItemText
              primary={logoutTab.key}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: '#6c757d',
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleChangeSection(tabs[tabs.length - 1].value as 'SUPPORT')
            }}
          >
            <ListItemIcon
              sx={{
                marginRight: 2,
                borderRadius: 1,
                backgroundColor: '#f5f5f5',
                minWidth: 0,
                color:
                  selectedSection === tabs[tabs.length - 1].value
                    ? '#c11b1e'
                    : '',
              }}
            >
              {tabs[tabs.length - 1].icon}
            </ListItemIcon>
            <ListItemText
              primary={tabs[tabs.length - 1].key}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: '#6c757d',
              }}
            />
          </ListItemButton>
        </ListItem>
      </Select>
      </FormControl>

    )
  }
  const renderListDesktop = () => {
    return (
      <>
        <List className={styles.list}>
          {tabs.slice(0, -1).map((item) => (
            <Fragment key={item.key}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleChangeSection(item?.path || '')
                  }}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: 2,
                      borderRadius: 1,
                      backgroundColor: '#f5f5f5',
                      minWidth: 0,
                      color: selectedSection === item.value ? '#c11b1e' : '',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.key}
                    primaryTypographyProps={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: '#6c757d',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Fragment>
          ))}
        </List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setOpenConfirmDialog(true)}>
            <ListItemIcon
              sx={{
                marginRight: 2,
                borderRadius: 1,
                backgroundColor: '#f5f5f5',
                minWidth: 0,
              }}
            >
              {logoutTab.icon}
            </ListItemIcon>
            <ListItemText
              primary={logoutTab.key}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: '#6c757d',
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleChangeSection(tabs[tabs.length - 1].value as 'SUPPORT')
            }}
          >
            <ListItemIcon
              sx={{
                marginRight: 2,
                borderRadius: 1,
                backgroundColor: '#f5f5f5',
                minWidth: 0,
                color:
                  selectedSection === tabs[tabs.length - 1].value
                    ? '#c11b1e'
                    : '',
              }}
            >
              {tabs[tabs.length - 1].icon}
            </ListItemIcon>
            <ListItemText
              primary={tabs[tabs.length - 1].key}
              primaryTypographyProps={{
                fontWeight: 600,
                fontSize: 14,
                color: '#6c757d',
              }}
            />
          </ListItemButton>
        </ListItem>
      </>
    )
  }
  return (
    <div className={styles.container}>
      <Grid container spacing={1}>
        <Grid item md={2} xs={12} sx={{display:'flex', justifyContent: 'left',  position: 'sticky'}}>
          <Box className={`${styles.listContainer} ${styles.leftContainer}`}>
            {matchesMobile ? renderListMobile() : renderListDesktop()}
          </Box>
        </Grid>
        <Grid item md={10} xs={12}>
          <Banner setOpenSettings={handleOpenSettings} />
          <Outlet />
        </Grid>
      </Grid>
      {isOpenedConfirmDialog && (
        <ConfirmDialog
          open={isOpenedConfirmDialog}
          handleOpen={() => setOpenConfirmDialog(false)}
          handleConfirm={() => handleLogout()}
          dialogTitle={t('Logout')}
          dialogMessage={t('Are you sure to logout from your account?')}
        />
      )}
    </div>
  )
}
