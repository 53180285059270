import React from 'react'
import {
  Box, Button,
  Dialog,
  DialogContent,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useSearchParams } from 'react-router-dom'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors'


interface IProps {
  open: boolean
  handleClose: () => void
  dataPayload?: any
}

export const DialogSuccessPaymentPlan = (props: IProps) => {
  const { open, handleClose } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation()

  const handleCloseDialog = () => {
    searchParams.delete('session_id')
    searchParams.delete('order_id')
    searchParams.delete('success')
    setSearchParams(searchParams)
    handleClose()
  }
  return (
    <Dialog open={open}>
      <DialogContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, flexDirection: 'column', padding: 0}}>
        <Box sx={{backgroundColor: green[500], width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
          <CheckCircleOutlineIcon sx={{ color: 'white', padding: '32px 60px', fontSize: 100 }}/>
        </Box>
        <Box sx={{padding: '32px 60px'}}>
          <Typography fontSize={28} sx={{color: green[700]}}>{t('payment_success')}</Typography>
          <Typography marginLeft={2} fontSize={16}>{t('payment_success_description')}</Typography>
        </Box>
        <Button onClick={handleCloseDialog} sx={{marginBottom: 2}}  variant="contained">close</Button>
      </DialogContent>

    </Dialog>
  )
}
