import styles from './Title.module.scss'

interface IProps {
  title: string
  description?: string
}

export const Title = (props: IProps) => (
  <div className={styles.container}>
    <div className={styles.title}>{props.title}</div>

    {props.description && (
      <div className={styles.description}>{props.description}</div>
    )}
  </div>
)
