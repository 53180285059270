import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomCountrySelect } from '../../../../../../shared-components/textfield/CustomCountrySelect'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { Title } from '../../Title'
import styles from './PaymentMethod.module.scss'

export const PaymentMethod = () => {
  const { t } = useTranslation()

  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [vatNumber, setVatNumber] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Title title={t('Payment Method')} />
      </div>

      <div className={styles.content}>
        <div>
          <div className={styles.title}>{t('Billing Address')}</div>

          <div className={styles.textfields}>
            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('First Name')}
              value={firstName}
              onChange={(e: any) => setFirstName(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('Last Name')}
              value={lastName}
              onChange={(e: any) => setLastName(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('Address')}
              value={address}
              onChange={(e: any) => setAddress(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('City')}
              value={city}
              onChange={(e: any) => setCity(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('Zip/Postal Code')}
              value={zipCode}
              onChange={(e: any) => setZipCode(e.target.value)}
            />

            <CustomCountrySelect
              size='small'
              className={styles.textfield}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('State')}
              value={state}
              onChange={(e: any) => setState(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('Company')}
              value={company}
              onChange={(e: any) => setCompany(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('VAT Number')}
              value={vatNumber}
              onChange={(e: any) => setVatNumber(e.target.value)}
            />

            <CustomTextfield
              className={styles.textfield}
              size='small'
              variant='outlined'
              label={t('Phone Number')}
              value={phoneNumber}
              onChange={(e: any) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        {/*<div>*/}
        {/*  <div className={styles.title}>{t('Payment Method')}</div>*/}

        {/*  <PaymentCard />*/}
        {/*</div>*/}

        {/*<div>*/}
        {/*  <div className={styles.title}>{t('Online Payments')}</div>*/}

        {/*  <div className={styles.textfields}>*/}
        {/*    <CustomTextfield*/}
        {/*      className={styles.textfield}*/}
        {/*      size='small'*/}
        {/*      variant='outlined'*/}
        {/*      label={t('Paypal Email')}*/}
        {/*      value={paypalEmail}*/}
        {/*      onChange={(e: any) => setPaypalEmail(e.target.value)}*/}
        {/*    />*/}

        {/*    <CustomTextfield*/}
        {/*      className={styles.textfield}*/}
        {/*      size='small'*/}
        {/*      variant='outlined'*/}
        {/*      label={t('Skrill Email')}*/}
        {/*      value={skrillEmail}*/}
        {/*      onChange={(e: any) => setSkrillEmail(e.target.value)}*/}
        {/*    />*/}

        {/*    <CustomTextfield*/}
        {/*      className={styles.textfield}*/}
        {/*      size='small'*/}
        {/*      variant='outlined'*/}
        {/*      label={t('Revolut Phone')}*/}
        {/*      value={revolutPhone}*/}
        {/*      onChange={(e: any) => setRevolutPhone(e.target.value)}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <div className={styles.buttonContainer}>
        <Button variant='contained' className={styles.textfieldButton}>
          {t('Save')}
        </Button>

        <Button variant='contained' className={styles.textfieldButton}>
          {t('Cancel')}
        </Button>
      </div>
    </div>
  )
}
