import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material'
import Notiflix from 'notiflix'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useCustomNavigation } from '../../../../hooks/useCustomNavigate'
import instance from '../../../../providers/axios.config'
import { NavigationEnum } from '../../../../utils/constants'
import { CustomCheckbox } from '../../../shared-components/checkbox/CustomCheckbox'
import { Recaptcha } from '../../../shared-components/recaptcha/Recaptcha'
import { CustomCountrySelect } from '../../../shared-components/textfield/CustomCountrySelect'
import { CustomPasswordField } from '../../../shared-components/textfield/CustomPasswordField'
import { CustomTextfield } from '../../../shared-components/textfield/CustomTextField'
import styles from './Register.module.scss'

export const Register = () => {
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState<string>('')
  const [whoAreYouValue, setWhoAreYouValue] = useState('buyer')
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] =
    useState(false)
  const [newsletterChecked, setNewsletterCheck] = useState(false)
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false)
  const [errorFeedback, setErrorFeedback] = useState({ message: '', name: '' })
  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useCustomNavigation()
  const signUpUser = async () => {
    if (!isCaptchaOpen) {
      setErrorFeedback({ message: t('Captcha is required'), name: 'captcha' })
      setIsLoading(false)
      return
    }
    setIsLoading(true) // Start l

    try {
      setErrorFeedback({ message: '', name: '' })
      const requiredFields = [
        'username',
        'email',
        'country',
        'firstName',
        'lastName',
        'password',
        'confirmPassword',
      ]
      for (const fieldName of requiredFields) {
        if (!eval(fieldName)) {
          if (fieldName === 'confirmPassword') {
            setErrorFeedback({
              message: `${t('Confirm Password')} ${t('is required')}.`,
              name: fieldName,
            })
          } else
            setErrorFeedback({
              message: `${t(
                fieldName.charAt(0).toUpperCase() +
                  fieldName.slice(1).toLowerCase()
              )} ${t('is required')}.`,
              name: fieldName,
            })
          return
        }
        if (!termsAndConditionsChecked || !privacyPolicyChecked) {
          setErrorFeedback({
            message: t('Terms & Conditions and Privacy Policy are required.'),
            name: 'termsOrPrivacy',
          })
          return
        }
      }
      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        setErrorFeedback({ message: t('Invalid email address'), name: 'email' })
        return
      }

      if (password !== confirmPassword) {
        setErrorFeedback({
          message: t('Passwords do not match'),
          name: 'confirmPassword',
        })
        return
      }

      const resp = await instance.post('auth/register', {
        email,
        first_name: firstName,
        last_name: lastName,
        username,
        country_id: parseInt(country),
        timezone: 'Europe/Bucharest',
        password,
        password_confirmation: confirmPassword,
        terms_and_conditions: termsAndConditionsChecked,
        account_type: whoAreYouValue,
      })
      Notiflix.Report.success(t('Accout created successfully.'), t(''), t('OK'))
      navigate('/login')
    } catch (error: any) {
      console.error('Registration failed:', error)

      if (error.response && error.response.data) {
        setErrorFeedback({
          message: error.response.data.message,
          name: 'apiError',
        })
      } else {
        setErrorFeedback({
          message: t('An error occurred during registration'),
          name: 'apiError',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography className={styles.welcome}>{t('Sign up')} </Typography>
        <Grid gap={4} container>
          <Grid flex={1} item>
            <CustomTextfield
              variant='outlined'
              error={errorFeedback.name === 'firstName'}
              label={t('First name')}
              className={styles.textField}
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
            />
          </Grid>
          <Grid flex={1} item>
            <CustomTextfield
              className={styles.sellerTextfield}
              select
              label={t('Who are you')}
              variant='outlined'
              value={whoAreYouValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhoAreYouValue(e.target.value)
              }
            >
              <MenuItem value='buyer'>{t('Buyer')}</MenuItem>
              <MenuItem value='seller'>{t('Seller')}</MenuItem>
            </CustomTextfield>
          </Grid>
        </Grid>
        <Grid gap={4} container>
          <Grid flex={1} item>
            <CustomTextfield
              variant='outlined'
              error={errorFeedback.name === 'lastName'}
              label={t('Last name')}
              className={styles.textField}
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
            />
          </Grid>
          <Grid flex={1} item>
            <CustomTextfield
              variant='outlined'
              error={errorFeedback.name === 'username'}
              label={t('Username')}
              className={styles.textField}
              value={username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUsername(e.target.value)
              }
            />
          </Grid>
        </Grid>
        <div className={styles.gridContainer}>
          <div className={styles.gridContent}>
            <CustomTextfield
              variant='outlined'
              error={errorFeedback.name === 'email'}
              label={t('Email')}
              className={styles.textField}
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <CustomCountrySelect
              size='medium'
              className={styles.textField}
              error={errorFeedback.name === 'country'}
              selectedCountry={country}
              setSelectedCountry={setCountry}
            />
          </div>
          <div className={styles.gridContent}>
            <div className={styles.textField}>
              <CustomPasswordField
                label={t('Password')}
                error={errorFeedback.name === 'password'}
                value={password}
                changePassword={setPassword}
              />
            </div>
            <div className={styles.textField}>
              <CustomPasswordField
                label={t('Confirm password')}
                error={errorFeedback.name === 'confirmPassword'}
                value={confirmPassword}
                changePassword={setConfirmPassword}
              />
            </div>
          </div>
        </div>
        <Typography fontSize={12}>{t('password_message_validator')}</Typography>
        <Typography variant='body2' color={'red'}>
          {errorFeedback.message}
        </Typography>
        <Recaptcha isOpen={isCaptchaOpen} setIsOpen={setIsCaptchaOpen} />
        <FormControl component='fieldset' className={styles.text}>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={termsAndConditionsChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTermsAndConditionsChecked(e.target.checked)
                }
              />
            }
            label={
              <Link target='_blank' to={NavigationEnum.ABOUT} className={styles.linkText}>
                {t('Terms & Conditions')}
              </Link>
            }
            className={styles.checkbox}
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={privacyPolicyChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPrivacyPolicyChecked(e.target.checked)
                }
              />
            }
            label={
              <Link target='_blank' to={NavigationEnum.ABOUT} className={styles.linkText}>
                {t('Privacy Policy')}
              </Link>
            }
            className={styles.checkbox}
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={newsletterChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewsletterCheck(e.target.checked)
                }
              />
            }
            label={
              <Typography className={styles.linkText}>
                {t('Newsletter')}
              </Typography>
            }
            className={styles.checkbox}
          />
        </FormControl>
        <Button
          onClick={!isLoading ? signUpUser : undefined}
          className={styles.button}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress /> : t('Sign up')}
        </Button>
        <div className={styles.no_account}>
          <Typography>{t('Already account')}</Typography>
          <Typography>
            <Link to={NavigationEnum.LOGIN} className={styles.linkText}>
              {t('Sign in')}
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}
