import Radio from '@mui/material/Radio'

export const CustomRadio = ({ ...props }) => {
  return (
    <Radio
      {...props}
      size="small"
      sx={{
        '&.Mui-checked': {
          color: '#c11b1e',
        },
      }}
    />
  )
}
