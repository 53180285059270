import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { NavigationEnum } from '../../../utils/constants'
import { ContactFooter } from './ContactFooter'
import styles from './Footer.module.scss'

export const Footer = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const shouldShowContactFooter =
    location.pathname === NavigationEnum.ASSETS || location.pathname === '/' || location.pathname === NavigationEnum.PLANS_PRICES;

  return (
    <>
      {shouldShowContactFooter && <ContactFooter />}

      <div className={styles.container}>
        <Typography className={styles.text}>
          {t('Copyright', {
            name: 'LayerStock',
            time: new Date().getFullYear(),
          })}
        </Typography>
      </div>
    </>
  )
}
