import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { Title } from '../../Title'
import styles from './DailyEarnings.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import Skeleton from '@mui/material/Skeleton'
import { IDailyEarning } from '../../../../../../../utils/types'

interface IProps {
  selectedDate: string
  handleBack: () => void
}

export const DailyEarnings = (props: IProps) => {
  const { selectedDate } = props

  const { t } = useTranslation()
  const [dailyEarning, setDailyEarning] = useState<IDailyEarning>({})
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 30,
    totalPages: 1
  })
  const handleChange = (event: any, value: number) => {
    setPagination({...pagination, currentPage: value})
  }
  const getEarningData = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`seller/daily-earnings?limit=10&page=${pagination.currentPage}&date=${selectedDate}`)
      setDailyEarning(res?.data || {})
      setPagination({...res?.data?.pagination})
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  useEffect(() => {
    getEarningData()
  }, [selectedDate])

  const renderTableDaily = () => {
    return (
      <TableBody>
        {(dailyEarning?.orders || []).map((order) => (
          <TableRow key={order.id} className={styles.tableRow}>
            <TableCell className={styles.tableCellContent}>
              <img
                src={order?.thumbURL}
                alt='img'
                className={styles.image}
              />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.image_id}
            </TableCell>
            <TableCell className={styles.tableCellContent}>{order?.category?.toUpperCase()}</TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.image_size}
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.plan}
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.before_tax}
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.tax}
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.earnings}
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              {order?.returned || 0}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }
  const renderLoadingSkeleton = () => {
    return (
      <TableBody>
        {[1, 2, 3, 4, 5].map((number) => (
          <TableRow key={number} className={styles.tableRow}>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}> <Skeleton /></TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
            <TableCell className={styles.tableCellContent}>
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <Title title={t('Daily Earnings')} />
        <div style={{ height: 45 }}>{selectedDate}</div>
      </div>
      <Paper elevation={3}>
        <div className={styles.detailsContainer}>
          <div>
            <span className={styles.details}>{t('Daily downloads')}</span>
            <span className={styles.detailsBold}>{dailyEarning?.downloads}</span>
          </div>
          <div>
            <span className={styles.details}>{t('Daily earnings')}</span>
            <span className={styles.detailsBold}>{dailyEarning?.earnings}</span>
          </div>
        </div>
      </Paper>

      <div className={styles.table}>
        <TableContainer component={Paper} className={styles.table}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableRow}>
                <TableCell className={styles.tableCell} />

                <TableCell className={styles.tableCell}>
                  {t('File ID')}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {t('Format')}
                </TableCell>
                <TableCell className={styles.tableCell}>{t('Size')}</TableCell>
                <TableCell className={styles.tableCell}>{t('Plan')}</TableCell>
                <TableCell className={styles.tableCell}>
                  {t('Before Tax')}
                </TableCell>
                <TableCell className={styles.tableCell}>{t('Tax')}</TableCell>
                <TableCell className={styles.tableCell}>
                  {t('Earning')}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {t('Returned')}
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? renderLoadingSkeleton() : renderTableDaily()}
          </Table>
        </TableContainer>

        {pagination?.totalPages > 1 && <CustomPagination
          shape='rounded'
          count={pagination?.totalPages}
          page={pagination?.currentPage}
          onChange={handleChange}
          size='large'
          className={styles.pagination}
        />}
      </div>
    </div>
  )
}
