import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const filter = createFilterOptions<any>();
type PropsTypes = {
  value?: string,
  onSetValue: (key: any) => void,
  options: any,
  label?: string,
  loading?: boolean,
}
export const CustomAutocomplete = (props: PropsTypes) => {
  const { t } = useTranslation()
  const {value, onSetValue, options, label, loading} = props

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue: any) => {
        if (typeof newValue === 'string') {
          onSetValue({
            name: newValue,
          });
        }
        else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onSetValue({
            name: newValue.inputValue,
          });
        } else {
          onSetValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        return filter(options, params);
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="custom-autocomplete"
      options={options}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      renderOption={(props, option: any) => <li {...props}>{option?.name}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }} placeholder={t('search')} size="small" label={label} />
      )}
    />
  )
}

export default CustomAutocomplete;