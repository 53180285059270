import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import styles from './Earnings.module.scss'
import { Title } from '../../Title'

export const EarningRoot = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const splitPathname = location.pathname.split('/')

  const getTitle = (filterValue: string) => {
    switch (filterValue) {
      case null:
        return t('Earnings')
      case 'statistics':
        return t('Statistics')
      default:
        return t('Earnings')
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Title title={getTitle(splitPathname[splitPathname.length - 1])} />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default EarningRoot;