import SettingsIcon from '@mui/icons-material/Settings'
import { Avatar, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../../utils/constants'
import styles from './Banner.module.scss'
import i18next from 'i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/reducers/rootReducer'

interface IProps {
  setOpenSettings: () => void
}

export const Banner = (props: IProps) => {
  const { setOpenSettings } = props
  const { t } = useTranslation()
  const account = useSelector((state: RootState) => state.user.usersData) as any;
  
  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('Pros know')}</div>

      <div className={styles.containerBackground}>
        <img
          loading='lazy'
          height={400}
          src={account?.design_photo || constants.img_wide}
          alt=''
          className={styles.backgroundImage}
        />
      </div>

      <div className={styles.userDetailsContainer}>
        <Avatar src={account?.avatar || constants.img_avatar} variant="rounded" sx={{width: 60, height: 60}}/>
        <div className={styles.textContentContainer}>
          <div className={styles.userName}>{account?.username}</div>

          <div className={styles.statusContainer}>
            <div>
              {account.role.slug === 'seller' ? t('Seller status') + ': ' : t('Contributor status') + ': '}
              <span
                style={{ textTransform: 'uppercase' }}
                className={styles.userName}
              >
                {account?.status.replace('_', ' ')}
              </span>
            </div>

            <div>
              {t('ID') + ': '}
              <span className={styles.userName}>{account?.id}</span>
            </div>
          </div>
        </div>

        <IconButton className={styles.settingsButton} onClick={setOpenSettings}>
          <SettingsIcon sx={{ color: '#c11b1e' }} />
        </IconButton>
        <Typography color='lightslategray' variant='subtitle2' onClick={setOpenSettings} sx={{cursor: 'pointer'}}>
          {i18next.t('My Account')}
        </Typography>
      </div>
    </div>
  )
}
