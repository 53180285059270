import React, { useEffect, useState } from 'react'
import { DragAndDropCustom } from '../dragAndDropCustom'
import {
  Alert,
  Box,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography, useMediaQuery,
  useTheme
} from '@mui/material'
import styles from './containerUploadStep.module.scss'
import CloseIcon from '@mui/icons-material/Close'
import Skeleton from '@mui/material/Skeleton'
import useViewportDimensions from '../../../hooks/useViewportDimensions'
import { ConfirmDialog } from '../dialogs/ConfirmDialog'
import { useTranslation } from 'react-i18next'
import { ImageCardStep1 } from './imageCardStep1'

const colsScreen = (screen: number) => {
  if(screen < 600) {
    return 2
  } else if(screen > 600 && screen < 900) {
    return 4
  }  else if(screen > 900 && screen < 1200) {
    return 5
  } else if(screen > 1200 && screen < 1560) {
    return 6
  } else if(screen > 1560 && screen < 2200) {
    return 8
  } else if(screen > 2200 && screen < 3000) {
    return 8
  } else if(screen > 3000 && screen < 4000) {
    return 10
  } else if(screen > 4000 && screen < 5000) {
    return 12
  } else if(screen > 5000 && screen < 6000) {
    return 10
  } return 10
}
interface ContainerUploadStepProps {
  acceptFile: any;
  title: string;
  description: string;
  onUpload: (key: any, type: string) =>  void;
  uploadImages: any;
  handleDeleteImage: any;
  type: string;
  unsavedImages: any;
  maxLength?: number;
  isLoading: boolean;
  setIsLoading: (key:boolean) => void;
}

const setMessageDiscalaimer = (images: any) => {
  const nameImages = images.map((item: any) => {
    return `${item.originalFilename}`
  })
 return nameImages.join(', ')

}
export const ContainerUploadStep = (props: ContainerUploadStepProps) => {
  const theme = useTheme()
  const {acceptFile,maxLength, title, description, onUpload, uploadImages, handleDeleteImage, type, unsavedImages, isLoading, setIsLoading} = props
  const [closeAlert, setCloseAlert] = useState(false);
  const { t } = useTranslation()
  const screenSize = useViewportDimensions()
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  const sm = useMediaQuery(theme.breakpoints.down('md'));
  const md = useMediaQuery(theme.breakpoints.down('lg'));
  const lg = useMediaQuery(theme.breakpoints.down('xl'));

  useEffect(() => {
    if(closeAlert) {
      setCloseAlert(false)
    }
  }, [JSON.stringify(unsavedImages)])

  const getMasonryColumns = () => {
    if (xs) return { col: 2, gap: 0 }
    if (sm) return { col: 3, gap: 0 }
    if (md) return { col: 4, gap: 1 }
    if (lg) return { col: 4, gap: 1 }
    return { col: 6, gap: 2 }
  }
  const masonryOptions = getMasonryColumns()

  const handleCloseAlert = () => {
    setCloseAlert(true)
  }


  return (
    <Grid container spacing={2}>
      {unsavedImages?.length && !closeAlert ?  <Grid item xs={12} sx={{marginTop: 5, marginBottom: 2}}>
        <Alert severity="error" onClose={handleCloseAlert}>{`${t('images_duplicated_not_saved')}: ${setMessageDiscalaimer(unsavedImages)}`}</Alert>
      </Grid> : null}
      <Grid item xs={12} md={2.5} lg={2}>
        <Typography className={styles.title}>{title}</Typography>
        <Box sx={{ width: '100%', height: 200 }}>
          <DragAndDropCustom onUpload={onUpload} acceptFile={acceptFile} title={title}
                             type={type}
                             setIsLoading={setIsLoading}
                             isLoading={isLoading}
                             maxLength={maxLength}
                             description={description}/>
        </Box>
        <Typography marginTop={1} fontSize={12}>Remaining images {maxLength}</Typography>
      </Grid>
      <Grid item xs={12} md={9.5} lg={10}>
        <ImageList sx={{maxHeight: 450, paddingTop: '20px' }}  cols={colsScreen(screenSize.width)}
                   gap={masonryOptions.gap}>
          {(uploadImages || []).map((item: any, index: any) => (
            <ImageCardStep1 key={`${item.id}-${index}-jpg`} item={item} type={type} index={index} handleDeleteImage={handleDeleteImage}/>
          ))}
        </ImageList>
      </Grid>
    </Grid>
  )
}
