import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const About = () => {
  const { t } = useTranslation()
  return (
    <Container maxWidth='lg'>
      <h1>{t('About')}</h1>
      <div>{t('About_Content')}</div>
    </Container>
  )
}
