import { Button, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material'
import { CustomRadio } from '../../../../../../shared-components/checkbox/CustomRadio'
import React, { useState } from 'react'
import AutocompleteCountries from '../../../../../../shared-components/textfield/AutocompleteCountries'
import styles from './Payment.module.scss'
import { useTranslation } from 'react-i18next'
import { UploadTaxForm } from '../../../../../../shared-components/uploadTaxForm'
import Notiflix from 'notiflix'
import instance from '../../../../../../../providers/axios.config'
import { typeResponse } from '../../../../../../../utils/types'


export const TaxCenter = () => {
  const { t } = useTranslation()
  const [person, setPerson] = useState('pf')
  const [selectedCountry, setSelectedCountry] = useState<{name?: string, value?: string}>(
    {name: '', value: ''}
  )
  const [fileTax, setFileTax] = useState('')
  const handleChangePerson = (ev: any) => {
    setPerson(ev.target.value)
  }
  const handleAddFile = (file: any) => {
    setFileTax(file)
  }
  const handleSaveTaxReform = async () => {
    const formData = new FormData()
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>', // Set the Content-Type header for FormData
      },
    }
    formData.append(`country`,`${selectedCountry.value}`)
    formData.append(`type`,`${person}`)
    formData.append(`file`,fileTax)
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post(`seller/tax-center/tax-forms`, formData, config) as typeResponse;
      if ((resp as any).status === 'success') {
        setSelectedCountry({name: '', value: ''})
        setFileTax('')
        Notiflix.Notify.success(t('Success'))
      }
    } catch (error) {
      // Handle error
      console.error('Error add data:', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  return (
    <div>
      <div className={styles.containerSubmitTax}>
        <Typography fontWeight="bold" fontSize={20} marginBottom={2}>{t('submit_new_tax_form')}</Typography>
        <Typography className={styles.labelStyleTax} color="text.secondary">{t('country_residence')}</Typography>
        <AutocompleteCountries selectedCountry={selectedCountry}
                               size='small'
                               className={styles.textfield}
                               setSelectedCountry={setSelectedCountry}/>
        <FormControl sx={{marginTop: 2}}>
          <Typography className={styles.labelStyleTax} color="text.secondary">{t('type_person')}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="individual"
            name="radio-buttons-group"
            onChange={handleChangePerson}
            value={person}
          >
            <FormControlLabel value="pf" control={<CustomRadio />} label={t('individual_person')} />
            <FormControlLabel value="pj" control={<CustomRadio />} label={t('legal_person')} />
          </RadioGroup>
        </FormControl>
        <UploadTaxForm title={t('attach_tax_form')} description={t('accept_pdf')} filetaxdata={fileTax} handleAddedFile={handleAddFile}/>
        <Button variant='contained' onClick={handleSaveTaxReform} disabled={!fileTax || !selectedCountry?.value}>
          {t('add_tax_form')}
        </Button>
      </div>
    </div>
  )
}

export default TaxCenter;