import DoneIcon from '@mui/icons-material/Done'
import SearchIcon from '@mui/icons-material/Search'
import { Chip } from '@mui/material'
import { JsonParam, useQueryParam, withDefault } from 'use-query-params'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import {
  IFilters,
  initialFiltersConst,
  NavigationEnum,
} from '../../../utils/constants'
import { ClearIcon } from '@mui/x-date-pickers'

interface IProps {
  title: string
  icon?: 'CHECK' | 'SEARCH' | 'CLEAR'
  handleSearch?: () => void
  handleDelete?: () => void
  isNoSearch?: boolean
}

export const FilterChipItem = (props: IProps) => {
  const initialFilters = withDefault(JsonParam, initialFiltersConst)
  const { title, icon = 'CHECK', handleSearch, handleDelete, isNoSearch } = props
  const [filters, setFilters] = useQueryParam<IFilters>(
    'filters',
    initialFilters
  )
  const customNavigate = useCustomNavigation()
  return (
    <Chip
      size='small'
      variant='outlined'
      deleteIcon={
        icon === 'CHECK' ? (
          <DoneIcon style={{ color: '#c11b1e', cursor: 'default' }} />
        ) : icon === 'SEARCH' ? (
          <SearchIcon style={{ color: '#c11b1e' }} />
        ) : icon === 'CLEAR' ? (
          <ClearIcon style={{ color: '#c11b1e' }} />
        ) : (
          <></>
        )
      }
      onClick={() => {
        if (handleSearch && !isNoSearch) {
          customNavigate(
            `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
              JSON.stringify({
                ...filters,
                searchFilter: title,
              })
            )}`
          )
        }
      }}
      sx={{
        margin: '5px',
        borderColor: '#c11b1e',
        backgroundColor: '#f5f5f5',
        '&:hover': {
          background: '#f5f5f5 !important',
        },
        '& .MuiChip-deleteIconOutlinedColorPrimary': {
          color: '#c11b1e',
        },
      }}
      label={title}
      onDelete={() => {
        if (handleSearch && icon !== 'CLEAR') {
          customNavigate(
            `${NavigationEnum.ASSETS}?filters=${encodeURIComponent(
              JSON.stringify({
                ...filters,
                searchFilter: title,
              })
            )}`
          )
        } else if(handleDelete && icon === 'CLEAR'){
          handleDelete()
        }
      }}
    />
  )
}
