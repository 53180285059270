import { Container } from '@mui/material'
import Cookies from 'js-cookie'
import { ChangeEvent, useEffect, useState } from 'react'
import { useCustomNavigation } from '../../../hooks/useCustomNavigate'
import { NavigationEnum } from '../../../utils/constants'
import { FilterChipsList } from '../card/FilterChipsList'
import { SearchBar } from './SearchBar'
import styles from './SearchPlaceholder.module.scss'

interface IProps {
  title: string
  description: string
  searchValue: string
  handleSearchChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

export const SearchPlaceholder = (props: IProps) => {
  const { title, description } = props

  const customNavigate = useCustomNavigation()
  const [cookieLocal, setCookieLocal] = useState<string[]>([])
  const currentSearchTermsCookies = Cookies.get('searchTermsCookie')
  const parsedSearchTermsCookies = currentSearchTermsCookies
    ? JSON.parse(currentSearchTermsCookies)
    : []

  useEffect(() => {
    setCookieLocal([...parsedSearchTermsCookies])
  }, [])

  const handleDeleteData = (data?: string) => {
    if(data) {
      const newCookies = cookieLocal.filter((cook: string) => cook !== data)
      setCookieLocal([...newCookies])
      Cookies.set('searchTermsCookie', JSON.stringify(newCookies))
    }
  }
  const handleSearchData= () => {
    customNavigate(NavigationEnum.ASSETS)
  }
  return (
    <div className={styles.container}>
      <Container maxWidth='lg'>
        <div className={styles.textContainer}>
          <h1>{title}</h1>
          <h3>{description}</h3>
        </div>

        <SearchBar />

        <div className={styles.latestSearchesContainer}>
          <FilterChipsList
            isVisibleChips={true}
            titles={cookieLocal.reverse()}
            icon='CLEAR'
            handleDelete={handleDeleteData}
            handleSearch={handleSearchData}
          />
        </div>
      </Container>
    </div>
  )
}
