import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar, ListItemButton, ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React, { useState } from 'react'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next'
import { notificationsIcons } from '../../../utils/notificationsIcons'
import { green } from '@mui/material/colors'
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { INotifications } from '../../../utils/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

const setColorText = (isRead: number, isHeader?: boolean) => {
  if(isHeader && isRead === 0) {
    return '#e4e6eb'
  } else if(isRead === 1 && isHeader) {
    return '#B0B3B8'
  } else {
    return 'initial'
  }
}
type NotificationItemType = {
  isHeader?: boolean,
  onDeleteNotification: (key: any) => void,
  onDisableNotification:  (key: any) => void,
  onReadNotification: (key: any) => void,
  notification: INotifications,
}

export const NotificationItemList = ({notification, isHeader, onReadNotification, onDeleteNotification, onDisableNotification}: NotificationItemType) => {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const profile = useSelector(
    (state: RootState) => state.user.usersData
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleListItemClick = (event: any, id: any) => {
    event.stopPropagation();
    if(id && notification.isRead === 0) {
      onReadNotification(id)
      setAnchorEl(null);
      setSelectedIndex(0)
    }

  }
  const handleDeleteNotification = () => {
    if(notification.id) {
      onDeleteNotification(notification.id)
      setSelectedIndex(1)
      setAnchorEl(null);
    }
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDisabledNotifications= () => {
    if(notification.type) {
      onDisableNotification(notification.type)
      setSelectedIndex(1)
      setAnchorEl(null);
    }
  }

  return (<>
    <ListItem  onClick={(event) => handleListItemClick(event, notification?.id)} sx={{backgroundColor: isHeader ? '#343a40' : 'transparent', cursor: 'pointer'}} alignItems="flex-start" secondaryAction={
      <IconButton edge="end" aria-label="more"  onClick={handleClick} sx={{color: isHeader ? '#fff' : 'initial'}}>
        <MoreHorizIcon />
      </IconButton>
    }>
      {notification?.type ? <ListItemAvatar>
        <Avatar alt="Notification" sx={{ bgcolor: green[500] }}>
          {notificationsIcons(notification.type)}
        </Avatar>
      </ListItemAvatar>: null}
      <ListItemText
        primary={<React.Fragment>
          <Typography
            sx={{  overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: isHeader ? "3" : '20',
              lineHeight: 1.64,
              fontSize: '0.9375rem',
              fontWeight: notification?.isRead ? 400 : 700,
              color: setColorText(notification?.isRead, isHeader),
              WebkitBoxOrient: "vertical", }}
            component="span"
          >
            {notification?.message}
          </Typography>
        </React.Fragment>}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: 'inline', color: isHeader ? '#2f97ff' : '#c11b1e' }}
              component="span"
              variant="body2"
            >
              {moment(notification.createdAt).fromNow()}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List sx={{backgroundColor: isHeader ? '#343a40' : 'transparent', padding: 0}}>
        {notification.isRead === 0 && <ListItemButton
          selected={selectedIndex === notification?.id}
          onClick={(event) => handleListItemClick(event, notification?.id)}
        >
          <ListItemIcon   sx={{color: isHeader ? '#E4E6EB' : 'initial'}}>
            <CheckIcon />
          </ListItemIcon>
            <ListItemText
              sx={{color: isHeader ? '#E4E6EB' : 'initial'}}
              primary={t('mark_read')}
            />
        </ListItemButton>}
        <ListItemButton
          selected={selectedIndex === notification?.id}
          onClick={handleDisabledNotifications}
        >
          <ListItemIcon   sx={{color: isHeader ? '#E4E6EB' : 'initial'}}>
            <AlarmOffIcon />
          </ListItemIcon>
          <ListItemText
            sx={{color: isHeader ? '#E4E6EB' : 'initial'}}
            primary={(profile?.notificationsDisabled || []).find((not: string) => not === notification?.type) ?  t('enabled') : t('disabled')}
          />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === notification?.id}
          onClick={handleDeleteNotification}
        >
          <ListItemIcon   sx={{color: isHeader ? '#E4E6EB' : 'initial'}}>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText
            sx={{color: isHeader ? '#E4E6EB' : 'initial'}}
            primary={t('Delete')}
          />
        </ListItemButton>
      </List>

    </Popover>
  </>)
}

export default NotificationItemList;